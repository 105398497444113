import { useRef, useState, useEffect } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import ExportarExcel from './ExportarExcel';
import { SpinerDownload } from './loader/SpinerLoader';

const paginationComponentOptions = {
  rowsPerPageText: 'Filas por página',
  rangeSeparatorText: 'de',
  selectAllRowsItem: true,
  selectAllRowsItemText: 'Todos',
};

createTheme(
  'solarized',
  {
    text: {
      primary: 'primary',
      secondary: '#2aa198',
    },
    background: {
      default: 'transparent',
    },
  },
  'dark'
);

function CustomTable({
  columns,
  data,
  isLoading,
  excelName,
  selectableRows,
  onSelectedRowsChange,
}) {
  const [dataFiltrada, setDataFiltrada] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const searchInput = useRef(null);
  const [paginaActual, setPaginaActual] = useState(1);
  const [zoom, setZoom] = useState(1); // Nuevo estado para el zoom

  useEffect(() => {
    setDataFiltrada(data);
  }, [data]);

  // Nuevas funciones para controlar el zoom
  const zoomIn = () => setZoom((prevZoom) => prevZoom + 0.1);
  const zoomOut = () => setZoom((prevZoom) => prevZoom - 0.1);

  const customStyles = {
    tableWrapper: {
      style: {
        transform: `scale(${zoom})`,
        transformOrigin: 'top left',
      },
    },
    rows: {
      style: {
        minHeight: '45px', // override the row height
      },
    },
    headCells: {
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        fontSize: '15px',
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },

    pagination: {
      style: {
        fontSize: '16px',
      },
      // pagination nav button
      pageButtonsStyle: {
        backgroundColor: '#2aa198',
      },
    },
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    const filteredData = data.filter((row) =>
      Object.values(row).some(
        (fieldValue) =>
          fieldValue &&
          fieldValue.toString().toLowerCase().includes(value.toLowerCase())
      )
    );
    setDataFiltrada(filteredData);
  };

  // valida que si la pagina es la misma en la que se encuentra, no se actualice el state
  useEffect(() => {
    const url = window.location.href;
    const page = sessionStorage.getItem('page');
    if (url === sessionStorage.getItem('url-actual')) {
      setPaginaActual(parseInt(page));
    }
  }, []);

  const handleChangePage = (page) => {
    // obtiene la url actual
    const url = window.location.href;
    // almacena la url actual en session storage
    sessionStorage.setItem('url-actual', url);
    // almacena en session storage la pagina actual
    sessionStorage.setItem('page', page);
  };

  const handleClearFilter = () => {
    setSearchValue('');
    setDataFiltrada(data);
    searchInput.current.focus();
  };

  return (
    <>
      {isLoading ? <SpinerDownload /> : null}
      <div className="row d-flex justify-content-between">
        <div className="col-md-3 col-12">
          <button className="btn btn-sm btn-outline-info" onClick={zoomIn}>
            <i className="bi bi-zoom-in"></i>
          </button>
          <button
            className="btn btn-sm btn-outline-info ms-1"
            onClick={zoomOut}
          >
            <i className="bi bi-zoom-out"></i>
          </button>
        </div>
        <div className="col-md-4 col-12">
          <div className="input-group">
            <span className="input-group-text" id="basic-addon1">
              <i className="bi bi-search"></i>
            </span>
            <input
              ref={searchInput}
              type="text"
              className="form-control"
              placeholder="Buscar"
              autoFocus
              value={searchValue}
              onChange={handleSearch}
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={handleClearFilter}
            >
              Limpiar
            </button>
          </div>
        </div>
        <div className="col-md-3 col-12 mt-md-0 mt-3">
          {!isLoading && data && data.length > 0 ? (
            <ExportarExcel
              excelData={data}
              fileName={excelName}
              columnMapping={columns}
            />
          ) : null}
        </div>
      </div>

      <DataTable
        customStyles={customStyles}
        theme="solarized"
        columns={columns}
        data={dataFiltrada}
        fixedHeader
        pagination
        highlightOnHover
        progressPending={isLoading}
        progressComponent="Obteniendo información..."
        paginationComponentOptions={paginationComponentOptions}
        noDataComponent="No se encontraron registros"
        paginationDefaultPage={paginaActual}
        onChangePage={handleChangePage}
        selectableRows={selectableRows}
        onSelectedRowsChange={onSelectedRowsChange}
        // dense // compacto
      />
    </>
  );
}

export default CustomTable;
