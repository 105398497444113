import { useState, useEffect } from 'react';
import useUser from '../../../hooks/useUser';
import { getTiposGastos } from '../../../services/tiposGastos.js';

function TiposGastosSelect({ opcionDefault, onSelect, reset, isRequired }) {
  const { apiToken } = useUser();
  const [tiposGastos, setTiposGastos] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  // Obtiene los tipos de gastos
  useEffect(() => {
    getTiposGastos(apiToken).then((data) => {
      setTiposGastos(data.tipos_gastos);
      if (reset) {
        setSelectedOption(null);
      } else if (opcionDefault && opcionDefault !== '') {
        setSelectedOption(opcionDefault);
      }
    });
  }, [apiToken, opcionDefault, reset]);

  return (
    <>
      <label htmlFor="input-tipoGasto" className="form-label">
        Concepto
      </label>
      <select
        value={selectedOption || ''}
        className="form-select"
        id="basicSelect"
        name="tipoGasto"
        onChange={(e) => {
          setSelectedOption(e.target.value);
          if (onSelect)
            onSelect(
              tiposGastos.find(
                (tipoGasto) => Number(tipoGasto.id) === Number(e.target.value)
              )
            );
        }}
        required={isRequired}
      >
        <option value={''}>Seleccionar Tipo de Gasto</option>
        {tiposGastos.map((tipoGasto) => (
          <option key={tipoGasto.id} value={tipoGasto.id}>
            {tipoGasto.tipo}
          </option>
        ))}
      </select>
    </>
  );
}

export default TiposGastosSelect;
