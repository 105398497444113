import { useParams } from 'react-router-dom';
import TablaRendiciones from '../../general/rendiciones/TablaRendiciones';
import TablaRevisar from '../../general/fpr/TablaRevisar';
import { useEffect, useState } from 'react';
import TablaRendicionesAdministracion from '../../general/rendiciones/TablaAdministracion';
import TablaPendientesEnvio from '../../general/fpr/TablaPendientesEnvio';
import TablaPendientesDeposito from '../../general/fpr/TablaPendientesDeposito';

function FprValidador() {
  const { tipotabla } = useParams();
  const [titulo, setTitulo] = useState('');

  useEffect(() => {
    let nuevoTitulo;
    switch (tipotabla) {
      case 'todas':
        nuevoTitulo = 'TODAS';
        break;
      case 'revision':
        nuevoTitulo = 'POR REVISAR';
        break;
      case 'administracion':
        nuevoTitulo = 'EN ADMINISTRACIÓN';
        break;
      case 'pagadas':
        nuevoTitulo = 'PAGADAS';
        break;
      case 'pendientes':
        nuevoTitulo = 'PENDIENTES DE ENVÍO';
        break;
      case 'depositos':
        nuevoTitulo = 'PENDIENTES DE DEPOSITO';
        break;
      default:
        nuevoTitulo = '';
        break;
    }
    setTitulo(nuevoTitulo);
  }, [tipotabla]);

  let tabla;
  switch (tipotabla) {
    case 'todas':
      tabla = <TablaRendiciones />;
      break;
    case 'revision':
      tabla = <TablaRevisar />;
      break;
    case 'administracion':
      tabla = <TablaRendicionesAdministracion />;
      break;
    case 'pagadas':
      tabla = <TablaRendiciones />;
      break;
    case 'pendientes':
      tabla = <TablaPendientesEnvio />;
      break;
    case 'depositos':
      tabla = <TablaPendientesDeposito />;
      break;
    default:
      tabla = <TablaRendiciones />;
      break;
  }

  return (
    <section className="section">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">{titulo}</h4>
        </div>
        <div className="card-body">
          {/* tabla */}
          {tabla}
        </div>
        <div className="card-footer"></div>
      </div>
    </section>
  );
}

export default FprValidador;
