import { useEffect, useState } from 'react';
import useUser from '../../hooks/useUser';
import { getEstadisticasRendiciones } from '../../services/rendicionesT';
import CardUserLogged from '../general/cardsInfo/CardUserLogged';
import TotalMesTecnicos from '../general/estadisticas/TotalMesTecnicos';
import KilometrosRegiones from '../general/estadisticas/KilometrosRegiones';
import GastoTicketPromedioProyecto from '../general/estadisticas/GastoTicketPromedioProyecto';

function DashFinanzas() {
  const { isLogged, role, apiToken, dataUserLogged } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [estadisticas, setEstadisticas] = useState([]);

  const ObtieneEstadisticas = () => {
    getEstadisticasRendiciones(apiToken).then((response) => {
      if (response.status === 200) {
        setEstadisticas(response.estadisticas);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (isLogged && role !== 'Tecnico') {
      ObtieneEstadisticas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, role, apiToken, dataUserLogged]);

  return (
    <section className="row">
      <div className="col-12 col-lg-9">
        {/*  Cards estadisticas  */}
        <div className="row">
          <div className="col-sm-12 col-md-4">
            <div className="card">
              <div className="card-body">
                <div className="row d-flex align-items-center">
                  <div className="col-md-4 col-lg-12 col-xl-12 position-absolute">
                    <div
                      className="rounded rounded-4 bg-danger text-center overflow-hidden"
                      style={{ height: '90px', width: '90px', opacity: '0.3' }}
                    >
                      <i
                        className="bi bi-currency-dollar text-white"
                        style={{ fontSize: '80px' }}
                      ></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12">
                    <h6 className="text-end font-semibold">
                      Pendientes de Pago
                    </h6>
                    <h4
                      className="font-extrabold mb-0 text-end"
                      id="doc-vencidos"
                    >
                      {isLoading
                        ? '0'
                        : estadisticas.conteo.find(
                            (elemento) => elemento.estado === 'Finanzas'
                          )?.cantidad || '0'}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="card">
              <div className="card-body">
                <div className="row d-flex align-items-center">
                  <div className="col-md-4 col-lg-12 col-xl-12 d-flex justify-content-start position-absolute">
                    <div
                      className="rounded rounded-4 bg-success text-center  overflow-hidden"
                      style={{ height: '90px', width: '90px', opacity: '0.3' }}
                    >
                      <i
                        className="bi bi-currency-dollar text-white"
                        style={{ fontSize: '80px' }}
                      ></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12">
                    <h6 className="text-end font-semibold">Consolidadas</h6>
                    <h4
                      className="font-extrabold mb-0 text-end"
                      id="doc-vencidos"
                    >
                      {isLoading
                        ? '0'
                        : estadisticas.conteo.find(
                            (elemento) => elemento.estado === 'Consolidadas'
                          )?.cantidad || '0'}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="card">
              <div className="card-body">
                <div className="row d-flex align-items-center">
                  <div className="col-md-4 col-lg-12 col-xl-12 position-absolute">
                    <div
                      className="rounded rounded-4 bg-warning text-center overflow-hidden"
                      style={{ height: '90px', width: '90px', opacity: '0.3' }}
                    >
                      <i
                        className="bi bi-exclamation-triangle-fill text-white"
                        style={{ fontSize: '80px' }}
                      ></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12">
                    <h6 className="text-end font-semibold text-truncate">
                      En Validación
                    </h6>
                    <h4
                      className="font-extrabold mb-0 text-end"
                      id="doc-vencidos"
                    >
                      {isLoading
                        ? '0'
                        : estadisticas.conteo.find(
                            (elemento) => elemento.estado === 'Validacion'
                          )?.cantidad || '0'}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  Cards estadisticas end  */}

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4>Gastos últimos 3 meses</h4>
              </div>
              <div className="card-body">
                <TotalMesTecnicos />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4>1-</h4>
              </div>
              <div className="card-body">
                <div className="table-responsive"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  Tercera columna  */}
      <div className="col-12 col-lg-3">
        {/*  Card info usuario logueado  */}
        <CardUserLogged />

        {/*  Card ultimas rendiciones  */}
        <GastoTicketPromedioProyecto />

        {/*  Card utimas aprovadas  */}
        <KilometrosRegiones />
      </div>
    </section>
  );
}

export default DashFinanzas;
