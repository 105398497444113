import { Link } from 'react-router-dom';
import { getRendicionesTodas } from '../../../services/rendicionesT';
import useUser from '../../../hooks/useUser';
import { useEffect, useState } from 'react';
import { formatDate } from '../../../hooks/useDates';
import CustomTable from '../../general/CustomTable';

const excelName = `Lista de rendiciones pendientes de pago`;

function TablaRendicionesFinanzas({
  recargarTabla,
  setRecargarTabla,
  selectableRows,
  onSelectedRowsChange,
}) {
  const { apiToken, dataUserLogged } = useUser();
  const [rendiciones, setRendiciones] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [perfil, setPerfil] = useState('');

  useEffect(() => {
    // dataUserlogged en minuscula
    setPerfil(dataUserLogged.role.toLowerCase());
  }, [dataUserLogged]);

  const columns = [
    {
      name: 'SEM.',
      cell: (row) => (
        <Link className="text-reset" to={`/${perfil}/rendicion/${row.id}`}>
          {row.semana}
        </Link>
      ),
      sortable: true,
      sortFunction: (a, b) => Number(a.semana) - Number(b.semana),
      width: '5%',
      clave: 'semana',
    },
    {
      name: 'RUT',
      selector: (row) => row.rut,
      sortable: true,
      sortFunction: (a, b) => Number(a.rut) - Number(b.rut),
      clave: 'rut',
      width: '11%',
    },
    {
      name: 'NOMBRE',
      selector: (row) => row.nombre,
      sortable: true,
      filterable: true,
      clave: 'nombre',
    },
    {
      name: 'APELLIDO',
      selector: (row) => row.apellido,
      sortable: true,
      clave: 'apellido',
    },
    {
      name: 'MONTO',
      selector: (row) => row.total_pago,
      sortable: true,
      sortFunction: (a, b) => Number(a.total_pago) - Number(b.total_pago),
      width: '9%',
      clave: 'total_pago',
    },
    {
      name: 'CC',
      selector: (row) =>
        row.centro_costo ? row.centro_costo.slice(-3) : 's/i',
      sortable: true,
      width: '6%',
      clave: 'centro_costo',
    },
    {
      name: 'FECHA ENVÍO',
      selector: (row) => formatDate(row.fecha_envio),
      sortable: true,
      sortFunction: (a, b) => Number(a.fecha_envio) - Number(b.fecha_envio),
      clave: 'fecha_envio',
      width: '11%',
    },
    {
      name: 'APROBACIÓN',
      selector: (row) => formatDate(row.fecha_aprueba),
      sortable: true,
      sortFunction: (a, b) => Number(a.fecha_aprueba) - Number(b.fecha_aprueba),
      clave: 'fecha_aprueba',
      width: '11%',
    },
    {
      name: 'VER',
      cell: (row) => (
        <Link
          to={`/${perfil}/rendicion/${row.id}`}
          className="btn btn-outline-primary btn-sm"
        >
          <i className="bi bi-eye"></i>
        </Link>
      ),
      width: '5%',
      rigth: true,
    },
  ];

  useEffect(() => {
    const fetchRendiciones = async () => {
      setIsLoading(true);
      const data = await getRendicionesTodas(4, apiToken);
      setRendiciones(data.rendiciones);
      setIsLoading(false);
      if (recargarTabla) {
        setRecargarTabla(false);
      }
    };
    fetchRendiciones();
  }, [apiToken, recargarTabla, setRecargarTabla]);

  return (
    <CustomTable
      columns={columns}
      data={rendiciones}
      isLoading={isLoading}
      exportar={true}
      excelName={excelName}
      {...(dataUserLogged.role === 'Finanzas'
        ? { selectableRows, onSelectedRowsChange }
        : {})}
    />
  );
}

export default TablaRendicionesFinanzas;
