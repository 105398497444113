import { useEffect, useState } from 'react';
import useUser from '../../../hooks/useUser';
import {
  createCentroCosto,
  deleteCentroCosto,
  getCentrosCostos,
} from '../../../services/tiposGastos';

import {
  AlertaConfirm,
  AlertaError,
  AlertaSuccess,
} from '../../general/Alerts';
import { SpinerDownload } from '../../general/loader/SpinerLoader';

const initialState = {
  id: '',
  centro_costo: '',
  descripcion: '',
};

function CentrosCostos() {
  const { apiToken } = useUser();
  const [centrosCostos, setCentrosCostos] = useState([]);
  const [centroCosto, setCentroCosto] = useState({ ...initialState });
  const [isLoading, setIsLoading] = useState(false);

  // get data
  const getData = (apiToken) => {
    setIsLoading(true);
    getCentrosCostos(apiToken).then((data) => {
      setCentrosCostos(data.centrosCostos);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData(apiToken);
  }, [apiToken]);

  // handleChange
  const handleChange = (e) => {
    setCentroCosto({
      ...centroCosto,
      [e.target.name]: e.target.value,
    });
  };

  // centroCosto edit
  const handleProyectoClicked = (centroCosto) => {
    setCentroCosto(centroCosto);
  };

  // delete centroCosto
  const handleDeleteProyecto = (centroCosto) => {
    AlertaConfirm({
      title: '¿Eliminar centro de costo?',
      text: `Se eliminara ${centroCosto.centro_costo}`,
      callback: () => {
        deleteCentroCosto(centroCosto.id, apiToken).then((data) => {
          if (data.status === 200) {
            AlertaSuccess({ message: 'Proyecto eliminada correctamente' });
            getData(apiToken);
          } else {
            AlertaError({ error: data.error });
          }
        });
      },
    });
  };

  // submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (centroCosto.centro_costo === '') {
      AlertaError({ error: 'Debe ingresar un centro de costo' });
      return;
    }
    AlertaConfirm({
      title: '¿Crear centroCosto?',
      text: `Se creara ${centroCosto.centro_costo}`,
      callback: () => {
        createCentroCosto(centroCosto, apiToken).then((data) => {
          if (data.status === 200) {
            if (centroCosto.id === '') {
              setCentrosCostos([...centrosCostos, data.centroCosto]);
            } else {
              const proyectosUpdated = centrosCostos.map((centroCosto) => {
                if (centroCosto.id === data.centroCosto.id) {
                  return data.centroCosto;
                }
                return centroCosto;
              });
              setCentrosCostos(proyectosUpdated);
            }
            setCentroCosto({ ...initialState });
            document.getElementById('form-create-centroCosto').reset();
            AlertaSuccess({ message: 'Centro de costo creado correctamente' });
          } else {
            AlertaError({ error: data.error });
          }
        });
      },
    });
  };

  return (
    <section className="section">
      <div className="row">
        <div className="col-sm-12 col-lg-8">
          <h3>Centros de Costos</h3>
          {/* table */}
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th className="col-3">Centro de Costo</th>
                <th>Descripción</th>
                <th className="col-2">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="3">
                    Obteniendo Información...
                    <SpinerDownload />
                  </td>
                </tr>
              ) : (
                centrosCostos.map((centroCosto) => (
                  <tr key={centroCosto.id}>
                    <td>{centroCosto.centro_costo}</td>
                    <td>{centroCosto.descripcion || 'Sin descripcion'}</td>
                    <td>
                      <div className="col-12 d-flex justify-content-end">
                        <button
                          className="btn btn-primary btn-sm mr-2"
                          onClick={() => handleProyectoClicked(centroCosto)}
                        >
                          Editar
                        </button>

                        <button
                          className="btn btn-danger btn-sm ms-1"
                          onClick={() => handleDeleteProyecto(centroCosto)}
                        >
                          Eliminar
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="col-sm-12 col-lg-4">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Agregar Nuevo Proyecto</h4>
            </div>
            <div className="card-body">
              {/* Formulario */}
              <form
                className="form"
                data-parsley-validate
                id="form-create-centroCosto"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  {/* Proyecto */}
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="centroCosto">Centro de costo</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Proyecto"
                        name="centro_costo"
                        value={centroCosto.centro_costo}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/* Descripcion */}
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="descripcion">Descripción</label>
                      <textarea
                        className="form-control"
                        placeholder="Descripción"
                        name="descripcion"
                        value={centroCosto.descripcion}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary me-1 mb-1">
                      Agregar
                    </button>
                    <button
                      type="reset"
                      className="btn btn-light-secondary me-1 mb-1"
                      onClick={() => setCentroCosto({ ...initialState })}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CentrosCostos;
