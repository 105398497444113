import { useEffect, useState } from 'react';
import CardUserLogged from '../../general/cardsInfo/CardUserLogged';
import { useParams } from 'react-router-dom';
import { getFprById } from '../../../services/fondosPorRendir';
import useUser from '../../../hooks/useUser';

function FprDetalleValidador() {
  const [fondosfpr, setFondosFpr] = useState(null);
  const { id } = useParams();
  const { apiToken } = useUser();

  const handleValidarFpr = () => {
    // Lógica para validar el FPR aquí
  };

  useEffect(() => {
    const fetchFpr = async () => {
      const response = await getFprById(id, apiToken);
      setFondosFpr(response.fpr);
      console.log(response);
    };
    fetchFpr();
  }, [apiToken, id]);

  return (
    <section className="row">
      <div className="col-12 col-lg-9">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              DETALLE DEL FONDO POR RENDIR {fondosfpr && `No. ${fondosfpr.id}`}
            </h4>
          </div>
          <div className="card-body p-0">
            {/* Datos */}
            {fondosfpr && (
              <div className="card">
                <div className="card-body">
                  <div className="col-4 shadow-sm p-2">
                    {/* Solicitud */}
                    <div>
                      <div className="bg-hyo mb-4">
                        <h5 className="card-subtitle mb-2 text-muted text-end mt-1 me-2">
                          SOLICITUD
                        </h5>
                      </div>
                      <div className="d-flex flex-column align-items-start">
                        <div className="d-flex justify-content-between w-100">
                          <strong>Fecha solicitud: </strong>
                          <span>{fondosfpr.created_at}</span>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                          <strong>Técnico: </strong>
                          <span>{`${fondosfpr.tecnico_nombre} ${fondosfpr.tecnico_apellido}`}</span>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                          <strong>Solicita: </strong>
                          <span>{`${fondosfpr.solicita_nombre} ${fondosfpr.solicita_apellido}`}</span>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                          <strong>Fecha del servicio: </strong>
                          <span>{fondosfpr.fecha_servicio}</span>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                          <strong>Monto: </strong>
                          <span>${fondosfpr.monto_deposito}</span>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                          <strong>Comentario: </strong>
                          <span>{fondosfpr.comentario_solicitud}</span>
                        </div>
                      </div>
                    </div>
                    {/* Deposito */}
                    <div>
                      <div className="bg-hyo mb-4">
                        <h5 className="card-subtitle mb-2 text-muted text-end mt-2 me-2">
                          DEPOSITO
                        </h5>
                      </div>
                      <div className="d-flex flex-column align-items-start">
                        <div className="d-flex justify-content-between w-100">
                          <strong>Fecha deposito: </strong>
                          <span>{fondosfpr.fecha_deposito}</span>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                          <strong>Deposita: </strong>
                          <span>{`${fondosfpr.deposita_nombre} ${fondosfpr.deposita_apellido}`}</span>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                          <strong>Comentario: </strong>
                          <span>{fondosfpr.comentario_deposito}</span>
                        </div>
                      </div>
                    </div>
                    {/* Justificacion */}
                    <div>
                      <div className="bg-hyo mb-4">
                        <h5 className="card-subtitle mb-2 text-muted text-end mt-2 me-2">
                          RENDICIÓN DE GASTOS
                        </h5>
                      </div>
                      <div className="d-flex flex-column align-items-start">
                        <div className="d-flex justify-content-between w-100">
                          <strong>Fecha rendicion: </strong>
                          <span>{fondosfpr.fecha_rendicion}</span>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                          <strong>Monto rendido: </strong>
                          <span>${fondosfpr.monto_rendicion}</span>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                          <strong>Diferencia a devolver: </strong>
                          <span>${fondosfpr.monto_devolucion}</span>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                          <strong>Comentario: </strong>
                          <span>{fondosfpr.comentario_rendicion}</span>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                          <strong>Valida: </strong>
                          <span>{`${fondosfpr.valida_r_nombre} ${fondosfpr.valida_r_apellido}`}</span>
                        </div>
                      </div>
                    </div>
                    {/* Devolucion */}
                    <div>
                      <div className="bg-hyo mb-4">
                        <h5 className="card-subtitle mb-2 text-muted text-end mt-2 me-2">
                          DEVOLUCIÓN
                        </h5>
                      </div>
                      <div className="d-flex flex-column align-items-start">
                        <div className="d-flex justify-content-between w-100">
                          <strong>Fecha devolución: </strong>
                          <span>{fondosfpr.fecha_servicio}</span>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                          <strong>Monto devolución: </strong>
                          <span>${fondosfpr.monto_devolucion}</span>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                          <strong>Comentario: </strong>
                          <span>{fondosfpr.comentario_devolucion}</span>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                          <strong>Valida: </strong>
                          <span>{`${fondosfpr.valida_d_nombre} ${fondosfpr.valida_d_apellido}`}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-8">{/* Imagen */}</div>
                </div>
              </div>
            )}
          </div>
          <div className="card-footer">
            <button className="btn btn-primary" onClick={handleValidarFpr}>
              Validar FPR
            </button>
          </div>
        </div>
      </div>
      {/*  Tercera columna  */}
      <div className="col-12 col-lg-3">
        {/*  Card info usuario logueado  */}
        <CardUserLogged />

        {/*  Card ultimas rendiciones si no esta cargando y estadisticas no esta vacio  */}

        {/*  Card utimas aprovadas  */}
      </div>
    </section>
  );
}

export default FprDetalleValidador;
