import { Link, useLocation } from 'react-router-dom';
import useUser from '../../hooks/useUser';

function MenuGerencia() {
  const location = useLocation();
  const { pathname } = location;
  const path = pathname.split('/');

  const { logout } = useUser(); // hook para hacer logout

  return (
    <div className="sidebar-menu">
      <ul className="menu">
        <li className="sidebar-title">Menú</li>
        <li
          className={'sidebar-item ' + (path[2] === undefined ? 'active' : '')}
        >
          <Link to="/gerencia" className="sidebar-link">
            <i className="bi bi-grid-fill"></i>
            <span>Dashboard</span>
          </Link>
        </li>

        {/* Rendiciones */}
        <li
          className={
            'sidebar-item has-sub ' +
            (path[2] === 'rendiciones' || path[2] === 'rendicion'
              ? 'active'
              : '')
          }
        >
          <Link to="#" className="sidebar-link">
            <i className="bi bi-card-checklist"></i>
            <span>Rendiciones</span>
          </Link>
          <ul className="submenu">
            <li className="submenu-item">
              <Link to="/gerencia/rendiciones/gerencia">
                Pendientes Aprobación
              </Link>
            </li>
            <li className="submenu-item">
              <Link to="/gerencia/rendiciones/validacion">En Validación</Link>
            </li>
            <li className="submenu-item">
              <Link to="/gerencia/rendiciones/finanzas">Por Consolidar</Link>
            </li>
            <li className="submenu-item">
              <Link to="/gerencia/rendiciones/consolidadas">Consolidadas</Link>
            </li>
          </ul>
        </li>

        {/* Documentos */}
        <li
          className={'sidebar-item ' + (path[2] === 'informes' ? 'active' : '')}
        >
          <Link to="/gerencia/informes" className="sidebar-link">
            <i className="bi bi-archive-fill"></i>
            <span>Informes</span>
          </Link>
        </li>

        {/* SISTEMA */}
        <li
          className={
            'sidebar-item has-sub ' + (path[2] === 'sistema' ? 'active' : '')
          }
        >
          <Link to="#" className="sidebar-link">
            <i className="bi bi-tools"></i>
            <span>Sistema</span>
          </Link>

          <ul className="submenu">
            <li className="submenu-item">
              <Link to="/gerencia/sistema/clientes">Gestion Clientes</Link>
            </li>
            <li className="submenu-item">
              <Link to="/gerencia/sistema/usuarios">Gestionar Usuarios</Link>
            </li>
            <li className="submenu-item">
              <Link to="/gerencia/sistema/solestados">Estados Solicitudes</Link>
            </li>
            <li className="submenu-item">
              <Link to="/gerencia/sistema/tiposgastos">Tipos de Gastos</Link>
            </li>
            <li className="submenu-item">
              <Link to="/gerencia/sistema/centroscostos">
                Centros de Costos
              </Link>
            </li>
          </ul>
        </li>
        <li className="sidebar-item">
          {/* call to logout */}
          <Link to="/" className="sidebar-link" onClick={logout}>
            <i className="bi bi-door-open-fill"></i>
            <span>Cerrar Sesión</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default MenuGerencia;
