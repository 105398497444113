import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

const PdfDownloader = ({ rootElementId, downloadFileName }) => {
  const downloadPdfDocument = () => {
    const input = document.getElementById(rootElementId);

    // Ocultar los elementos que deseas excluir del PDF
    const elementsToExclude = input.getElementsByClassName('oculto-pdf');
    for (let i = 0; i < elementsToExclude.length; i++) {
      elementsToExclude[i].style.display = 'none';
    }

    // Ajustar el tamaño del lienzo HTML con margen
    const marginLeft = 20; // 2 cm en píxeles considerando la resolución estándar de 96 ppp
    const marginTop = 40; // 2 cm en píxeles considerando la resolución estándar de 96 ppp
    const canvasWidth = input.offsetWidth + marginLeft * 2;
    const canvasHeight = input.offsetHeight + marginTop * 2;

    html2canvas(input, {
      scale: 1.5,
      width: canvasWidth,
      height: canvasHeight,
      x: -marginLeft,
      y: -marginTop,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', 0.9);
      const pdf = new jsPDF('landscape', 'px', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      let heightLeft = pdfHeight;
      let position = 0;

      pdf.addImage(imgData, 'JPEG', 0, position, pdfWidth, pdfHeight);
      heightLeft -= pdf.internal.pageSize.getHeight();

      while (heightLeft >= 0) {
        position = heightLeft - pdfHeight + 20 / pdf.internal.scaleFactor; // Ajustar la posición para retroceder 10 píxeles
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
        heightLeft -= pdf.internal.pageSize.getHeight();
      }

      pdf.save(`${downloadFileName}.pdf`);

      // Restaurar la visualización de los elementos excluidos
      for (let i = 0; i < elementsToExclude.length; i++) {
        elementsToExclude[i].style.display = '';
      }
    });
  };

  return (
    <button
      className="btn btn-secondary btn-sm oculto-pdf"
      onClick={downloadPdfDocument}
    >
      <i className="bi bi-filetype-pdf"></i> Descargar Documento
    </button>
  );
};

export default PdfDownloader;

// generar pdf desde un elemento HTML con multiples páginas (paginación)
export const PdfDownloader2 = ({
  rootElementId_1,
  rootElementId_2,
  downloadFileName,
}) => {
  const downloadPdfDocument = () => {
    const input = document.getElementById(rootElementId_1);
    const input2 = document.getElementById(rootElementId_2);

    const marginLeft = 40; // 2 cm en píxeles considerando la resolución estándar de 96 ppp
    const marginTop = 40; // 2 cm en píxeles considerando la resolución estándar de 96 ppp
    const canvasWidth = input.offsetWidth + marginLeft * 2;
    const canvasHeight = input.offsetHeight + marginTop * 2;

    const pdf = new jsPDF();
    html2canvas(input, {
      scale: 1.5,
      width: canvasWidth,
      height: canvasHeight,
      x: -marginLeft,
      y: -marginTop,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.addPage();
      html2canvas(input2, {
        scale: 1.5,
        width: canvasWidth,
        height: canvasHeight,
        x: -marginLeft,
        y: -marginTop,
      }).then((canvas2) => {
        const imgData2 = canvas2.toDataURL('image/png');
        const imgProps2 = pdf.getImageProperties(imgData2);
        const pdfWidth2 = pdf.internal.pageSize.getWidth();
        const pdfHeight2 = (imgProps2.height * pdfWidth2) / imgProps2.width;
        pdf.addImage(imgData2, 'PNG', 0, 0, pdfWidth2, pdfHeight2);
        pdf.save(`${downloadFileName}.pdf`);
      });
    });
  };

  return (
    <button className="btn btn-secondary" onClick={downloadPdfDocument}>
      <i className="bi bi-filetype-pdf"></i> Descargar Documento
    </button>
  );
};
