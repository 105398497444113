import React from 'react';
import * as XLSX from 'xlsx';

function ExportarExcel({ excelData, fileName, columnMapping }) {
  const uniqueKeys = [
    ...new Set(
      excelData.reduce((keys, row) => Object.keys(row).concat(keys), [])
    ),
  ].filter((clave) => columnMapping.some((col) => col.clave === clave));

  const headerKeys = columnMapping
    .filter((col) => uniqueKeys.includes(col.clave))
    .map((col) => col.clave);

  const header = headerKeys.map(
    (clave) => columnMapping.find((c) => c.clave === clave).name
  );

  const filteredData = excelData.map((item) =>
    headerKeys.reduce((newItem, clave) => {
      const col = columnMapping.find((c) => c.clave === clave);
      if (col) {
        newItem[col.name] = item[clave];
      }
      return newItem;
    }, {})
  );

  const handleDownloadExcel = () => {
    const sheet = XLSX.utils.json_to_sheet(filteredData, { header });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, 'Hoja1');
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  return (
    <button className="btn btn-success" onClick={handleDownloadExcel}>
      <i className="bi bi-cloud-arrow-down-fill"></i> Descargar en Excel
    </button>
  );
}

export default ExportarExcel;
