import { useEffect, useRef, useState } from 'react';
import Resizer from 'react-image-file-resizer';
import { editItemTicket } from '../../../services/rendicionesT';
import useUser from '../../../hooks/useUser';
import { AlertToast, AlertaConfirm, AlertaError } from '../Alerts';
import TiposGastosSelect from '../forms/TiposGastosSelect';
import { SpinerLoad } from '../loader/SpinerLoader';

// file resizer
const resizeFile = (file) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      600,
      600,
      'JPEG',
      90,
      0,
      (uri) => {
        resolve(uri);
      },
      'file',
      'auto'
    );
  });
};

const FileInput = ({ inputRef, handleFileChange }) => {
  return (
    <div className="form-group">
      <div className="custom-file">
        <input
          ref={inputRef}
          type="file"
          className="custom-file-input"
          id="imageFile"
          onChange={handleFileChange}
          accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
        <label className="custom-file-label" htmlFor="imageFile">
          Elegir archivo
        </label>
      </div>
    </div>
  );
};

const ImageUploadPreview = ({
  previewImage,
  handleFileDrop,
  handleFileDragOver,
}) => {
  return (
    <div className="form-group text-center border border-primary">
      <div
        className="image-upload-preview"
        onDrop={handleFileDrop}
        onDragOver={handleFileDragOver}
      >
        <p className="text-muted">Puede arrastrar imagen aqui</p>
        {previewImage && (
          <img
            className="img-fluid mx-auto"
            src={previewImage}
            alt="Upload Preview"
          />
        )}
      </div>
    </div>
  );
};

function ModalEditarItem({ itemEdit = null, valorKm, reloadItems }) {
  const [previewImage, setPreviewImage] = useState(null);
  const [file, setFile] = useState(null);
  const [esKilometraje, setEsKilometraje] = useState(false);
  const kilometros = useRef(null);
  const monto = useRef(null);
  const inputFile = useRef(null);
  const [dataItem, setDataItem] = useState({});
  const [resetSelect, setResetSelect] = useState(false);
  const formulario = useRef(null);
  const { apiToken } = useUser();
  const modalRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const checkBootstrap = () => {
      if (window.bootstrap) {
        const modalInstance = new window.bootstrap.Modal(modalRef.current);
        // Guardamos la instancia del modal en la referencia para poder acceder a ella más tarde
        modalRef.current.modalInstance = modalInstance;
      } else {
        // Si window.bootstrap aún no está definido, volvemos a comprobarlo después de un corto retraso
        setTimeout(checkBootstrap, 200);
      }
    };

    checkBootstrap();
  }, []);

  useEffect(() => {
    if (itemEdit) {
      setDataItem(itemEdit);
      setEsKilometraje(Number(itemEdit.tipo_id) === 1);
    }
  }, [itemEdit]);

  useEffect(() => {
    if (resetSelect) {
      setResetSelect(false);
    }
  }, [resetSelect]);

  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0];
      const fileURL = URL.createObjectURL(file);

      const supportedTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ];

      if (file.type.startsWith('image/')) {
        resizeFile(file).then((uri) => {
          setFile(uri);
          setPreviewImage(fileURL);
        });
      } else if (supportedTypes.includes(file.type)) {
        setFile(file);
        setPreviewImage(null);
      } else {
        throw new Error('File type not supported');
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const handleFileChange = async (e) => {
  //   try {
  //     const file = e.target.files[0];
  //     const fileURL = URL.createObjectURL(file);
  //     resizeFile(file).then((uri) => {
  //       setFile(uri);
  //       setPreviewImage(fileURL);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleFileDrop = async (e) => {
    e.preventDefault();
    try {
      const file = e.target.files[0];
      const fileURL = URL.createObjectURL(file);
      resizeFile(file).then((uri) => {
        setFile(uri);
        setPreviewImage(fileURL);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleTipo = (tipo) => {
    setDataItem({ ...dataItem, tipo_id: tipo.id });
    setEsKilometraje(tipo.tipo === 'Kilometraje'); // Actualiza el estado esKilometraje
  };

  const handleChange = (e) => {
    if (esKilometraje) {
      const montoKms = Number(e.target.value) * Number(valorKm);
      setDataItem({ ...dataItem, kilometros: e.target.value, monto: montoKms });
    } else {
      setDataItem({ ...dataItem, monto: e.target.value, kilometros: null });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dataItem.comprobante = file ? file : null;
    // agregar el item al ticket
    AlertaConfirm({
      title: 'Editar Item',
      text: '¿Enviar información actualizada?',
      callback: () => {
        setIsLoading(true);
        editItemTicket(dataItem, apiToken)
          .then((res) => {
            if (res.status === 200) {
              AlertToast({
                icon: 'success',
                title: 'Gasto Actualizado',
                timer: 3000,
              });
            } else {
              AlertaError({
                title: 'Error al editar item',
                error: 'Hubo un error al editar el item',
              });
            }
          })
          .catch((err) => {
            console.log(err);
            AlertaError({
              title: 'Error al editar item',
              error: 'Hubo un error al editar el item',
            });
          })
          .finally(() => {
            setIsLoading(false);
            // Cerrar el modal
            modalRef.current.modalInstance.hide();
            resetAll();
            reloadItems();
          });
      },
    });
  };

  const resetAll = () => {
    setPreviewImage(null);
    setFile(null);
    inputFile.current.value = '';
    setDataItem({});
    formulario.current.reset();
    setResetSelect(true);
  };

  const handleCancel = () => {
    resetAll();
  };

  const handleFileDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div
      className="modal fade modal-lg"
      id="modalEditarItem"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      ref={modalRef}
    >
      {isLoading && <SpinerLoad />}
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Editar Item Gasto</h5>
          </div>
          <div className="modal-body">
            <form ref={formulario} onSubmit={handleSubmit} id="formEditarItem">
              <div className="row">
                {/* Tipo */}
                <div className="form-group mandatory col-sm-12 col-md-6">
                  <TiposGastosSelect
                    opcionDefault={
                      itemEdit && itemEdit.tipo_id ? itemEdit.tipo_id : null
                    }
                    onSelect={handleTipo}
                    reset={resetSelect}
                    isRequired={true}
                  />
                </div>

                {/* Monto - Kilometraje */}
                {esKilometraje ? (
                  <div className="form-group mandatory col-sm-12 col-md-6">
                    <label htmlFor="kilometros" className="form-label">
                      Kilometros
                    </label>
                    <input
                      ref={kilometros}
                      type="number"
                      className="form-control"
                      id="kilometros"
                      name="kilometros"
                      onChange={handleChange}
                      value={dataItem.kilometros}
                      required
                    />
                  </div>
                ) : (
                  <div className="form-group mandatory col-sm-12 col-md-6">
                    <label htmlFor="monto" className="form-label">
                      Monto $
                    </label>
                    <input
                      ref={monto}
                      type="number"
                      className="form-control"
                      id="monto"
                      name="monto"
                      onChange={handleChange}
                      value={dataItem.monto}
                      required
                    />
                  </div>
                )}
              </div>

              {/* Comentario */}
              <div className="form-groupmb-4 col-12">
                <label htmlFor="comentarioJustificacion" className="form-label">
                  Comentario
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="comentarioJustificacion"
                  name="comentario_justificacion"
                  onChange={(e) =>
                    setDataItem({
                      ...dataItem,
                      comentario_gasto: e.target.value,
                    })
                  }
                  value={dataItem.comentario_gasto}
                />
              </div>

              {/* Comprobante */}
              <FileInput
                inputRef={inputFile}
                handleFileChange={handleFileChange}
              />
              {/* <div className="form-group">
                <div className="custom-file">
                  <input
                    ref={inputFile}
                    type="file"
                    className="custom-file-input"
                    id="imageFile"
                    onChange={handleFileChange}
                  />
                  <label className="custom-file-label" htmlFor="imageFile">
                    Elija imagen
                  </label>
                </div>
              </div> */}
              <ImageUploadPreview
                previewImage={previewImage}
                handleFileDrop={handleFileDrop}
                handleFileDragOver={handleFileDragOver}
              />
              {/* <div className="form-group text-center border border-primary">
                <div className="image-upload-preview" onDrop={handleFileDrop}>
                  <p className="text-muted">Puede arrastrar imagen aqui</p>
                  {previewImage && (
                    <img
                      src={previewImage}
                      alt="Upload Preview"
                      className="img-fluid"
                    />
                  )}
                </div>
              </div> */}
              <div className="alert alert-light-info">
                Permite el envio de PDF, e imagenes jpg, jpeg, png
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <div className="col-12 d-flex justify-content-between">
              <button
                type="submit"
                form="formEditarItem"
                className="btn btn-success"
              >
                {' '}
                <i className="bi bi-cloud-arrow-up"></i> Actualizar Gasto
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={handleCancel}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalEditarItem;
