import { useState } from 'react';

const SemanaCard = () => {
  const [fechaActual] = useState(new Date());

  const pad = (num) => (num < 10 ? '0' + num : num);

  const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear + 86400000) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };

  const getWeekInfo = (date) => {
    const startOfWeek =
      date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    const monday = new Date(date.setDate(startOfWeek));
    const sunday = new Date(date.setDate(startOfWeek + 6));
    const weekNumber = getWeekNumber(monday);

    return {
      weekNumber,
      startDate: `${pad(monday.getDate())}-${pad(
        monday.getMonth() + 1
      )}-${monday.getFullYear()}`,
      endDate: `${pad(sunday.getDate())}-${pad(
        sunday.getMonth() + 1
      )}-${sunday.getFullYear()}`,
    };
  };

  const currentWeekInfo = getWeekInfo(new Date(fechaActual));

  const lastWeekDate = new Date(fechaActual);
  lastWeekDate.setDate(lastWeekDate.getDate() - 7);
  const lastWeekInfo = getWeekInfo(lastWeekDate);

  const nextWeekDate = new Date(fechaActual);
  nextWeekDate.setDate(nextWeekDate.getDate() + 7);
  const nextWeekInfo = getWeekInfo(nextWeekDate);

  return (
    // <div className="row">
    //{' '}
    <div className="col-12 px-4">
      <h5 className="text-info text-center">Info. Semana 2023</h5>
      <div className="pricing">
        <div className="row align-items-center">
          <div className="col-md-4 px-0">
            <div className="card">
              <h4 className="price m-0 text-center">
                {lastWeekInfo.weekNumber}
              </h4>
              <ul className="px-2">
                <li>
                  <i className="bi bi-calendar-x"></i>
                  <small>{lastWeekInfo.startDate.slice(0, 5)}</small>
                </li>
                <li>
                  <i className="bi bi-calendar-x"></i>
                  <small>{lastWeekInfo.endDate.slice(0, 5)}</small>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-4 px-0 position-relative z-1">
            <div className="card card-highlighted shadow-lg">
              <h4 className="price text-white m-0 text-center">
                {currentWeekInfo.weekNumber}
              </h4>
              <ul className="px-2">
                <li>
                  <i className="bi bi-calendar-check"></i>
                  <small>{currentWeekInfo.startDate.slice(0, 5)}</small>
                </li>
                <li>
                  <i className="bi bi-calendar-check"></i>
                  <small>{currentWeekInfo.endDate.slice(0, 5)}</small>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 px-0">
            <div className="card py-0">
              <h4 className="price m-0 text-center">
                {nextWeekInfo.weekNumber}
              </h4>
              <ul className="px-2">
                <li>
                  <i className="bi bi-calendar-plus"></i>
                  <small>{nextWeekInfo.startDate.slice(0, 5)}</small>
                </li>
                <li>
                  <i className="bi bi-calendar-plus"></i>
                  <small>{nextWeekInfo.endDate.slice(0, 5)}</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default SemanaCard;
