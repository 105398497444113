import { Navigate } from 'react-router-dom';
import useUser from '../../hooks/useUser';
import { LOGIN } from '../../routes/rutas';
import { AlertaError } from '../general/Alerts';
import Main from '../general/Main';
import Sidebar from '../general/Sidebar';
import MenuSupervisor from '../supervisor/MenuSupervisor';

function SupervisorRoute() {
  const { isLogged, role, validateToken, logout } = useUser();

  if (!isLogged) return <Navigate to={LOGIN} />;

  if (role !== 'Supervisor') {
    return <Navigate to={LOGIN} />;
  }

  const tokenValidation = validateToken();
  setTimeout(() => {
    AlertaError({
      title: 'Sesión expirada!',
      error: 'Su sesión ha expirado, vuelva a iniciar sesión',
    });
    logout();
    return <Navigate to={LOGIN} />;
  }, tokenValidation * 1000);

  return (
    <>
      <Sidebar menu={<MenuSupervisor />} />
      <Main />
    </>
  );
}

export default SupervisorRoute;
