import { useEffect, useState } from 'react';
import CardUserLogged from '../../general/cardsInfo/CardUserLogged';
import { useParams } from 'react-router-dom';
import { getFprById, updateFpr } from '../../../services/fondosPorRendir';
import useUser from '../../../hooks/useUser';
import InputImagen from '../../general/forms/InputImagen';
import { AlertaConfirmComentario } from '../../general/Alerts';

function FprPagoFinanzas() {
  const [fondosfpr, setFondosFpr] = useState(null);
  const { id } = useParams();
  const { apiToken, dataUserLogged } = useUser();
  const [imageUrl, setImageUrl] = useState(null);
  const [imageBase64, setImageBase64] = useState(null);

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const handleImageProcessed = async (image) => {
    const urlImagen = URL.createObjectURL(image);
    setImageUrl(urlImagen);

    const base64Data = await blobToBase64(image);
    setImageBase64(base64Data);
  };

  const handlePagoFpr = () => {
    AlertaConfirmComentario({
      title: 'Deposito FPR',
      text: 'Se dejara la solicitud de FPR como depositado.',
      icon: 'warning',
      callback: (comentario) => {
        const fpr = {
          id: fondosfpr.id,
          deposita_id: dataUserLogged.id,
          tecnico_id: fondosfpr.tecnico_id,
          fecha_deposito: 'ok',
          estado_id: 2,
        };

        if (comentario) {
          fpr.comentario_deposito = comentario; // Añade el comentario a la estructura.
        }

        if (imageBase64) {
          const [header, content] = imageBase64.split(',');

          fpr.Attachment = {
            Content: content, // Aquí envías la parte base64 de la cadena (sin el header "data:image/png;base64,")
            ContentType: 'image/jpeg', // Siempre es JPEG ya que usamos 'JPEG' en el resizer.
            Filename: `deposito_${fpr.id}.jpeg`, // Puedes cambiar esto si tienes el nombre real del archivo.
          };
        }

        updateFpr(fpr.id, fpr, apiToken).then((response) => {
          if (response.status === 200) {
            alert('Actualizado con exito');
          } else {
            alert('No se pudo actualizar');
          }
        });
      },
    });
  };

  useEffect(() => {
    const fetchFpr = async () => {
      const response = await getFprById(id, apiToken);
      setFondosFpr(response.fpr);
      console.log(response);
    };
    fetchFpr();
  }, [apiToken, id]);

  return (
    <section className="row">
      <div className="col-12 col-lg-9">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              DEPOSITO A FONDO POR RENDIR {fondosfpr && `No. ${fondosfpr.id}`}
            </h4>
          </div>
          <div className="card-body p-0">
            {/* Datos */}
            {fondosfpr && (
              <div className="card">
                <div className="card-body d-flex justify-content-between">
                  <div className="col-4 shadow-sm p-2">
                    {/* Solicitud */}
                    <div>
                      <div className="bg-hyo mb-4">
                        <h5 className="card-subtitle mb-2 text-muted text-end mt-1 me-2">
                          SOLICITUD
                        </h5>
                      </div>
                      <div className="d-flex flex-column align-items-start">
                        <div className="d-flex justify-content-between w-100">
                          <strong>Fecha solicitud: </strong>
                          <span>{fondosfpr.created_at}</span>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                          <strong>Técnico: </strong>
                          <span>{`${fondosfpr.tecnico_nombre} ${fondosfpr.tecnico_apellido}`}</span>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                          <strong>Solicita: </strong>
                          <span>{`${fondosfpr.solicita_nombre} ${fondosfpr.solicita_apellido}`}</span>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                          <strong>Fecha del servicio: </strong>
                          <span>{fondosfpr.fecha_servicio}</span>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                          <strong>Monto: </strong>
                          <span>${fondosfpr.monto_deposito}</span>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                          <strong>Comentario: </strong>
                          <span>{fondosfpr.comentario_solicitud}</span>
                        </div>
                      </div>
                    </div>
                    {/* Deposito */}
                    <div>
                      <div className="bg-hyo mb-4">
                        <h5 className="card-subtitle mb-2 text-muted text-end mt-2 me-2">
                          DEPOSITO
                        </h5>
                      </div>
                      <div className="d-flex flex-column align-items-start">
                        <div className="d-flex justify-content-between w-100">
                          <strong>Fecha deposito: </strong>
                          <span>{fondosfpr.fecha_deposito}</span>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                          <strong>Deposita: </strong>
                          <span>{`${fondosfpr.deposita_nombre} ${fondosfpr.deposita_apellido}`}</span>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                          <strong>Comentario: </strong>
                          <span>{fondosfpr.comentario_deposito}</span>
                        </div>
                      </div>
                      {Number(fondosfpr.estado_id) === 1 && (
                        <div className="mt-5">
                          <span className="text-muted">
                            <strong>Comprobante del deposito:</strong>
                          </span>
                          <InputImagen
                            onImageProcessed={handleImageProcessed}
                          />
                        </div>
                      )}
                    </div>
                    {/* Justificacion */}
                    {Number(fondosfpr.estado_id) >= 2 && (
                      <div>
                        <div className="bg-hyo mb-4">
                          <h5 className="card-subtitle mb-2 text-muted text-end mt-2 me-2">
                            RENDICIÓN DE GASTOS
                          </h5>
                        </div>
                        <div className="d-flex flex-column align-items-start">
                          <div className="d-flex justify-content-between w-100">
                            <strong>Fecha rendicion: </strong>
                            <span>{fondosfpr.fecha_rendicion}</span>
                          </div>
                          <div className="d-flex justify-content-between w-100">
                            <strong>Monto rendido: </strong>
                            <span>${fondosfpr.monto_rendicion}</span>
                          </div>
                          <div className="d-flex justify-content-between w-100">
                            <strong>Diferencia a devolver: </strong>
                            <span>${fondosfpr.monto_devolucion}</span>
                          </div>
                          <div className="d-flex justify-content-between w-100">
                            <strong>Comentario: </strong>
                            <span>{fondosfpr.comentario_rendicion}</span>
                          </div>
                          <div className="d-flex justify-content-between w-100">
                            <strong>Valida: </strong>
                            <span>{`${fondosfpr.valida_r_nombre} ${fondosfpr.valida_r_apellido}`}</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* Devolucion */}
                    {Number(fondosfpr.estado_id) >= 3 && (
                      <div>
                        <div className="bg-hyo mb-4">
                          <h5 className="card-subtitle mb-2 text-muted text-end mt-2 me-2">
                            DEVOLUCIÓN
                          </h5>
                        </div>
                        <div className="d-flex flex-column align-items-start">
                          <div className="d-flex justify-content-between w-100">
                            <strong>Fecha devolución: </strong>
                            <span>{fondosfpr.fecha_servicio}</span>
                          </div>
                          <div className="d-flex justify-content-between w-100">
                            <strong>Monto devolución: </strong>
                            <span>${fondosfpr.monto_devolucion}</span>
                          </div>
                          <div className="d-flex justify-content-between w-100">
                            <strong>Comentario: </strong>
                            <span>{fondosfpr.comentario_devolucion}</span>
                          </div>
                          <div className="d-flex justify-content-between w-100">
                            <strong>Valida: </strong>
                            <span>{`${fondosfpr.valida_d_nombre} ${fondosfpr.valida_d_apellido}`}</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-8 ms-5">
                    <img
                      src={imageUrl}
                      alt="Imagen procesada"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="card-footer">
            <button className="btn btn-primary" onClick={handlePagoFpr}>
              FPR Depositado
            </button>
          </div>
        </div>
      </div>
      {/*  Tercera columna  */}
      <div className="col-12 col-lg-3">
        {/*  Card info usuario logueado  */}
        <CardUserLogged />

        {/*  Card ultimas rendiciones si no esta cargando y estadisticas no esta vacio  */}

        {/*  Card utimas aprovadas  */}
      </div>
    </section>
  );
}

export default FprPagoFinanzas;
