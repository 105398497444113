import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useUser from '../../../hooks/useUser';
import { getProyectos } from '../../../services/proyectos.js';

function ProyectosSelect({ opcionDefault, onSelect }) {
  const { apiToken } = useUser();
  const [proyectos, setProyectos] = useState([]);
  const [selectedOption, setSelectedOption] = useState(
    opcionDefault ? opcionDefault : ''
  );

  useEffect(() => {
    getProyectos(apiToken).then((data) => {
      setProyectos(data.proyectos);
    });
  }, [apiToken]);

  return (
    <>
      <div className="form-group mandatory">
        <label htmlFor="input-proyecto" className="form-label">
          Proyecto
        </label>
        <select
          value={selectedOption}
          className="form-select"
          id="basicSelect"
          name="proyecto"
          onChange={(e) => {
            setSelectedOption(e.target.value);
            if (onSelect)
              onSelect(
                proyectos.find((proyecto) => proyecto.id === e.target.value)
              );
          }}
        >
          <option value={''}>Seleccionar proyecto</option>
          {proyectos.map((proyecto) => (
            <option key={proyecto.id} value={proyecto.id}>
              {proyecto.cliente}
            </option>
          ))}
        </select>
        {/* link to open modal */}
        <Link
          to="#"
          data-bs-toggle="modal"
          data-bs-target="#modalProyecto"
          className="text-decoration-none"
        >
          <i className="bi bi-info-circle"></i> Información de proyectos
        </Link>
      </div>
      {/* Modal */}
      <div
        className="modal fade text-left"
        id="modalProyecto"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalProyectoLabel1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-scrollable modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalProyectoLabel1">
                Detalle de Proyectos
              </h5>
              <button
                type="button"
                className="close rounded-pill"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i data-feather="x"></i>
              </button>
            </div>
            <div className="modal-body">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Proyecto</th>
                    <th>Descripcion</th>
                  </tr>
                </thead>
                <tbody>
                  {proyectos.map((proyecto) => (
                    <tr key={proyecto.id}>
                      <td>{proyecto.cliente}</td>
                      <td>{proyecto.descripcion_proyecto}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn" data-bs-dismiss="modal">
                <i className="bx bx-x d-block d-sm-none"></i>
                <span className="d-none d-sm-block">Cerrar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProyectosSelect;
