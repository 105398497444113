import { useEffect, useState } from 'react';
import { getGastosPromedioTicketProyecto } from '../../../services/estadisticas';

function GastosTicketPromedioProyecto() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchGastosPromedioProyecto = async () => {
      const result = await getGastosPromedioTicketProyecto();
      const sortedData = result.estadisticas_gastos.sort(
        (a, b) =>
          parseInt(b.promedio_gasto, 10) - parseInt(a.promedio_gasto, 10)
      );
      setData(sortedData);
    };
    fetchGastosPromedioProyecto();
  }, []);

  const listItemStyle = {
    padding: '0.25rem 1.25rem',
  };

  return (
    <div className="card">
      <div className="card-header">
        <h6>COSTO PROMEDIO TICKET</h6>
        <small className="text-secondary">
          Es el costo promedio en la atención de un ticket para un proyecto o
          servicio
        </small>
      </div>
      <div className="card-body">
        <ul className="list-group list-group-flush">
          {data.map((item) => (
            <li
              key={item.proyecto}
              className="list-group-item d-flex justify-content-between align-items-center"
              style={listItemStyle}
            >
              {item.proyecto}
              <span className="badge bg-light text-primary border border-dark rounded-pill">
                ${item.promedio_gasto}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default GastosTicketPromedioProyecto;
