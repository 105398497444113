import { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

function BarrasGastosMesTecnicos({ data }) {
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (data && data.length) {
      const reversedData = [...data].reverse();
      let allTechnicians = [];
      let technicianTotals = {};

      // Recolecta todos los nombres de los técnicos y sus totales
      reversedData.forEach((item) =>
        item.datos.forEach((dato) => {
          const techName = `${dato.nombre} ${dato.apellido}`;
          if (!allTechnicians.includes(techName)) {
            allTechnicians.push(techName);
            technicianTotals[techName] = 0;
          }
          technicianTotals[techName] += parseFloat(dato.total_mes);
        })
      );

      // Ordena los técnicos por su total
      allTechnicians.sort((a, b) => technicianTotals[b] - technicianTotals[a]);

      const processedData = reversedData.map((item) => {
        let monthData = {};

        item.datos.forEach(
          (dato) =>
            (monthData[`${dato.nombre} ${dato.apellido}`] = parseFloat(
              dato.total_mes
            ))
        );

        return {
          name: `${item.mes}/${item.ano}`,
          data: allTechnicians.map((tech) => monthData[tech] || 0),
        };
      });

      setSeries(processedData);
      setCategories(allTechnicians);
    }
  }, [data]);

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: '13px',
              fontWeight: 900,
            },
            formatter: function (val) {
              return '$' + val;
            },
          },
        },
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    title: {
      text: 'Consolidados',
    },
    xaxis: {
      categories: categories,
      labels: {
        formatter: function (val) {
          return '$' + val;
        },
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return '$' + val;
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40,
    },
  };

  return (
    <div id="chart">
      <Chart options={options} series={series} type="bar" height={1800} />
    </div>
  );
}

export default BarrasGastosMesTecnicos;
