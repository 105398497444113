import { Link } from 'react-router-dom';
import { getFprs } from '../../../services/fondosPorRendir';
import useUser from '../../../hooks/useUser';
import { useEffect, useState } from 'react';
import { formatDate } from '../../../hooks/useDates';
import CustomTable from '../CustomTable';

const excelName = `Rendiciones pendientes de envío ${formatDate(new Date())}`;

function TablaPendientesDeposito() {
  const { apiToken, dataUserLogged } = useUser();
  const [fondosPr, setFondosPr] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [perfil, setPerfil] = useState('');

  useEffect(() => {
    // dataUserlogged en minuscula
    setPerfil(dataUserLogged.role.toLowerCase());
  }, [dataUserLogged]);

  const columns = [
    {
      name: 'No.',
      cell: (row) => (
        <Link className="text-reset" to={`/${perfil}/fpr/detalle/${row.id}`}>
          {row.id}
        </Link>
      ),
      sortable: true,
      sortFunction: (a, b) => Number(a.id) - Number(b.id),
      width: '5%',
      clave: 'id',
    },
    {
      name: 'F/SOLICITUD',
      selector: (row) => formatDate(new Date(row.created_at)),
      sortable: true,
      clave: 'created_at',
    },
    {
      name: 'NOMBRE',
      selector: (row) => row.tecnico_nombre,
      sortable: true,
      filterable: true,
      clave: 'tecnico_nombre',
    },
    {
      name: 'APELLIDO',
      selector: (row) => row.tecnico_apellido,
      sortable: true,
      clave: 'tecnico_apellido',
    },
    {
      name: 'REGIÓN',
      selector: (row) => row.region,
      sortable: true,
      clave: 'region',
    },
    {
      name: 'MONTO',
      selector: (row) => row.monto_deposito,
      sortable: true,
      clave: 'monto_deposito',
    },
    {
      name: 'F/SERVICIO',
      selector: (row) => formatDate(new Date(row.fecha_servicio)),
      sortable: true,
      clave: 'fecha_servicio',
    },
    {
      name: 'SOLICITANTE',
      selector: (row) => row.solicita_nombre + ' ' + row.solicita_apellido,
      sortable: true,
      clave: 'solicitante',
    },
    {
      name: 'VER',
      cell: (row) => (
        <Link
          to={`/${perfil}/fpr/detalle/${row.id}`}
          className="btn btn-outline-primary btn-sm"
        >
          <i className="bi bi-eye"></i>
        </Link>
      ),
      width: '5%',
      rigth: true,
    },
  ];

  useEffect(() => {
    const fetchFondosPr = async () => {
      const data = await getFprs(1, apiToken);
      setFondosPr(data.fpr);
      setIsLoading(false);
    };
    fetchFondosPr();
  }, [apiToken]);

  return (
    <CustomTable
      columns={columns}
      data={fondosPr}
      isLoading={isLoading}
      exportar={true}
      excelName={excelName}
    />
  );
}

export default TablaPendientesDeposito;
