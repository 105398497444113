import { getGastosMesTecnicos } from '../../../services/estadisticas';
import { useEffect, useState } from 'react';
import BarrasGastosMesTecnicos from '../charts/BarrasGastosMesTecnicos';

function TotalMesTecnicos() {
  const [gastosMesTecnicos, setGastosMesTecnicos] = useState([]);

  useEffect(() => {
    // async await
    const fetchGastosMesTecnicos = async () => {
      const gastos = await getGastosMesTecnicos();
      setGastosMesTecnicos(gastos.estadisticas_mes);
    };
    fetchGastosMesTecnicos();
  }, []);

  return <BarrasGastosMesTecnicos data={gastosMesTecnicos} />;
}

export default TotalMesTecnicos;
