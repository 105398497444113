import { apiUrl } from '../config';

// Obtiene los usuarios
export const getUsuarios = async (apiToken) => {
  const response = await fetch(`${apiUrl('administracion/usuarios')}`, {
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  });
  const data = await response.json();
  return data;
};

// registra un nuevo usuario
export const createUsuario = async (usuario, apiToken) => {
  const formdata = new FormData();
  formdata.append('id', usuario.id);
  formdata.append('rut', usuario.rut);
  formdata.append('nombre', usuario.nombre);
  formdata.append('apellido', usuario.apellido);
  formdata.append('email', usuario.email);
  formdata.append('password', usuario.password);
  formdata.append('role', usuario.role);
  formdata.append('comuna', usuario.comuna);
  formdata.append('direccion', usuario.direccion);
  formdata.append('telefono', usuario.telefono);
  formdata.append('region', usuario.region);
  formdata.append('valor_kilometro', usuario.valor_kilometro);
  const response = await fetch(`${apiUrl('auth/register')}`, {
    method: 'POST',
    body: formdata,
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  });
  const result = await response.json();
  result.status = response.status;
  return result;
};

// Elimina un usuario (vista gerencia)
export const deleteUsuario = async (id, apiToken) => {
  console.log(id);
  const response = await fetch(`${apiUrl('administracion/usuario')}/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  });
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtiene tecnicos
export const getTecnicos = async (apiToken) => {
  const response = await fetch(
    `${apiUrl('misc/tecnicos')}
    `,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};
