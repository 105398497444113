/** RUTAS
 * Rutas de la aplicación que son importadas en App.jsx
 */

export const LOGIN = '/'; // login
export const RECUPERAR = '/recuperar'; // recuperar contraseña

export const TECNICO = '/tecnico'; // tecnico
export const VALIDADOR = '/validador'; // validador
export const GERENCIA = '/gerencia'; // Gerencia
export const FINANZAS = '/finanzas'; // contabilidad
export const SUPERVISOR = '/supervisor'; // supervisor
