import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import TablaRendicionesAdministracion from '../../general/rendiciones/TablaAdministracion';
import TablaPendientesEnvio from '../../general/rendiciones/TablaPendientesEnvio';
import TablaRendicionesFinanzas from '../../general/rendiciones/TablaFinanzas';
import TablaRendicionesPagadas from '../../general/rendiciones/TablaPagadas';
import TablaRevisar from '../../general/rendiciones/TablaRevisar';
import TablaRendiciones from '../../general/rendiciones/TablaRendiciones';

const tableMap = {
  gerencia: {
    titulo: 'EN GERENCIA',
    componente: TablaRendicionesAdministracion,
  },
  validacion: {
    titulo: 'EN VALIDACIÓN',
    componente: TablaRevisar,
  },
  finanzas: {
    titulo: 'FINANZAS | PENDIENTES DE PAGO',
    componente: TablaRendicionesFinanzas,
  },
  consolidadas: {
    titulo: 'CONSOLIDADAS',
    componente: TablaRendicionesPagadas,
  },
  pendientes: {
    titulo: 'PENDIENTES DE ENVÍO',
    componente: TablaPendientesEnvio,
  },
  todas: {
    titulo: 'TODAS LAS RENDICIONES',
    componente: TablaRendiciones,
  },
  default: {
    titulo: '',
    componente: TablaRevisar,
  },
};

function RendicionesSupervisor() {
  const { tipotabla } = useParams();
  const [titulo, setTitulo] = useState('');
  const [TablaComponente, setTablaComponente] = useState(
    () => tableMap.default.componente
  );

  const [recargarTabla, setRecargarTabla] = useState(false);

  useEffect(() => {
    const tabla = tableMap[tipotabla] || tableMap.default;
    setTitulo(tabla.titulo);
    setTablaComponente(() => tabla.componente);
  }, [tipotabla]);

  return (
    <section className="section">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">{titulo}</h4>
        </div>
        <div className="card-body">
          {/* tabla */}
          <TablaComponente
            recargarTabla={recargarTabla}
            setRecargarTabla={setRecargarTabla}
          />
        </div>
      </div>
    </section>
  );
}

export default RendicionesSupervisor;
