import './spinerloader.css';

export function SpinerLoad() {
  return (
    <div className="loader-102">
      <span>ENVIANDO INFORMACIÓN</span>
    </div>
  );
}

export function SpinerDownload() {
  return (
    <div className="loader-101">
      <span>OBTENIENDO INFORMACIÓN</span>
    </div>
  );
}
