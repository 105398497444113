import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatDate, formatDateTime } from '../../../hooks/useDates';
import { getRendicion } from '../../../services/rendicionesT';
import useUser from '../../../hooks/useUser';
import { AlertaError } from '../../general/Alerts';
import { SpinerDownload } from '../../general/loader/SpinerLoader';
import PdfDownloader from '../../general/PdfDownloader';

function RendicionSemanaSup() {
  const { apiToken, dataUserLogged } = useUser();
  const [rendicion, setRendicion] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [totalKilometros, setTotalKilometros] = useState(0);
  const [totalGastos, setTotalGastos] = useState(0);
  const [kilometrosTotales, setKilometrosTotales] = useState(0);
  const [totalPagar, setTotalPagar] = useState(0);
  const [ultimoVisto, setUltimoVisto] = useState(null);
  const [subtotalesGastos, setSubtotalesGastos] = useState({});

  // obtener el id de la url
  const id = window.location.pathname.split('/')[3];
  const [perfil, setPerfil] = useState('');

  useEffect(() => {
    // dataUserlogged en minuscula
    setPerfil(dataUserLogged.role.toLowerCase());
  }, [dataUserLogged]);

  function ObtieneRendicion(id, apiToken) {
    getRendicion(id, apiToken)
      .then((response) => {
        if (response.status === 200) {
          setRendicion(response.rendicion);
        }
      })
      .catch((error) => {
        console.error('Error obteniendo rendición:', error);
        AlertaError({
          title: 'Error',
          error: 'Error al obtener la rendición',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    ObtieneRendicion(id, apiToken);
  }, [apiToken, id]);

  useEffect(() => {
    if (rendicion.tickets) {
      let kilometrosTotales = 0;
      let totalKilometros = 0;
      let totalGastos = 0;
      rendicion.tickets.forEach((ticket) => {
        kilometrosTotales += Number(ticket.kilometros);
        totalKilometros += Number(ticket.subtotal_kms);
        totalGastos += Number(ticket.subtotal_gastos);
      });

      // totalKilometros = kilometrosTotales * Number(rendicion.valor_kilometro);

      // setTotalPagar(totalGastos);
      setTotalPagar(totalGastos + totalKilometros);
      setKilometrosTotales(kilometrosTotales);
      setTotalKilometros(totalKilometros);
      // setTotalGastos(totalGastos - totalKilometros);
      setTotalGastos(totalGastos);
    }
  }, [rendicion]);

  useEffect(() => {
    if (rendicion.tickets) {
      const nuevoMapaSumas = {};

      rendicion.tickets.forEach((ticket) => {
        ticket.items_gastos.forEach((item) => {
          const tipoGasto = item.tipo_gasto;
          const monto = Number(item.monto);

          if (!nuevoMapaSumas[tipoGasto]) {
            nuevoMapaSumas[tipoGasto] = monto;
          } else {
            nuevoMapaSumas[tipoGasto] += monto;
          }
        });
      });

      setSubtotalesGastos(nuevoMapaSumas);
    }
  }, [rendicion]);

  useEffect(() => {
    const ultiaSemanaVista = sessionStorage.getItem('id-semana-viendo');
    if (id === ultiaSemanaVista) {
      const ultimoVisto = sessionStorage.getItem('ultimo-visto');
      setUltimoVisto(ultimoVisto);
    }
  }, [id]);

  const handleUltimoVisto = (ticket) => {
    // almacena ultimo visto en session storage
    sessionStorage.setItem('ultimo-visto', ticket);
    // almacena el id de la semana en session storage
    sessionStorage.setItem('id-semana-viendo', id);
  };

  return (
    <section className="row" id="documento">
      <div className="col-12">
        {isLoading ? <SpinerDownload /> : null}
        <div className="card">
          <div className="card-header">
            <h4>Rendición Semana {rendicion.numero}</h4>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th colSpan="1">#{rendicion.numero}</th>
                    <th colSpan="1">
                      Desde {formatDate(rendicion.fecha_desde)}
                    </th>
                    <th colSpan="1">
                      Hasta {formatDate(rendicion.fecha_hasta)}
                    </th>
                    <th colSpan="2">
                      Enviada el:{' '}
                      {formatDateTime(rendicion.fecha_envio) || 'No enviada'}
                    </th>
                    <th>Detalle Gastos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="1">Nombre</th>
                    <td colSpan="3">
                      {rendicion.nombre} {rendicion.apellido}
                    </td>

                    <td>
                      <strong>Región</strong> {rendicion.region}
                    </td>
                    <td
                      rowSpan="2"
                      style={{ textAlign: 'left', verticalAlign: 'top' }}
                    >
                      <table className="table">
                        <thead>
                          <tr style={{ fontSize: '13px', height: '30px' }}>
                            <th>Item / Concepto</th>
                            <th className="text-end">Subtotal</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isLoading && rendicion.tickets.length > 0
                            ? Object.entries(subtotalesGastos).map(
                                ([tipoGasto, suma]) => (
                                  <tr
                                    key={tipoGasto}
                                    style={{ fontSize: '13px', height: '30px' }}
                                  >
                                    <td>{tipoGasto}</td>
                                    <td className="text-end">${suma}</td>
                                  </tr>
                                )
                              )
                            : null}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="5">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th width="200px">Kilómetros</th>
                            <th width="200px">Total Kilómetraje</th>
                            <th width="200px">Total Gastos</th>
                            <th></th>
                            <th width="200px">Información adicional</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{kilometrosTotales || 0} kms</td>
                            <td>${totalKilometros || '0'} pesos</td>
                            <td colSpan="2">${totalGastos || '0'} pesos</td>
                            <td>
                              {rendicion.valor_kilometro &&
                                Number(rendicion.valor_kilometro) > 0 && (
                                  <span className="badge rounded-pill bg-light-primary fw-normal">
                                    Km: ${rendicion.valor_kilometro}
                                  </span>
                                )}
                              <span className="badge rounded-pill bg-light-primary fw-normal ms-2">
                                CC:{' '}
                                {rendicion.centro_costo
                                  ? rendicion.centro_costo.slice(-3)
                                  : 'Sin Dato'}
                              </span>
                              <span className="badge rounded-pill bg-light-primary fw-normal ms-2">
                                {rendicion.tipo_tecnico
                                  ? rendicion.tipo_tecnico
                                  : 'Sin Dato'}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <th>Comentario Técnico:</th>
                            <td colSpan="4" style={{ maxWidth: '600px' }}>
                              {rendicion.observaciones_tecnico ||
                                'Sin comentario'}
                            </td>
                          </tr>
                          <tr>
                            <th>Comentario Validador:</th>
                            <td colSpan="4">
                              {rendicion.observaciones_validador ||
                                'Sin comentario'}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <th colSpan="1" scope="row" className="fs-5">
                      Total a pagar
                    </th>
                    <th colSpan="3" className="fs-4">
                      ${totalPagar || '0'} pesos
                    </th>
                    <th>Estado: {rendicion.estado_nombre}</th>
                    <th className="text-end">
                      Total Gastos ${totalGastos || '0'}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-12">
        <div className="card">
          <div className="card-header p-3 bg-success bg-opacity-10 border border-hyo border-start-1 rounded-end d-flex justify-content-between">
            <h4>Tickets / Servicios</h4>
            <PdfDownloader
              downloadFileName={`Rendicion sem-${rendicion.numero} ${rendicion.nombre} ${rendicion.apellido}`}
              rootElementId="documento"
            />
          </div>
          <div className="card-body ">
            {/* Tabla tickets */}
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Fecha</th>
                    <th>Proyecto</th>
                    <th>Ticket</th>
                    <th>Origen</th>
                    <th>Destino</th>
                    <th>R. Urbano</th>
                    <th>Kms</th>
                    <th>Subt. Kms</th>
                    <th>Gastos</th>
                    <th>Subt. Gastos</th>
                    <th>Total</th>
                    <th className="oculto-pdf">Ver</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading && rendicion.tickets.length > 0 ? (
                    rendicion.tickets.map((ticket, index) => (
                      <tr
                        key={ticket.id}
                        className={
                          Number(ticket.items_rechazados) > 0
                            ? 'table-danger'
                            : ''
                        }
                      >
                        <td
                          className={`${
                            ticket.id === ultimoVisto ? 'bg-secondary' : ''
                          }`}
                        >
                          {index + 1}
                        </td>
                        <td>{formatDate(ticket.fecha_atencion)}</td>
                        <td>{ticket.cliente}</td>
                        <td>
                          {ticket.nomenclatura}
                          {ticket.ticket}
                        </td>
                        <td>
                          {ticket.direccion_desde}, {ticket.comuna_desde}
                        </td>
                        {/* <td>{ticket.comuna_desde}</td> */}
                        <td>
                          {ticket.direccion_hasta}, {ticket.comuna_hasta}
                        </td>
                        {/* <td>{ticket.comuna_hasta}</td> */}
                        <td>{ticket.radio_urbano === '1' ? 'Si' : 'No'}</td>
                        <td>{ticket.kilometros || 0}</td>
                        <td>${ticket.subtotal_kms || 0}</td>
                        <td>
                          <ul className="m-0">
                            {ticket.items_gastos.map((item, i) => (
                              <li key={i} className="d-flex">
                                <small className="flex-grow-1 me-2">
                                  {`${item.tipo_gasto} `}
                                </small>
                                <small>${item.monto}</small>
                              </li>
                            ))}
                          </ul>
                        </td>
                        <td>${ticket.subtotal_gastos || 0}</td>
                        <td>
                          $
                          {Number(ticket.subtotal_kms) +
                            Number(ticket.subtotal_gastos)}
                        </td>
                        <td
                          className={`oculto-pdf ${
                            ticket.id === ultimoVisto ? 'bg-secondary' : ''
                          }`}
                        >
                          <div className="d-flex justify-content-center">
                            <Link
                              to={`/${perfil}/rendicion/ticket/${ticket.id}`}
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                handleUltimoVisto(ticket.id);
                              }}
                            >
                              <i className="bi bi-eye"></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="14">Rendicion sin tickets</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card-footer">
            <table className="table mb-0 table-bordered">
              <thead>
                <tr>
                  <th>Historial Rendición</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <small>
                      Revisada:{' '}
                      {rendicion &&
                      rendicion.fecha_valida &&
                      rendicion.nombre_validador &&
                      rendicion.apellido_validador
                        ? `${formatDate(rendicion.fecha_valida)} | ${
                            rendicion.nombre_validador
                          } ${rendicion.apellido_validador}`
                        : 'Sin datos'}
                    </small>
                  </td>
                </tr>
                <tr>
                  <td>
                    <small>
                      Aprobada:{' '}
                      {rendicion &&
                      rendicion.fecha_aprueba &&
                      rendicion.nombre_gerente &&
                      rendicion.apellido_gerente
                        ? `${formatDate(rendicion.fecha_aprueba)} | ${
                            rendicion.nombre_gerente
                          } ${rendicion.apellido_gerente}`
                        : 'Sin datos'}
                    </small>
                  </td>
                </tr>
                <tr>
                  <td>
                    <small>
                      Consolidada:{' '}
                      {rendicion &&
                      rendicion.fecha_pago &&
                      rendicion.nombre_finanza &&
                      rendicion.apellido_finanza
                        ? `${formatDate(rendicion.fecha_pago)} | ${
                            rendicion.nombre_finanza
                          } ${rendicion.apellido_finanza}`
                        : 'Sin datos'}
                    </small>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="row text-end">
              <small className="position-absolute">
                Ultima modificación: {formatDateTime(rendicion.updated_at)}
              </small>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RendicionSemanaSup;
