import { useEffect, useState } from 'react';
import { getTecnicos } from '../../../services/usuarios';
import useUser from '../../../hooks/useUser';

const SelectTecnicos = ({ value, onChange }) => {
  const [tecnicos, setTecnicos] = useState([]);
  const { apiToken } = useUser();

  useEffect(() => {
    const fetchTecnicos = async () => {
      const data = await getTecnicos(apiToken);
      if (data.status === 200) {
        setTecnicos(data.usuarios);
      }
    };
    fetchTecnicos();
  }, [apiToken]);

  const handleChange = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <select className="form-select" value={value} onChange={handleChange}>
      <option value="">Seleccione un técnico</option>
      {tecnicos.map((tecnico) => (
        <option key={tecnico.id} value={tecnico.id}>
          {`${tecnico.nombre} ${tecnico.apellido}`}
        </option>
      ))}
    </select>
  );
};

export default SelectTecnicos;
