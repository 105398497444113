import React, { useEffect, useState } from 'react';
import { getRegiones } from '../../../services/dataSelects';
import useUser from '../../../hooks/useUser';

function RegionesSelect({ seleccion, selDefault }) {
  const { apiToken } = useUser();
  const [regiones, setRegiones] = useState([]);
  const [region, setRegion] = useState();

  useEffect(() => {
    getRegiones(apiToken).then((data) => {
      setRegiones(data);
    });
  }, [apiToken]);

  // setea el valor por defecto del select la region pasada
  useEffect(() => {
    setRegion(selDefault);
  }, [selDefault]);

  function handleChange(id) {
    const regionSeleccionada = regiones.find((region) => region.id === id);
    seleccion(regionSeleccionada);
    setRegion(id);
  }

  return (
    <div className="form-group mandatory">
      <label htmlFor="select-region" className="form-label">
        Región
      </label>
      <select
        value={region}
        defaultValue={'DEFAULT'}
        className="form-select"
        id="select-region"
        name="region"
        onChange={(e) => {
          handleChange(e.target.value);
        }}
      >
        <option value={'DEFAULT'} disabled>
          Seleccionar región
        </option>
        {regiones.map((region, i) => (
          <option key={i} value={region.id}>
            {region.region}
          </option>
        ))}
      </select>
    </div>
  );
}

export default RegionesSelect;
