import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useUser from '../../../hooks/useUser.js';
import { getRendicionesPendientes } from '../../../services/rendicionesT.js';
import { formatDate, getNumeroSemana } from '../../../hooks/useDates.js';

function RendicionesDash() {
  const [rendicionesPendientes, setRendicionesPendientes] = useState([]);
  const { dataUserLogged, apiToken } = useUser();

  useEffect(() => {
    const fetchData = async () => {
      const rendicionesPendientesData = await getRendicionesPendientes(
        dataUserLogged.id,
        apiToken
      );
      console.log(rendicionesPendientesData);
      setRendicionesPendientes(rendicionesPendientesData.rendiciones);
    };
    fetchData();
  }, [apiToken, dataUserLogged.id]);

  return (
    <div className="card px-0 rounded-5 rounded-top-0 d-block d-sm-none">
      <div className="card-header">
        <h4>Rendiciones Pendientes</h4>
      </div>
      <div className="card-body">
        <div
          id="carouselExampleCaptions"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <ol className="carousel-indicators">
            {rendicionesPendientes.map((_, index) => (
              <li
                key={index}
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to={index}
                className={index === 0 ? 'active' : ''}
              ></li>
            ))}
          </ol>
          <div className="carousel-inner">
            {rendicionesPendientes.map((rendicion, index) => (
              <div
                key={rendicion.id}
                className={`carousel-item ${index === 0 ? 'active' : ''}`}
              >
                <div className="card border border-4 mt-2 shadow">
                  <div className="card-header pb-2">
                    <h2>SEMANA: {rendicion.numero}</h2>
                  </div>
                  <div className="card-body position-relative">
                    <div
                      className="position-absolute top-0 end-0 translate-middle"
                      style={{
                        fontSize: '100px',
                        color:
                          rendicion.estado_nombre === 'Rechazada Validacion'
                            ? 'orange'
                            : rendicion.estado_nombre === 'Pendiente' &&
                              Number(rendicion.numero) === getNumeroSemana()
                            ? 'green'
                            : rendicion.estado_nombre === 'Pendiente' &&
                              Number(rendicion.numero) === getNumeroSemana() - 1
                            ? 'orange'
                            : rendicion.estado_nombre === 'Pendiente' &&
                              Number(rendicion.numero) <= getNumeroSemana() - 2
                            ? 'red'
                            : 'inherit',
                        filter: 'drop-shadow(3px 3px 5px green)',
                      }}
                    >
                      {rendicion.estado_nombre === 'Pendiente' &&
                      Number(rendicion.numero) <= getNumeroSemana() - 2 ? (
                        <i className="bi bi-calendar-x"></i>
                      ) : rendicion.estado_nombre === 'Pendiente' &&
                        Number(rendicion.numero) === getNumeroSemana() - 1 ? (
                        <i className="bi bi-calendar-x"></i>
                      ) : rendicion.estado_nombre === 'Pendiente' &&
                        Number(rendicion.numero) === getNumeroSemana() ? (
                        <i className="bi bi-calendar-check"></i>
                      ) : rendicion.estado_nombre === 'Rechazada Validacion' ? (
                        <i className="bi bi-exclamation-triangle"></i>
                      ) : (
                        rendicion.estado_nombre
                      )}
                    </div>
                    <p className="mb-0">
                      <small>DESDE: {formatDate(rendicion.fecha_desde)}</small>
                    </p>
                    <p className="mb-1">
                      <small>HASTA: {formatDate(rendicion.fecha_hasta)}</small>
                    </p>
                    <p>
                      <strong>MONTO: ${rendicion.total_pago}</strong>
                    </p>
                    <div
                      className="position-absolute top-100 start-50 translate-middle pb-4 text-uppercase text-center"
                      style={{ width: '15rem' }}
                    >
                      <strong
                        style={{
                          color:
                            rendicion.estado_nombre === 'Rechazada Validacion'
                              ? 'orange'
                              : rendicion.estado_nombre === 'Pendiente' &&
                                Number(rendicion.numero) === getNumeroSemana()
                              ? 'green'
                              : rendicion.estado_nombre === 'Pendiente' &&
                                Number(rendicion.numero) ===
                                  getNumeroSemana() - 1
                              ? 'orange'
                              : rendicion.estado_nombre === 'Pendiente' &&
                                Number(rendicion.numero) <=
                                  getNumeroSemana() - 2
                              ? 'red'
                              : 'inherit',
                        }}
                      >
                        {rendicion.estado_nombre === 'Pendiente' &&
                        Number(rendicion.numero) <= getNumeroSemana() - 2
                          ? 'Pendiente Atrasada'
                          : rendicion.estado_nombre === 'Pendiente' &&
                            Number(rendicion.numero) === getNumeroSemana() - 1
                          ? 'Pendiente'
                          : rendicion.estado_nombre === 'Pendiente' &&
                            Number(rendicion.numero) === getNumeroSemana()
                          ? 'Semana Actual'
                          : rendicion.estado_nombre === 'Rechazada Validacion'
                          ? 'Rechazada Validación'
                          : rendicion.estado_nombre}
                      </strong>
                    </div>
                  </div>
                  <div className="card-footer">
                    <Link to={`/tecnico/rendiciones/semana/${rendicion.id}`}>
                      <button className="btn btn-primary btn-block">
                        Ver Rendición
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <a
            className="carousel-control-prev"
            href="#carouselExampleCaptions"
            role="button"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleCaptions"
            role="button"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default RendicionesDash;
