import React from 'react';
import ReactDOM from 'react-dom/client';

// Estilos css general
import './assets/css/main/app.css';
import './assets/css/main/app-dark.css';
import './assets/css/main/fuentes.css';
import './assets/css/main/herramientas.css';
import './assets/css/shared/iconly.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(
  //<React.StrictMode>
  <App />
  // </React.StrictMode>
);
