import React, { useRef, useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { urlS3Rendiciones } from '../../../config';

// Necesitamos establecer una URL de trabajador para react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function VisorComprobante({ item, onClose }) {
  const imageRef = useRef(null);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [zoomFactor, setZoomFactor] = useState(1);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const isImage = (file) => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    return imageExtensions.some((ext) => file.toLowerCase().endsWith(ext));
  };

  const isPdf = (file) => {
    return file.toLowerCase().endsWith('pdf');
  };

  const handleClose = () => {
    onClose(false);
  };

  const rotateLeft = () => {
    setRotationAngle(rotationAngle - 90);
  };

  const rotateRight = () => {
    setRotationAngle(rotationAngle + 90);
  };

  const handleZoom = (event) => {
    const direction = event.deltaY > 0 ? 1 : -1;
    let newZoomFactor = zoomFactor + direction * -0.5;
    newZoomFactor = Math.min(Math.max(1, newZoomFactor), 2);
    setZoomFactor(newZoomFactor);
  };

  const handleMouseMove = (event) => {
    if (imageRef.current) {
      const boundingRect = imageRef.current.parentNode.getBoundingClientRect();
      setMousePosition({
        x: event.clientX - boundingRect.left - boundingRect.width / 2,
        y: event.clientY - boundingRect.top - boundingRect.height / 2,
      });
    }
  };

  useEffect(() => {
    const handleWheel = (event) => {
      if (event.target.closest('.zoomable')) {
        event.preventDefault();
      }
    };

    window.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <div className="card">
      <div className="card-header d-flex justify-content-between">
        <h4>
          <i className="bi bi-receipt text-info"></i> Visor de Comprobante
        </h4>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={handleClose}
        ></button>
      </div>
      <div className="card-content p-1">
        {/* imagen o PDF */}
        {item ? (
          <div
            onWheel={handleZoom}
            onMouseMove={handleMouseMove}
            className="zoomable"
            style={{
              position: 'relative',
              width: '100%',
              height: '0',
              paddingBottom: '100%',
              overflow: 'hidden',
            }}
          >
            {isImage(item.comprobante) && (
              <img
                src={`${urlS3Rendiciones}${item.comprobante}`}
                alt=""
                className="img-fluid rounded mx-auto d-block"
                ref={imageRef}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: `translate(-50%, -50%) scale(${zoomFactor}) translate(${
                    mousePosition.x * (zoomFactor - 1)
                  }px, ${
                    mousePosition.y * (zoomFactor - 1)
                  }px) rotate(${rotationAngle}deg)`,
                  transition: 'transform 0.3s ease',
                }}
              />
            )}

            {isPdf(item.comprobante) && (
              <div style={{ width: '100%', height: '650px', overflow: 'auto' }}>
                <Document file={`${urlS3Rendiciones}${item.comprobante}`}>
                  <Page
                    pageNumber={1}
                    width={Math.min(
                      600,
                      document.documentElement.clientWidth - 20
                    )}
                  />
                </Document>
              </div>
            )}

            {!isImage(item.comprobante) && !isPdf(item.comprobante) && (
              <p>Formato de archivo no soportado</p>
            )}

            {item && isImage(item.comprobante) && (
              <div
                className="btn-group"
                style={{
                  position: 'absolute',
                  bottom: '10px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  zIndex: 1,
                }}
              >
                <button
                  type="button"
                  className="btn btn-outline-info btn-sm"
                  onClick={rotateLeft}
                >
                  <i className="bi bi-arrow-counterclockwise"></i> Rotar
                </button>
                <button
                  type="button"
                  className="btn btn-outline-info btn-sm"
                  onClick={rotateRight}
                >
                  Rotar <i className="bi bi-arrow-clockwise"></i>
                </button>
              </div>
            )}
          </div>
        ) : null}
      </div>
      <div className="card-footer">
        <h6 className="text-info">
          {!item ? null : `Concepto: ${item.tipo_gasto} `}
        </h6>
        <h6 className="text-info">
          {item && item.monto > 0 ? `$${item.monto} pesos.` : null}
        </h6>
      </div>
    </div>
  );
}

export default VisorComprobante;
