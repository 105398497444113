import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useUser from '../../../../hooks/useUser';
import { getComunasRegion } from '../../../../services/dataSelects';
import { addTicket } from '../../../../services/rendicionesT';
import {
  AlertaConfirm,
  AlertaError,
  AlertaSuccess,
} from '../../../general/Alerts';
import ProyectosSelect from '../../../general/forms/ProyectosSelect';
import { validaNuevoTicket } from '../../../../hooks/useValidaTicket';

const INITIAL_TICKET_STATE = {
  rendicion_id: null,
  fecha_atencion: null,
  hora_inicio: null,
  hora_termino: null,
  proyecto_id: null,
  ticket: '',
  radio_urbano: 1,
  direccion_desde: null,
  comuna_desde_id: null,
  direccion_hasta: null,
  comuna_hasta_id: null,
  region_id: null,
  observacion: null,
};

// enviar ticket async
const enviarTicket = async (
  apiToken,
  ticket,
  nomenclatura,
  navigate,
  setTicket,
  dataUserLogged,
  id
) => {
  const data = await addTicket(ticket, apiToken);
  if (data.status === 200) {
    AlertaSuccess({
      title: 'Ticket agregado',
      success: `Se agregó el ticket ${nomenclatura}${ticket.ticket} a la rendición`,
    });
    // atras
    navigate(-1);
    setTicket(INITIAL_TICKET_STATE);
    setTicket({
      ...ticket,
      rendicion_id: id,
      region_id: dataUserLogged.region,
    });
  } else {
    AlertaError({
      title: 'Error al agregar ticket',
      text: data.message,
    });
  }
};

//  COMPONENTE
function AgregaTicket() {
  const [nomenclatura, setNomenclatura] = useState(null);
  const [comunas, setComunas] = useState([]);
  const { apiToken, dataUserLogged } = useUser();
  const [ticket, setTicket] = useState(INITIAL_TICKET_STATE);
  const { id, desde, hasta } = useParams();
  const navigate = useNavigate();
  const [ticketInput, setTicketInput] = useState({
    type: 'number',
    placeholder: 'Numero de ticket',
    disabled: false,
  });

  // scroll top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getComunas = () => {
    getComunasRegion(dataUserLogged.region, apiToken).then((data) => {
      setComunas(data.comunas);
    });
  };

  const seleccionProyecto = (proyecto) => {
    setNomenclatura(proyecto.nomenclatura);

    setTicketInput({
      type: proyecto.nomenclatura === null ? 'text' : 'number',
      placeholder:
        proyecto.nomenclatura === null
          ? 'Especificar en observación'
          : 'Numero de ticket',
      disabled: proyecto.nomenclatura === null,
    });
    setTicket({
      ...ticket,
      proyecto_id: proyecto.id,
      ticket: proyecto.nomenclatura === null ? proyecto.cliente : '',
    });
  };

  useEffect(() => {
    setTicket({
      ...ticket,
      rendicion_id: id,
      region_id: dataUserLogged.region,
    });
    getComunas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const value =
      e.target.type === 'checkbox'
        ? e.target.checked
          ? 1
          : 0
        : e.target.value;
    setTicket({
      ...ticket,
      [e.target.name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validaNuevoTicket(ticket)) {
      AlertaConfirm({
        title: `¿Agregar Ticket?`,
        text: `Se agregará el ticket ${nomenclatura}${ticket.ticket} a la rendición`,
        callback: () => {
          enviarTicket(
            apiToken,
            ticket,
            nomenclatura,
            navigate,
            setTicket,
            dataUserLogged,
            id
          );
        },
      });
    }
  };

  return (
    <section className="row">
      <div className="col-md-4 col-12 mb-5">
        <h4>Agrega Ticket</h4>
        <form onSubmit={handleSubmit}>
          <div className="form-group mandatory">
            <label htmlFor="fecha_atencion" className="form-label">
              Fecha de Atención
            </label>
            <input
              type="date"
              className="form-control"
              id="fecha_atencion"
              name="fecha_atencion"
              min={desde}
              max={hasta}
              onChange={handleChange}
            />
          </div>
          <div className="row">
            <div className="form-group col-6 mandatory">
              <label htmlFor="hora_inicio" className="form-label">
                Hora de Inicio
              </label>
              <input
                type="time"
                className="form-control"
                id="hora_inicio"
                name="hora_inicio"
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-6 mandatory">
              <label htmlFor="hora_termino" className="form-label">
                Hora de Término
              </label>
              <input
                type="time"
                className="form-control"
                id="hora_termino"
                name="hora_termino"
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Proyectos */}
          <ProyectosSelect onSelect={seleccionProyecto} />

          {/* Ticket */}
          <div className="input-group">
            <span className="input-group-text" id="nomenclatura">
              {nomenclatura}
            </span>
            <input
              type={ticketInput.type}
              className="form-control"
              placeholder={ticketInput.placeholder}
              name="ticket"
              value={ticket.ticket}
              onChange={handleChange}
              disabled={ticketInput.disabled}
            />
          </div>

          <div className="row">
            {/* Correlativo Gepro */}
            <div className="col-6 mt-2 mb-3">
              <span>IBM Gepro</span>
              <div className="input-group">
                <span className="input-group-text" id="gepro-g">
                  G-
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="000123"
                  maxLength="6"
                  name="correlativo_gepro"
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* Radio Urbano */}
            <div className="col-6">
              <div className="form-group form-check mt-4">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="radio_urbano"
                  id="radio_urbano"
                  onChange={handleChange}
                  checked={ticket.radio_urbano}
                />
                <label className="form-check-label" htmlFor="radio_urbano">
                  Radio Urbano
                </label>
              </div>
            </div>
          </div>

          {/* Direccion Desde */}
          <div className="form-group mandatory">
            <label htmlFor="direccion_desde" className="form-label">
              Dirección Desde
            </label>
            <input
              type="text"
              className="form-control"
              id="direccion_desde"
              name="direccion_desde"
              onChange={handleChange}
            />
          </div>

          {/* Comuna Desde */}
          <div className="form-group mandatory">
            <label htmlFor="comuna_desde_id" className="form-label">
              Comuna Desde
            </label>
            <select
              className="form-control"
              id="comuna_desde_id"
              name="comuna_desde_id"
              defaultValue="0"
              onChange={(e) => {
                handleChange(e);
              }}
            >
              <option value="0" disabled>
                Selecciona una opción
              </option>
              {comunas.map((comuna) => (
                <option key={comuna.id} value={comuna.id}>
                  {comuna.comuna}
                </option>
              ))}
            </select>
          </div>

          {/* Direccion Hasta */}
          <div className="form-group mandatory">
            <label htmlFor="direccion_hasta" className="form-label">
              Dirección Hasta
            </label>
            <input
              type="text"
              className="form-control"
              id="direccion_hasta"
              name="direccion_hasta"
              onChange={handleChange}
            />
          </div>

          {/* Comuna Hasta */}
          <div className="form-group mandatory">
            <label htmlFor="comuna_hasta_id" className="form-label">
              Comuna Hasta
            </label>
            <select
              className="form-control"
              id="comuna_hasta_id"
              name="comuna_hasta_id"
              defaultValue="0"
              onChange={(e) => {
                handleChange(e);
              }}
            >
              <option value="0" disabled>
                Selecciona una opción
              </option>
              {comunas.map((comuna) => (
                <option key={comuna.id} value={comuna.id}>
                  {comuna.comuna}
                </option>
              ))}
            </select>
          </div>

          {/* Observacion */}
          <div className="form-group">
            <label htmlFor="observacion">Observación</label>
            <textarea
              className="form-control"
              id="observacion"
              name="observacion"
              rows="3"
              onChange={handleChange}
            ></textarea>
          </div>

          {/* Botones */}
          <button type="submit" className="btn btn-primary btn-block">
            <i className="bi bi-cloud-plus"></i> Guardar Ticket
          </button>
        </form>
      </div>
      <div className="col-md-8 d-xs-none">
        <h4>Importante</h4>
        <ul>
          <li>
            El ticket quedara registrado por {dataUserLogged.nombre}{' '}
            {dataUserLogged.apellido}.
          </li>
          <li>
            El ticket debe ser de la región de {dataUserLogged.region_nombre}.
          </li>
          <li>Llenar todos los campos.</li>
          <li>Seleccionar una opción en cada select.</li>
          <li>Si el ticket es de radio urbano, marcar la casilla.</li>
          <li>Mantenga al día sus rendiciones de gastos.</li>
          <li>Revise la información antes de presionar Agregar.</li>
        </ul>
        <span>
          Soporte técnico o dudas con la app{' '}
          <a
            href="https://wa.me/56949247732?text=Hola%20contacto%20por%20app%20HyO"
            target="blank"
          >
            <i className="bi bi-whatsapp bi-3x"></i>
          </a>
        </span>
      </div>
    </section>
  );
}

export default AgregaTicket;
