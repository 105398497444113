import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import useUser from '../../hooks/useUser';
import CardInfoSemana from '../general/cardsInfo/CardInfoSemana';

function MenuSupervisor() {
  const location = useLocation();
  const { pathname } = location;
  const path = pathname.split('/');

  const { logout } = useUser(); // hook para hacer logout

  return (
    <div className="sidebar-menu">
      <ul className="menu">
        <li className="sidebar-title">Menú</li>
        <li
          className={'sidebar-item ' + (path[2] === undefined ? 'active' : '')}
        >
          <Link to="/tecnico" className="sidebar-link">
            <i className="bi bi-grid-fill"></i>
            <span>Dashboard</span>
          </Link>
        </li>
        {/* Rendiciones */}
        <li
          className={
            'sidebar-item has-sub ' +
            (path[2] === 'rendiciones' || path[2] === 'rendicion'
              ? 'active'
              : '')
          }
        >
          <Link to="#" className="sidebar-link">
            <i className="bi bi-person-video2"></i>
            <span>Rendiciones</span>
          </Link>
          <ul className="submenu">
            <li className="submenu-item">
              <Link to="/supervisor/rendiciones/pendientes">
                Pendientes de Envio
              </Link>
            </li>
            <li className="submenu-item">
              <Link to="/supervisor/rendiciones/validacion">En Validación</Link>
            </li>

            <li className="submenu-item">
              <Link to="/supervisor/rendiciones/gerencia">
                En Aprobación Gerencial
              </Link>
            </li>
            <li className="submenu-item">
              <Link to="/supervisor/rendiciones/finanzas">En Finanzas</Link>
            </li>
            <li className="submenu-item">
              <Link to="/supervisor/rendiciones/consolidadas">
                Consolidadas
              </Link>
            </li>
            <li className="submenu-item">
              <Link to="/supervisor/rendiciones/todas">Ver Todas</Link>
            </li>
          </ul>
        </li>

        {/* Informes */}
        <li
          className={'sidebar-item ' + (path[2] === 'informes' ? 'active' : '')}
        >
          <Link to="/supervisor/informes" className="sidebar-link">
            <i className="bi bi-archive"></i>
            <span>Informes</span>
          </Link>
        </li>

        {/* Perfil */}
        <li
          className={'sidebar-item ' + (path[2] === 'perfil' ? 'active' : '')}
        >
          <Link to="/usuario/perfil" className="sidebar-link">
            <i className="bi bi-person"></i>
            <span>Perfil</span>
          </Link>
        </li>
        {/* Cerrar Sesion */}
        <li className="sidebar-item">
          <Link to="/" className="sidebar-link" onClick={logout}>
            <i className="bi bi-door-open-fill"></i>
            <span>Cerrar Sesión</span>
          </Link>
        </li>
      </ul>
      <CardInfoSemana />
    </div>
  );
}

export default MenuSupervisor;
