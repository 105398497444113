import {
  GERENCIA,
  TECNICO,
  VALIDADOR,
  FINANZAS,
  RECUPERAR,
  SUPERVISOR,
} from './routes/rutas';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { UserContextProvider } from './context/UserContext';
import PublicRoute from './components/router/PublicRoute';
// LOGIN
import Login from './components/login/Login';
import RecuperarPassword from './components/login/Recuperar';
// TECNICO
import TecnicoRoute from './components/router/TecnicoRoute';
import DashTecnico from './components/tecnico/Dashboard';
// import NuevaRendicion from './components/tecnico/rendiciones/nueva/NuevaRendicion';
import RendicionesTecnico from './components/tecnico/rendiciones/todas/RendicionesTecnico';
import RendicionSemana from './components/tecnico/rendiciones/semana/RendicionSemana';

import AgregaTicket from './components/tecnico/rendiciones/nueva/AgregaTicket';
import VerTicketTec from './components/tecnico/rendiciones/semana/VerTicketTec';

import FprDetalleTecnico from './components/tecnico/fpr/FprDetalleTecnico';
import FprAgregarTicket from './components/tecnico/fpr/FprAgregarTicket';

// VALIDADOR
import ValidadorRoute from './components/router/ValidadorRoute';
import DashValidador from './components/validador/Dashboard';
import RendicionesValidador from './components/validador/rendiciones/RendicionesValidador';
// import RendicionSemanaValidador from './components/validador/rendiciones/RendicionSemana';
import VerTicketValidador from './components/validador/rendiciones/VerTicketValidador';
import FprValidador from './components/validador/fpr/FprValidador';
import NuevoFpr from './components/validador/fpr/NuevoFpr';
import FprDetalleValidador from './components/validador/fpr/FprDetalleValidador';

// GERENCIA
import GerenciaRoute from './components/router/GerenciaRoute';
import DashGerencia from './components/gerencia/DashGerencia';
import Usuarios from './components/gerencia/sistema/Usuarios';
import Clientes from './components/gerencia/sistema/Clientes';
import EstadosSolicitudes from './components/gerencia/sistema/EstadosSolicitudes';
import TiposGastos from './components/gerencia/sistema/TiposGastos';
import CentrosCostos from './components/gerencia/sistema/CentrosCostos';
import RendicionesGerencia from './components/gerencia/rendiciones/RendicionesGerencia';

// FINANZAS
import FinanzasRoute from './components/router/FinanzasRoute';
import DashFinanzas from './components/finanzas/DashFinanzas';
import RendicionesFinanzas from './components/finanzas/rendiciones/RendicionesFinanzas';
import FprPagoFinanzas from './components/finanzas/fpr/FprPagoFinanzas';
import FprFinanzas from './components/finanzas/fpr/FrpFinanzas';

// SUPERVISOR
import SupervisorRoute from './components/router/SupervisorRoute';
import DashSupervisor from './components/supervisor/DashSupervisor';
import RendicionesSupervisor from './components/supervisor/rendiciones/RendicionesSupervisor';
import RendicionSemanaSup from './components/supervisor/rendiciones/RendicionSemanaSup';

// GENERICO
import RendicionSemanaView from './components/general/rendiciones/RendicionSemanaView';
import VerTicket from './components/general/rendiciones/VerTicket';
import EditarTicket from './components/general/rendiciones/EditarTicket';
import Informes from './components/general/informes/Informes';
// Errores
import Error403 from './components/errores/Error403';

function App() {
  return (
    <UserContextProvider>
      <Router>
        <Routes>
          <Route path="/" element={<PublicRoute />}>
            <Route index element={<Login />} />
            <Route path={RECUPERAR} element={<RecuperarPassword />} />
          </Route>
          {/* Tecnico */}
          <Route path={TECNICO} element={<TecnicoRoute />}>
            <Route index element={<DashTecnico />} />
            {/* <Route path="rendiciones/nueva" element={<NuevaRendicion />} /> */}
            <Route path="rendiciones" element={<RendicionesTecnico />} />
            <Route
              path="rendiciones/semana/:id"
              element={<RendicionSemana />}
            />
            <Route
              path="rendiciones/agregarticket/:id/:desde/:hasta"
              element={<AgregaTicket />}
            />
            <Route
              path="rendiciones/editarticket/:id/:desde/:hasta"
              element={<EditarTicket />}
            />
            <Route path="rendiciones/ticket/:id" element={<VerTicketTec />} />

            <Route path="fpr/:id" element={<FprDetalleTecnico />} />
            <Route
              path="fpr/agregarticket/:id"
              element={<FprAgregarTicket />}
            />
          </Route>

          {/* Validador */}
          <Route path={VALIDADOR} element={<ValidadorRoute />}>
            <Route index element={<DashValidador />} />
            <Route
              path="rendiciones/:tipotabla"
              element={<RendicionesValidador />}
            />
            <Route path="rendicion/:id" element={<RendicionSemanaView />} />
            <Route
              path="rendicion/ticket/:id"
              element={<VerTicketValidador />}
            />
            <Route
              path="rendiciones/editarticket/:id/:desde/:hasta"
              element={<EditarTicket />}
            />
            <Route path="fpr/nuevo" element={<NuevoFpr />} />
            <Route path="fpr/:tipotabla" element={<FprValidador />} />
            <Route path="fpr/detalle/:id" element={<FprDetalleValidador />} />

            <Route path="informes" element={<Informes />} />
          </Route>
          {/* Gerencia */}
          <Route path={GERENCIA} element={<GerenciaRoute />}>
            <Route index element={<DashGerencia />} />
            <Route path="sistema/usuarios" element={<Usuarios />} />
            <Route path="sistema/clientes" element={<Clientes />} />
            <Route path="sistema/solestados" element={<EstadosSolicitudes />} />
            <Route path="sistema/tiposgastos" element={<TiposGastos />} />
            <Route path="sistema/centroscostos" element={<CentrosCostos />} />
            <Route
              path="rendiciones/:tipotabla"
              element={<RendicionesGerencia />}
            />
            <Route path="rendicion/:id" element={<RendicionSemanaView />} />
            <Route path="rendicion/ticket/:id" element={<VerTicket />} />
            <Route path="informes" element={<Informes />} />
          </Route>
          {/* Finanzas */}
          <Route path={FINANZAS} element={<FinanzasRoute />}>
            <Route index element={<DashFinanzas />} />
            <Route
              path="rendiciones/:tipotabla"
              element={<RendicionesFinanzas />}
            />
            <Route path="rendicion/:id" element={<RendicionSemanaView />} />
            <Route path="rendicion/ticket/:id" element={<VerTicket />} />
            <Route path="informes" element={<Informes />} />

            <Route path="fpr/:tipotabla" element={<FprFinanzas />} />
            <Route path="fpr/detalle/:id" element={<FprPagoFinanzas />} />
          </Route>
          {/* Supervisor */}
          <Route path={SUPERVISOR} element={<SupervisorRoute />}>
            <Route index element={<DashSupervisor />} />
            <Route
              path="rendiciones/:tipotabla"
              element={<RendicionesSupervisor />}
            />
            <Route path="rendicion/:id" element={<RendicionSemanaSup />} />
            <Route path="rendicion/ticket/:id" element={<VerTicket />} />
            <Route path="informes" element={<Informes />} />
          </Route>
          {/* 
          <Route path={SPOC} element={<Auth />} /> */}
          {/* Errores */}
          <Route path="*" element={<Error403 />} />
        </Routes>
      </Router>
    </UserContextProvider>
  );
}

export default App;
