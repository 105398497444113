// development
// export const apiUrl = (path) => `http://localhost:8001/${path}`;

// production
export const apiUrl = (path) => `https://apihyo.mapp.cl/${path}`;

// url S3 > ELIMINAR
export const urlS3 = 'https://mappstorage.s3.amazonaws.com/gepro/';

// url S3 gastos_adicionales > ELIMINAR
export const urlS3GastosAdicionales =
  'https://mappstorage.s3.amazonaws.com/gepro/gasto_adicional/';

// url S3 rendiciones HyO
export const urlS3Rendiciones =
  'https://hyostorage.s3.amazonaws.com/rendiciones/';
