import { Navigate, Outlet } from 'react-router-dom';
import {
  GERENCIA,
  FINANZAS,
  TECNICO,
  VALIDADOR,
  LOGIN,
  SUPERVISOR,
} from '../../routes/rutas';
import useUser from '../../hooks/useUser';
// Importo css de Auth
import '../../assets/css/pages/auth.css';

function PublicRoute() {
  const { isLogged, role } = useUser();

  if (isLogged) {
    switch (role) {
      case 'Tecnico':
        return <Navigate to={TECNICO} />;
      case 'Validador':
        return <Navigate to={VALIDADOR} />;
      case 'Gerencia':
        return <Navigate to={GERENCIA} />;
      case 'Finanzas':
        return <Navigate to={FINANZAS} />;
      case 'Supervisor':
        return <Navigate to={SUPERVISOR} />;
      default:
        return <Navigate to={LOGIN} />;
    }
  }

  return (
    <div id="auth">
      <div className="row h-100">
        <div className="col-lg-5 col-12">
          <div id="auth-left">
            <div className="auth-logo">
              <h1 className="text-success">HyO</h1>
            </div>
            <Outlet />
          </div>
        </div>
        {/* Logo HYO constado formulario login */}
        <div className="col-lg-7 d-none d-lg-block">
          <div id="auth-right">
            <div className="contenidoError">
              <div className="layout">
                <img src="images/logo_HyO.webp" alt="HyO Ltda." width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublicRoute;
