import React, { useEffect, useState } from 'react';
import useUser from '../../../hooks/useUser';
import {
  createUsuario,
  deleteUsuario,
  getUsuarios,
} from '../../../services/usuarios';
import {
  AlertaError,
  AlertaSuccess,
  AlertaConfirm,
} from '../../general/Alerts';
import InputMask from 'react-input-mask';
import ComunasSelect from '../../general/forms/ComunasSelect';
import RegionesSelect from '../../general/forms/RegionesSelect';

const TECNICO = 1;
const VALIDADOR = 2;
const GERENCIA = 3;
const FINANZAS = 4;
const CONTABILIDAD = 5;
const SUPERVISOR = 6;

const initialState = {
  id: '',
  rut: '',
  nombre: '',
  apellido: '',
  direccion: '',
  comuna: '',
  region: '',
  telefono: '',
  email: '',
  password: '',
  passwordConfirmacion: '',
  role: '',
  valor_kilometro: '',
};

function Usuarios() {
  const { apiToken } = useUser();

  const [usuarios, setUsuarios] = useState([]);
  const [usuario, setUsuario] = useState({ ...initialState });

  // get data
  const getData = (apiToken) => {
    getUsuarios(apiToken).then((data) => {
      setUsuarios(data.usuarios);
    });
  };

  useEffect(() => {
    getData(apiToken);
  }, [apiToken]);

  // handleChange
  const handleChange = (e) => {
    setUsuario({
      ...usuario,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === 'password') {
      if (usuario.password.length < 6 || usuario.password.length > 10) {
        e.target.classList.add('border', 'border-danger');
      } else {
        e.target.classList.remove('border', 'border-danger');
      }
    }
  };

  // handleComuna
  const seleccionComuna = (comuna) => {
    setUsuario({
      ...usuario,
      comuna: comuna.id,
    });
  };

  // handleRegion
  const seleccionRegion = (region) => {
    setUsuario({
      ...usuario,
      region: region.id,
    });
  };

  // usuario edit
  const handleEditUser = (usuario) => {
    setUsuario(usuario);
    setUsuario({
      ...usuario,
      password: '',
      passwordConfirmacion: '',
    });
  };

  // delete usuario
  const handleDeleteUser = (usuario) => {
    AlertaConfirm({
      title: '¿Eliminar usuario?',
      text: `Se eliminara ${usuario.nombre} ${usuario.apellido}`,
      callback: () => {
        deleteUsuario(usuario.id, apiToken).then((data) => {
          if (data.status === 200) {
            AlertaSuccess({ message: 'usuario eliminado correctamente' });
            getData(apiToken);
          } else {
            AlertaError({ error: data });
          }
        });
      },
    });
  };

  // handleSubmit and update usuarios if success
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      usuario.nombre === '' ||
      usuario.apellido === '' ||
      // usuario.rut === '' ||
      usuario.region === '' ||
      usuario.email === '' ||
      usuario.password === '' ||
      usuario.passwordConfirmacion === '' ||
      usuario.role === ''
    ) {
      AlertaError({ error: 'Todos los campos son obligatorios' });
      return;
    }
    if (usuario.password !== usuario.passwordConfirmacion) {
      AlertaError({ error: 'Las contraseñas no coinciden' });
      return;
    }

    AlertaConfirm({
      title: '¿Crear usuario?',
      text: `Se creara ${usuario.nombre} ${usuario.apellido}`,
      callback: () => {
        createUsuario(usuario, apiToken).then((data) => {
          if (data.status === 200) {
            AlertaSuccess({ message: 'Usuario creado correctamente' });
            getData(apiToken);
          } else {
            AlertaError({ error: data.error });
          }
        });
      },
    });
  };

  return (
    <section className="section">
      <div className="row">
        <div className="col-sm-12 col-lg-6">
          <h3>Usuarios activos</h3>
          {/* table */}
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>Email</th>
                <th>Rol</th>
                <th>Region</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              {usuarios.map((usuario) => (
                <tr key={usuario.id}>
                  <td>{usuario.nombre}</td>
                  <td>{usuario.apellido}</td>
                  <td>{usuario.email}</td>
                  <td>{usuario.role}</td>
                  <td>{usuario.region_nombre}</td>
                  <td>
                    <div className="col-12 d-flex justify-content-end">
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => handleEditUser(usuario)}
                      >
                        Editar
                      </button>
                      <button
                        className="btn btn-danger btn-sm ms-1"
                        onClick={() => handleDeleteUser(usuario)}
                      >
                        Eliminar
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="col-6">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Nuevo Usuario</h4>
            </div>
            <div className="card-body">
              {/* Formulario */}
              <form
                className="form"
                data-parsley-validate
                onSubmit={handleSubmit}
                id="form-create-user"
              >
                <div className="row">
                  {/* Nombres */}
                  <div className="col-md-4 col-12">
                    <div className="form-group mandatory">
                      <label htmlFor="input-nombre" className="form-label">
                        Nombre
                      </label>
                      <input
                        type="text"
                        id="input-nombre"
                        className="form-control"
                        placeholder="Nombre"
                        name="nombre"
                        value={usuario.nombre}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/* Apellido */}
                  <div className="col-md-4 col-12">
                    <div className="form-group mandatory">
                      <label htmlFor="input-apellido" className="form-label">
                        Apellido
                      </label>
                      <input
                        type="text"
                        id="input-apellido"
                        className="form-control"
                        placeholder="Apellido"
                        name="apellido"
                        value={usuario.apellido}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {/* Rut */}
                  <div className="col-md-4 col-12">
                    <div className="form-group mandatory">
                      <label htmlFor="input-rut" className="form-label">
                        RUT
                      </label>
                      <InputMask
                        className="form-control"
                        mask={'99999999-*'}
                        id="input-rut"
                        name="rut"
                        value={usuario.rut}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  {/* Email */}
                  <div className="col-md-4 col-12">
                    <div className="form-group mandatory">
                      <label htmlFor="input-email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        id="input-email"
                        className="form-control"
                        name="email"
                        value={usuario.email}
                        onChange={handleChange}
                        placeholder="email"
                      />
                    </div>
                  </div>
                  {/* Password */}
                  <div className="col-md-4 col-12">
                    <div className="form-group mandatory">
                      <label htmlFor="input-password" className="form-label">
                        Password
                      </label>
                      <input
                        type="text"
                        id="input-password"
                        className="form-control"
                        name="password"
                        onChange={handleChange}
                        placeholder="password"
                      />
                    </div>
                  </div>

                  {/* Password Confirmacion */}
                  <div className="col-md-4 col-12">
                    <div className="form-group mandatory">
                      <label
                        htmlFor="input-password-confirmacion"
                        className="form-label"
                      >
                        Password Confirmación
                      </label>
                      <input
                        type="text"
                        id="input-password-confirmacion"
                        className="form-control"
                        name="passwordConfirmacion"
                        onChange={handleChange}
                        placeholder="password"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  {/* Direccion */}
                  <div className="col-md-4 col-12">
                    <div className="form-group">
                      <label htmlFor="input-direccion" className="form-label">
                        Direccion
                      </label>
                      <input
                        type="text"
                        id="input-direccion"
                        className="form-control"
                        placeholder="Direccion"
                        name="direccion"
                        value={usuario.direccion || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/* Comuna */}
                  <div className="col-md-4 col-12">
                    <div className="form-group">
                      <ComunasSelect seleccion={seleccionComuna} />
                    </div>
                  </div>

                  {/* Region */}
                  <div className="col-md-4 col-12">
                    <div className="form-group">
                      <RegionesSelect seleccion={seleccionRegion} />
                    </div>
                  </div>
                </div>

                <div className="row">
                  {/* Telefono */}
                  <div className="col-md-4 col-12">
                    <div className="form-group">
                      <label htmlFor="input-telefono" className="form-label">
                        Telefono
                      </label>
                      <input
                        type="text"
                        id="input-telefono"
                        className="form-control"
                        placeholder="Telefono"
                        name="telefono"
                        value={usuario.telefono || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {/* Rol */}
                  <div className="col-md-4 col-12">
                    <div className="form-group mandatory">
                      <label htmlFor="input-role" className="form-label">
                        Rol
                      </label>
                      {/* select */}
                      <select
                        className="form-select"
                        name="role"
                        value={usuario.role}
                        onChange={handleChange}
                      >
                        <option value="">Seleccione</option>
                        <option value={GERENCIA}>Gerencia</option>
                        <option value={CONTABILIDAD}>Contabilidad</option>
                        <option value={TECNICO}>Tecnico</option>
                        <option value={VALIDADOR}>Validador</option>
                        <option value={FINANZAS}>Finanzas</option>
                        <option value={SUPERVISOR}>Supervisor</option>
                      </select>
                    </div>
                  </div>

                  {/* Valor KM */}
                  <div className="col-md-4 col-12">
                    <div className="form-group mandatory">
                      <label htmlFor="input-kilometro" className="form-label">
                        Valor Kilometro
                      </label>
                      <input
                        type="number"
                        id="input-kilometro"
                        className="form-control"
                        placeholder="0"
                        name="valor_kilometro"
                        value={usuario.valor_kilometro || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 d-flex justify-content-end">
                  <button type="submit" className="btn btn-primary me-1 mb-1">
                    Agregar
                  </button>
                  <button
                    type="reset"
                    className="btn btn-light-secondary me-1 mb-1"
                    onClick={() => setUsuario({ ...initialState })}
                  >
                    Borrar Form
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Usuarios;
