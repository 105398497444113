import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { formatDate, formatDateTime } from '../../../../hooks/useDates';
import useUser from '../../../../hooks/useUser';
import { deleteItemTicket, getTicket } from '../../../../services/rendicionesT';
import ModalAgregarItem from '../nueva/ModalAgregarItem';
import ModalVerImagen from '../nueva/ModalVerImagen';
import { AlertToast, AlertaConfirm } from '../../../general/Alerts';
import ModalEditarItem from '../../../general/rendiciones/ModalEditarItem';
import { SpinerDownload } from '../../../general/loader/SpinerLoader';

function VerTicketTec() {
  const { id } = useParams();
  const { apiToken, dataUserLogged } = useUser();
  const [ticket, setTicket] = useState([]);
  const [ticketId, setTicketId] = useState(null);
  const [imagenModal, setImagenModal] = useState(null);
  const [itemEdit, setItemEdit] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function ObtieneTicket() {
    setIsLoading(true);
    getTicket(id, apiToken).then((response) => {
      if (response.status === 200) {
        setTicket(response.ticket);
      }
      setIsLoading(false);
    });
  }

  useEffect(() => {
    ObtieneTicket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalClose = () => {
    setImagenModal(null);
  };

  // eleiminar item
  const handleDeleteItem = (id, tipo_gasto) => {
    AlertaConfirm({
      title: 'Eliminar Item',
      text: `¿Estas seguro de eliminar el item ${tipo_gasto}?`,
      callback: () => {
        setIsLoading(true);
        deleteItemTicket(id, apiToken).then((response) => {
          if (response.status === 200) {
            AlertToast({
              icon: 'success',
              title: 'Gasto Eliminado',
              timer: 3000,
            });
            ObtieneTicket();
          }
        });
      },
    });
  };

  return (
    <section className="row">
      {isLoading && <SpinerDownload />}
      <div className="col-12">
        <div className="row">
          <div className="card">
            <div className="card-header">
              <h4>
                Ticket {ticket.nomenclatura}
                {ticket.ticket}
              </h4>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" className="col-2">
                        Fecha Atencion
                      </th>
                      <th scope="col" className="col-2">
                        Hora Inicio
                      </th>
                      <th scope="col" className="col-2">
                        Hora Termino
                      </th>
                      <th scope="col" className="col-2">
                        Cliente{' '}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">{formatDate(ticket.fecha_atencion)}</th>
                      <td>{ticket.hora_inicio}</td>
                      <td>{ticket.hora_termino}</td>
                      <td>{ticket.cliente}</td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <th scope="row" className="col-1">
                                Desde
                              </th>
                              <td className="col-sm-8 col-md-3">
                                {ticket.direccion_desde}
                              </td>
                              <td>{ticket.comuna_desde}</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">Hasta</th>
                              <td>{ticket.direccion_hasta}</td>
                              <td>{ticket.comuna_hasta}</td>
                            </tr>
                            <tr>
                              <th scope="row">Comentario Solicitud:</th>
                              <td colSpan="4">
                                {ticket.observacion || 'Sin Comentario'}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="col-2">
                        Radio urbano:{' '}
                        <strong>
                          {ticket.radio_urbano === '1' ? 'Si' : 'No'}
                        </strong>
                      </th>
                      <td colSpan={2}>
                        IBM GePro:{' '}
                        {ticket.correlativo_gepro !== null &&
                          ticket.correlativo_gepro}
                      </td>
                      <td>Creado: {formatDateTime(ticket.created_at)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer">
              {Number(ticket.estado) === 1 || Number(ticket.estado) === 6 ? (
                <button
                  className="btn btn-success btn-block"
                  data-bs-toggle="modal"
                  data-bs-target="#modalComprobanteItem"
                  onClick={() => {
                    setTicketId(ticket.id);
                    setItemEdit(null);
                  }}
                >
                  <i className="bi bi-receipt"></i> Agregar Item Gasto
                </button>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="card">
            <div className="card-header">
              <h4>Items</h4>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Concepto</th>
                      <th>Kms</th>
                      <th>Monto</th>
                      <th>Comentarios / Observaciones</th>
                      <th>Accion</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ticket.items_gastos && ticket.items_gastos.length > 0 ? (
                      ticket.items_gastos.map((item) => (
                        <tr
                          key={item.id}
                          className={
                            Number(item.corregir) === 1 ? 'table-danger' : ''
                          }
                        >
                          <td>{item.tipo_gasto}</td>
                          <td>{item.kilometros}</td>
                          <td>{item.monto}</td>
                          <td className="col-8">
                            {item.comentario_gasto}
                            {item.comentario_validador && (
                              <>
                                <br />
                                <small>
                                  <strong className="text-danger">
                                    Validador:
                                  </strong>{' '}
                                  {item.comentario_validador}
                                </small>
                              </>
                            )}
                          </td>
                          <td className="col-1">
                            <div className="d-flex justify-content-end">
                              {item.comprobante ? (
                                <button
                                  className="btn btn-sm btn-info"
                                  data-bs-toggle="modal"
                                  data-bs-target="#modalVerComprobante"
                                  onClick={() =>
                                    setImagenModal(item.comprobante)
                                  }
                                >
                                  <i className="bi bi-eye"></i>
                                </button>
                              ) : (
                                'S/I'
                              )}
                              {Number(ticket.estado) === 1 ||
                              Number(ticket.estado) === 6 ? (
                                <>
                                  <button
                                    className="btn btn-sm btn-warning ms-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#modalEditarItem"
                                    onClick={() =>
                                      setItemEdit({
                                        id: item.id,
                                        tipo_id: item.tipo_id,
                                        monto: item.monto,
                                        kilometros: item.kilometros,
                                        comentario_gasto: item.comentario_gasto,
                                        comprobante: item.comprobante,
                                        tecnicoId: dataUserLogged.id,
                                        ticket_id: item.ticket_id,
                                      })
                                    }
                                  >
                                    <i className="bi bi-pencil"></i>
                                  </button>
                                  <button
                                    className="btn btn-sm btn-danger ms-2"
                                    onClick={() =>
                                      handleDeleteItem(item.id, item.tipo_gasto)
                                    }
                                  >
                                    <i className="bi bi-trash"></i>
                                  </button>
                                </>
                              ) : null}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4">Sin Items</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalAgregarItem
        ticketId={ticketId}
        valorKm={dataUserLogged.valor_kilometro}
        tecnicoId={dataUserLogged.id}
        reloadItems={ObtieneTicket}
      />
      <ModalVerImagen
        imagen={imagenModal}
        handleModalClose={handleModalClose}
      />
      <ModalEditarItem
        itemEdit={itemEdit}
        valorKm={dataUserLogged.valor_kilometro}
        reloadItems={ObtieneTicket}
      />
    </section>
  );
}

export default VerTicketTec;
