import React, { useEffect, useState } from 'react';
import { getComunas } from '../../../services/dataSelects';
import useUser from '../../../hooks/useUser';

function ComunasSelect({ seleccion, selDefault }) {
  const { apiToken } = useUser();
  const [comunas, setComunas] = useState([]);
  const [comuna, setComuna] = useState();

  useEffect(() => {
    getComunas(apiToken).then((data) => setComunas(data));
  }, [apiToken]);

  // setea el valor por defecto del select la comuna pasada
  useEffect(() => {
    setComuna(selDefault);
  }, [selDefault]);

  function handleChange(id) {
    const comunaSeleccionada = comunas.find((comuna) => comuna.id === id);
    seleccion(comunaSeleccionada);
    setComuna(id);
  }

  return (
    <div className="form-group mandatory">
      <label htmlFor="select-comuna" className="form-label">
        Comuna
      </label>
      <select
        value={comuna}
        defaultValue={'DEFAULT'}
        className="form-select"
        id="select-comuna"
        name="comuna"
        onChange={(e) => {
          handleChange(e.target.value);
        }}
      >
        <option value={'DEFAULT'} disabled>
          Seleccionar comuna
        </option>
        {comunas.map((comuna, i) => (
          <option key={i} value={comuna.id}>
            {comuna.comuna}
          </option>
        ))}
      </select>
    </div>
  );
}

export default ComunasSelect;
