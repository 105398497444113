import React, { useEffect } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import Footer from './Footer';
import useUser from '../../hooks/useUser';

function BreadCumbs() {
  //Obtiene las secciones de la url para crear los breadcumbs
  const laUrl = useLocation().pathname.split('/');
  laUrl.shift();
  return laUrl;
}

function Titulo() {
  const { dataUserLogged } = useUser();
  //Obtiene las secciones de la url para crear los breadcumbs
  const laUrl = useLocation().pathname.split('/');
  laUrl.shift();
  return laUrl[1] || 'Dashboard - ' + dataUserLogged.role;
}

function Main({ titulo, descripcion }) {
  const navigate = useNavigate(); // hook de navegacion
  const { isLogged } = useUser(); // hook de usuario logueado
  // valida session
  useEffect(() => {
    if (!isLogged) {
      navigate('/');
    }
  }, [isLogged, navigate]);

  return (
    <div id="main" className="px-2 px-sm-4 pt-0 pt-xl-2">
      <header className="mb-3">
        <Link className="burger-btn d-block d-xl-none">
          <i className="bi bi-justify fs-3"></i>
        </Link>
      </header>
      <div className="page-heading">
        {/* Titulo y breadcumbs */}
        <div className="page-title">
          <div className="row">
            <div className="col-12 col-md-6 order-md-1 order-last">
              <h3 className="text-capitalize">{Titulo()}</h3>
            </div>
            <div className="col-12 col-md-6 order-md-2 order-first d-none d-lg-block">
              <nav
                aria-label="breadcrumb"
                className="breadcrumb-header float-start float-lg-end"
              >
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  {BreadCumbs().map((seccion, i) => {
                    return (
                      <li
                        className="breadcrumb-item active text-capitalize"
                        aria-current="page"
                        key={i}
                      >
                        {seccion}
                      </li>
                    );
                  })}
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        {/* Section con el contenido principal */}
        <Outlet />
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Main;
