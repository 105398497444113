import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import useUser from '../../hooks/useUser';

function MenuTecnico() {
  const location = useLocation();
  const { pathname } = location;
  const path = pathname.split('/');

  const { logout } = useUser(); // hook para hacer logout

  return (
    <div className="sidebar-menu">
      <ul className="menu">
        <li className="sidebar-title">Menú</li>
        <li
          className={'sidebar-item ' + (path[2] === undefined ? 'active' : '')}
        >
          <Link to="/tecnico" className="sidebar-link">
            <i className="bi bi-grid-fill"></i>
            <span>Dashboard</span>
          </Link>
        </li>
        {/* Rendiciones */}
        <li
          className={
            'sidebar-item has-sub ' +
            (path[2] === 'rendiciones' || path[2] === 'rendiciones'
              ? 'active'
              : '')
          }
        >
          <Link to="#" className="sidebar-link">
            <i className="bi bi-person-video2"></i>
            <span>Rendiciones</span>
          </Link>
          <ul className="submenu">
            <li className="submenu-item">
              <Link to="/tecnico/rendiciones">Ver Todas</Link>
            </li>
            <li className="submenu-item d-none">
              <Link to="/tecnico/rendiciones/nueva">Crear Nueva</Link>
            </li>
          </ul>
        </li>

        {/* Perfil */}
        <li
          className={'sidebar-item ' + (path[2] === 'perfil' ? 'active' : '')}
        >
          <Link to="/usuario/perfil" className="sidebar-link">
            <i className="bi bi-person"></i>
            <span>Perfil</span>
          </Link>
        </li>

        {/* Instructivo */}
        <li
          className={'sidebar-item ' + (path[2] === 'perfil' ? 'active' : '')}
        >
          {/* Link to download */}

          <a
            href="https://hyostorage.s3.amazonaws.com/documentos/instructivo.pdf"
            className="sidebar-link"
            target="_blank"
            download
          >
            <i className="bi bi-file-text"></i>
            <span>Instructivo</span>
          </a>
        </li>
        {/* Cerrar Sesion */}
        <li className="sidebar-item">
          <Link to="/" className="sidebar-link" onClick={logout}>
            <i className="bi bi-door-open-fill"></i>
            <span>Cerrar Sesión</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default MenuTecnico;
