import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getComunasRegion } from '../../../services/dataSelects';
import { editTicket, getTicket } from '../../../services/rendicionesT';
import ProyectosSelect from '../forms/ProyectosSelect';
import useUser from '../../../hooks/useUser';
import { SpinerDownload } from '../loader/SpinerLoader';
import { AlertaConfirm, AlertaError, AlertaSuccess } from '../Alerts';

function EditarTicket() {
  const navigate = useNavigate();
  const [nomenclatura, setNomenclatura] = useState(null);
  const [comunas, setComunas] = useState([]);
  const { apiToken } = useUser();
  const { id, desde, hasta } = useParams();
  const [ticket, setTicket] = useState({});
  const [ticketInput, setTicketInput] = useState({
    type: 'number',
    placeholder: 'Numero de ticket',
    disabled: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [editedTicket, setEditedTicket] = useState({
    id: id,
  });

  // Recuperar las comunas de la región seleccionada
  useEffect(() => {
    if (ticket.region_id) {
      getComunasRegion(ticket.region_id, apiToken)
        .then((res) => {
          setComunas(res.comunas);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [ticket.region_id, apiToken]);

  // Recuperar los datos del ticket al cargar el componente
  useEffect(() => {
    setIsLoading(true);
    getTicket(id, apiToken)
      .then((res) => {
        setTicket(res.ticket);
        setNomenclatura(res.ticket.nomenclatura);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, [id, apiToken]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const callback = async () => {
      try {
        const res = await editTicket(id, editedTicket, apiToken);
        if (res.status === 200) {
          AlertaSuccess({
            title: `Ticket editado`,
            success: `Se han guardado los cambios al ticket ${nomenclatura}${ticket.ticket}`,
            callback: () => {
              navigate(-1);
            },
          });
        } else {
          AlertaError({
            title: `Error al editar el ticket ${nomenclatura}${ticket.ticket}`,
            text: `No se han guardado los cambios`,
          });
        }
      } catch (err) {
        AlertaError({
          title: `Error al editar el ticket ${nomenclatura}${ticket.ticket}`,
          text: `No se han guardado los cambios`,
        });
        console.error(err);
      }
    };

    AlertaConfirm({
      title: `¿Guardar Cambios?`,
      text: `Se guardarán los cambios al ticket ${nomenclatura}${ticket.ticket}`,
      callback,
    });
  };

  const handleChange = (e) => {
    setEditedTicket({
      ...editedTicket,
      // si es checkbox, cambiar el valor a 1 o 0
      [e.target.name]:
        e.target.type === 'checkbox' ? +e.target.checked : e.target.value,
    });
    setTicket({
      ...ticket,
      [e.target.name]:
        e.target.type === 'checkbox' ? +e.target.checked : e.target.value,
    });
  };

  const seleccionProyecto = (proyecto) => {
    setNomenclatura(proyecto.nomenclatura);

    setTicketInput({
      type: proyecto.nomenclatura === null ? 'text' : 'number',
      placeholder:
        proyecto.nomenclatura === null
          ? 'Especificar en observación'
          : 'Numero de ticket',
      disabled: proyecto.nomenclatura === null,
    });
    setEditedTicket({
      ...editedTicket,
      proyecto_id: proyecto.id,
      ticket: proyecto.nomenclatura === null ? proyecto.cliente : '',
    });
    setTicket({
      ...ticket,
      proyecto_id: proyecto.id,
      ticket: proyecto.nomenclatura === null ? proyecto.cliente : '',
    });
  };

  return (
    <section className="row">
      {isLoading ? <SpinerDownload /> : null}
      <div className="col-md-4 col-12 mb-5">
        <h4 className="text-warning">
          <i className="bi bi-pencil"></i> Editar Ticket
        </h4>
        <form onSubmit={handleSubmit}>
          <div className="form-group mandatory">
            <label htmlFor="fecha_atencion" className="form-label">
              Fecha de Atención
            </label>
            <input
              type="date"
              className="form-control"
              id="fecha_atencion"
              name="fecha_atencion"
              min={desde}
              max={hasta}
              onChange={handleChange}
              value={ticket.fecha_atencion || ''}
            />
          </div>
          <div className="row">
            <div className="form-group col-6 mandatory">
              <label htmlFor="hora_inicio" className="form-label">
                Hora de Inicio
              </label>
              <input
                type="time"
                className="form-control"
                id="hora_inicio"
                name="hora_inicio"
                onChange={handleChange}
                value={ticket.hora_inicio || ''}
              />
            </div>
            <div className="form-group col-6 mandatory">
              <label htmlFor="hora_termino" className="form-label">
                Hora de Término
              </label>
              <input
                type="time"
                className="form-control"
                id="hora_termino"
                name="hora_termino"
                onChange={handleChange}
                value={ticket.hora_termino || ''}
              />
            </div>
          </div>

          {/* Proyectos */}
          {ticket && ticket.proyecto_id ? (
            <ProyectosSelect
              opcionDefault={ticket.proyecto_id}
              onSelect={seleccionProyecto}
            />
          ) : null}

          {/* Ticket */}
          <div className="input-group">
            <span className="input-group-text" id="nomenclatura">
              {nomenclatura}
            </span>
            <input
              type={ticketInput.type}
              className="form-control"
              placeholder={ticketInput.placeholder}
              name="ticket"
              value={ticket.ticket || ''}
              onChange={handleChange}
              disabled={ticketInput.disabled}
            />
          </div>

          <div className="row">
            {/* Correlativo Gepro */}
            <div className="col-6 mt-2 mb-3">
              <span>IBM Gepro</span>
              <div className="input-group">
                <span className="input-group-text" id="gepro-g">
                  G-
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="000123"
                  maxLength="6"
                  name="correlativo_gepro"
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* Radio Urbano */}
            <div className="col-6">
              <div className="form-group form-check mt-4">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="radio_urbano"
                  id="radio_urbano"
                  onChange={handleChange}
                  checked={ticket.radio_urbano}
                />
                <label className="form-check-label" htmlFor="radio_urbano">
                  Radio Urbano
                </label>
              </div>
            </div>
          </div>

          {/* Direccion Desde */}
          <div className="form-group mandatory">
            <label htmlFor="direccion_desde" className="form-label">
              Dirección Desde
            </label>
            <input
              type="text"
              className="form-control"
              id="direccion_desde"
              name="direccion_desde"
              onChange={handleChange}
              value={ticket.direccion_desde || ''}
            />
          </div>

          {/* Comuna Desde */}
          <div className="form-group mandatory">
            <label htmlFor="comuna_desde_id" className="form-label">
              Comuna Desde
            </label>
            <select
              className="form-control"
              id="comuna_desde_id"
              name="comuna_desde_id"
              onChange={(e) => {
                handleChange(e);
              }}
              value={ticket.comuna_desde_id || 0}
            >
              <option value="0" disabled>
                Selecciona una opción
              </option>
              {comunas.map((comuna) => (
                <option key={comuna.id} value={comuna.id}>
                  {comuna.comuna}
                </option>
              ))}
            </select>
          </div>

          {/* Direccion Hasta */}
          <div className="form-group mandatory">
            <label htmlFor="direccion_hasta" className="form-label">
              Dirección Hasta
            </label>
            <input
              type="text"
              className="form-control"
              id="direccion_hasta"
              name="direccion_hasta"
              onChange={handleChange}
              value={ticket.direccion_hasta || ''}
            />
          </div>

          {/* Comuna Hasta */}
          <div className="form-group mandatory">
            <label htmlFor="comuna_hasta_id" className="form-label">
              Comuna Hasta
            </label>
            <select
              className="form-control"
              id="comuna_hasta_id"
              name="comuna_hasta_id"
              onChange={(e) => {
                handleChange(e);
              }}
              value={ticket.comuna_hasta_id || 0}
            >
              <option value="0" disabled>
                Selecciona una opción
              </option>
              {comunas.map((comuna) => (
                <option key={comuna.id} value={comuna.id}>
                  {comuna.comuna}
                </option>
              ))}
            </select>
          </div>

          {/* Observacion */}
          <div className="form-group">
            <label htmlFor="observacion">Observación</label>
            <textarea
              className="form-control"
              id="observacion"
              name="observacion"
              rows="3"
              onChange={handleChange}
              value={ticket.observacion || ''}
            ></textarea>
          </div>

          {/* Botones */}
          <button type="submit" className="btn btn-primary btn-block">
            <i className="bi bi-cloud-plus"></i> Guardar Cambios
          </button>
        </form>
      </div>
    </section>
  );
}

export default EditarTicket;
