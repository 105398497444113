import { Link, useLocation } from 'react-router-dom';
import useUser from '../../hooks/useUser';

function MenuFinanzas() {
  const location = useLocation();
  const { pathname } = location;
  const path = pathname.split('/');

  const { logout } = useUser(); // hook para hacer logout

  return (
    <div className="sidebar-menu">
      <ul className="menu">
        <li className="sidebar-title">Menú</li>
        <li
          className={'sidebar-item ' + (path[2] === undefined ? 'active' : '')}
        >
          <Link to="/finanzas" className="sidebar-link">
            <i className="bi bi-grid-fill"></i>
            <span>Dashboard</span>
          </Link>
        </li>

        {/* Rendiciones */}
        <li
          className={
            'sidebar-item has-sub ' +
            (path[2] === 'rendiciones' || path[2] === 'rendicion'
              ? 'active'
              : '')
          }
        >
          <Link to="#" className="sidebar-link">
            <i className="bi bi-person-video2"></i>
            <span>Rendiciones</span>
          </Link>
          <ul className="submenu">
            <li className="submenu-item">
              <Link to="/finanzas/rendiciones/finanzas">Por Pagar</Link>
            </li>
            <li className="submenu-item">
              <Link to="/finanzas/rendiciones/validacion">En Validación</Link>
            </li>
            <li className="submenu-item">
              <Link to="/finanzas/rendiciones/gerencia">En Gerencia</Link>
            </li>
            <li className="submenu-item">
              <Link to="/finanzas/rendiciones/consolidadas">Consolidadas</Link>
            </li>
          </ul>
        </li>

        {/* Fondos por rendir */}
        <li
          className={
            'sidebar-item has-sub ' +
            (path[2] === 'fpr' || path[2] === 'fpr' ? 'active' : '')
          }
        >
          <Link to="#" className="sidebar-link">
            <i className="bi bi-cash"></i>
            <span>Fondos por Rendir</span>
          </Link>
          <ul className="submenu">
            <li className="submenu-item">
              <Link to="/finanzas/fpr/depositos">Pendientes de Deposito</Link>
            </li>
            <li className="submenu-item">
              <Link to="/validador/rendiciones/todas">Ver Todos</Link>
            </li>
          </ul>
        </li>

        {/* Documentos */}
        <li
          className={'sidebar-item ' + (path[2] === 'informes' ? 'active' : '')}
        >
          <Link to="/finanzas/informes" className="sidebar-link">
            <i className="bi bi-archive-fill"></i>
            <span>Informes</span>
          </Link>
        </li>

        <li className="sidebar-item">
          {/* call to logout */}
          <Link to="/" className="sidebar-link" onClick={logout}>
            <i className="bi bi-door-open-fill"></i>
            <span>Cerrar Sesión</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default MenuFinanzas;
