import { Link } from 'react-router-dom';
import { formatDateTime } from '../../../hooks/useDates';

function CardUltimasApVal({ data, perfil }) {
  return (
    <div className="card shadow-sm">
      <div className="card-header">
        <h4 className="text-secondary">Últimas Validadas</h4>
      </div>
      <div className="card-content pb-4">
        {data && data.length > 0 ? (
          <ul className="list-group list-group-flush shadow-sm">
            {data.map((rendicion, i) => (
              <Link
                to={`/${perfil}/rendicion/${rendicion.id}`}
                key={i}
                className="list-group-item list-group-item-action"
              >
                <div className="name">
                  <h6 className="mb-1">{`${rendicion.nombre} ${rendicion.apellido}`}</h6>
                  <div className="d-flex align-items-center justify-content-between mb-1 mt-1">
                    <h6 className="text-info mb-0">
                      Semana {rendicion.semana}
                    </h6>
                    <small className="text-info">
                      {`${rendicion.validador_nombre} ${rendicion.validador_apellido}`}
                    </small>
                  </div>
                  <small className="text-secondary mb-0">
                    {formatDateTime(rendicion.fecha_valida)}
                  </small>
                </div>
              </Link>
            ))}
          </ul>
        ) : (
          <div className="recent-message d-flex px-4 py-3">
            <h6>No hay nuevas aprobadas</h6>
          </div>
        )}
        {'/' + perfil ? (
          <div className="px-4">
            <Link
              to="rendiciones/aprobadas"
              className="btn btn-block btn-outline-primary font-bold mt-3"
            >
              <i className="bi bi-eye-fill"></i> Ver Todas
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default CardUltimasApVal;
