import { useEffect, useState } from 'react';
import { getKilometrosRegion } from '../../../services/estadisticas';

function KilometrosRegiones() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchKilometrosRegion = async () => {
      const result = await getKilometrosRegion();
      const sortedData = result.estadisticas_kilometros.sort(
        (a, b) =>
          parseInt(b.total_kilometros, 10) - parseInt(a.total_kilometros, 10)
      );
      setData(sortedData);
    };
    fetchKilometrosRegion();
  }, []);

  const listItemStyle = {
    padding: '0.25rem 1.25rem',
  };

  return (
    <div className="card">
      <div className="card-header">
        <h6>KILOMETROS POR REGIÓN</h6>
      </div>
      <div className="card-body">
        <ul className="list-group list-group-flush">
          {data.map((item) => (
            <li
              key={item.region}
              className="list-group-item d-flex justify-content-between align-items-center"
              style={listItemStyle}
            >
              {item.region}
              <span className="badge bg-light text-primary border border-dark  rounded-pill">
                {item.total_kilometros}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default KilometrosRegiones;
