import { apiUrl } from '../config';

/*****************************
 * TECNICO
 *****************************/

/**** RENDICIONES *****/

// Obtiene estadisticas del tecnico
export const getEstadisticasTecnico = async (id, apiToken) => {
  try {
    const response = await fetch(
      `${apiUrl('tecnico/rendiciones/estadisticas')}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }
    );
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Obtiene rendiciones del tecnico
export const getRendiciones = async (id, apiToken) => {
  try {
    const response = await fetch(`${apiUrl('tecnico/rendiciones')}/${id}`, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Obtiene rendiciones pendientes del tecnico
export const getRendicionesPendientes = async (id, apiToken) => {
  try {
    const response = await fetch(
      `${apiUrl('tecnico/rendiciones/pendientes')}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }
    );
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Obtiene una rendicion del tecnico
export const getRendicion = async (id, apiToken) => {
  try {
    const response = await fetch(`${apiUrl('tecnico/rendiciones/ver')}/${id}`, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Envia un comentario a la rendicion por su id
export const addComentarioRendicion = async (dataComentario, apiToken) => {
  const formdata = new FormData();
  formdata.append('id', dataComentario.id);
  formdata.append(
    'observaciones_tecnico',
    dataComentario.observaciones_tecnico
  );

  try {
    const response = await fetch(
      `${apiUrl('tecnico/rendiciones/addcomentario')}`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
        body: formdata,
      }
    );
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Envia una rendicion por su id
export const enviarRendicion = async (dataRendicion, apiToken) => {
  const formdata = new FormData();
  formdata.append('id', dataRendicion.id);
  formdata.append('kilometros', dataRendicion.kilometros);
  formdata.append('total_kilometros', dataRendicion.total_kilometros);
  formdata.append('total_gastos', dataRendicion.total_gastos);
  formdata.append('total_pago', dataRendicion.total_pago);
  formdata.append('estado', dataRendicion.estado);

  try {
    const response = await fetch(`${apiUrl('tecnico/rendiciones/enviar')}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
      body: formdata,
    });
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};

/**** TICKETS *****/

// Agrega un ticket a la rendicion
export const addTicket = async (ticket, apiToken) => {
  // console.log(ticket);
  try {
    const response = await fetch(`${apiUrl('tecnico/rendiciones/addticket')}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiToken}`,
      },
      body: JSON.stringify(ticket),
    });
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Editar un ticket
export const editTicket = async (id, ticket, apiToken) => {
  try {
    const response = await fetch(
      `${apiUrl('misc/rendiciones/ticket/editar')}/${id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiToken}`,
        },
        body: JSON.stringify(ticket),
      }
    );
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Obtiene un ticket de un arendicion por id
export const getTicket = async (id, apiToken) => {
  try {
    const response = await fetch(
      `${apiUrl('tecnico/rendiciones/ticket')}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }
    );
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Elimina un ticket de una rendicion por id
export const deleteTicket = async (id, apiToken) => {
  try {
    const response = await fetch(
      `${apiUrl('tecnico/rendiciones/ticket/eliminar')}/${id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }
    );
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};

/**** ITEMS *****/

// Agrega un item a un ticket
export const addItemTicket = async (item, apiToken) => {
  const formdata = new FormData();
  formdata.append('ticket_id', item.ticket_id);
  formdata.append('tipo_id', item.tipo_id);
  formdata.append('kilometros', item.kilometros);
  formdata.append('monto', item.monto);
  formdata.append('numero_boleta', item.numero_boleta);
  formdata.append('comprobante', item.comprobante);
  formdata.append('comentario_gasto', item.comentario_gasto);
  formdata.append('tecnicoId', item.tecnicoId);
  try {
    const response = await fetch(`${apiUrl('tecnico/rendiciones/additem')}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
      body: formdata,
    });
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Actualiza un item de un ticket
export const editItemTicket = async (item, apiToken) => {
  const formdata = new FormData();

  for (let key in item) {
    if (item[key] !== null) {
      formdata.append(key, item[key]);
    }
  }

  // Añade otros campos que necesites, como 'corregir', si no están en item
  formdata.append('corregir', 0);

  console.log(formdata);

  try {
    const response = await fetch(
      `${apiUrl('misc/rendiciones/itemgasto/editar')}`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
        body: formdata,
      }
    );
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

// Elimina un item de un ticket
export const deleteItemTicket = async (id, apiToken) => {
  try {
    const response = await fetch(
      `${apiUrl('tecnico/rendiciones/itemgasto/eliminar')}/${id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }
    );
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};

/*****************************
 * VALIDADOR
 *****************************/

/**** RENDICIONES *****/

// Obtiene las ultimas tres rendiciones enviadas
export const getUltimasRendicionesCreadas = async (apiToken) => {
  try {
    const response = await fetch(`${apiUrl('validador/rendiciones/ultimas')}`, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Obtiene las ultimas tres rendiciones aprovadas
export const getUltimasRendApValidador = async (apiToken) => {
  try {
    const response = await fetch(
      `${apiUrl('validador/rendiciones/aprobadas')}`,
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }
    );
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Obtiene todas las rendiciones
// export const getRendicionesTodas = async (tipo, apiToken) => {
//   try {
//     const response = await fetch(`${apiUrl('validador/rendiciones')}/${tipo}`, {
//       headers: {
//         Authorization: `Bearer ${apiToken}`,
//       },
//     });
//     const data = await response.json();
//     data.status = response.status;
//     return data;
//   } catch (error) {
//     console.log(error);
//   }
// };

// Obtiene todas las rendiciones
export const getRendicionesTodas = async (
  tipo,
  apiToken,
  rechazadas = null
) => {
  try {
    let url = `${apiUrl('validador/rendiciones')}/${tipo}`;

    if (rechazadas !== null) {
      url += `/${rechazadas}`;
    }

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Rechaza un item de gasto
export const rechazarItemGasto = async (item, comentario, apiToken) => {
  const actualizar = {
    comentario_validador: comentario,
    corregir: true,
  };

  try {
    const response = await fetch(
      `${apiUrl('validador/rendiciones/itemgasto/rechazar')}/${item}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
        body: JSON.stringify(actualizar),
      }
    );
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Editar un item de gasto por el validador
export const editarItemGasto = async (id, item, apiToken) => {
  try {
    const response = await fetch(
      `${apiUrl('validador/rendiciones/itemgasto/editar')}/${id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
        body: JSON.stringify(item),
      }
    );
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log('error', error);
  }
};

/** GENERALES */

// Obtiene estadisticas de las rendiciones para dashboard
export const getEstadisticasRendiciones = async (apiToken) => {
  try {
    const response = await fetch(`${apiUrl('misc/rendiciones/estadisticas')}`, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log('error', error);
  }
};

// Actualiza el estado de una rendicion
export const actualizarEstadoRendicion = async (id, dataSend, apiToken) => {
  // console.log(dataSend);
  // pone un delay de 5 segundos
  // setTimeout(() => {
  //   console.log('actualizando estado');
  // }, 5000);
  try {
    const response = await fetch(
      `${apiUrl('misc/rendiciones/actualizarestado')}/${id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
        body: JSON.stringify(dataSend),
      }
    );
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log('error', error);
  }
};

// Actualizar estado de varias rendicones
export const actualizarEstadoRendiciones = async (dataSend, apiToken) => {
  // console.log(dataSend);
  try {
    const response = await fetch(
      `${apiUrl('misc/rendiciones/actualizarestados')}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
        body: JSON.stringify(dataSend),
      }
    );
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log('error', error);
  }
};
