import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';

const VisorRutas = ({ desde, hasta }) => {
  const [distancia, setDistancia] = useState(0);
  const [duracion, setDuracion] = useState(0);

  const apiIsLoaded = (map, maps) => {
    const geocoder = new maps.Geocoder();
    const directionsService = new maps.DirectionsService();
    const directionsRenderer = new maps.DirectionsRenderer();
    directionsRenderer.setMap(map);

    geocoder.geocode({ address: desde }, (results, status) => {
      if (status === 'OK') {
        const origin = results[0].geometry.location;
        geocoder.geocode({ address: hasta }, (results, status) => {
          if (status === 'OK') {
            const destination = results[0].geometry.location;

            directionsService.route(
              {
                origin: origin,
                destination: destination,
                travelMode: maps.TravelMode.DRIVING,
              },
              (result, status) => {
                if (status === maps.DirectionsStatus.OK) {
                  directionsRenderer.setDirections(result);
                  const distanciaText = result.routes[0].legs[0].distance.text;
                  const duracionText = result.routes[0].legs[0].duration.text;

                  setDistancia(distanciaText);
                  setDuracion(duracionText);
                } else {
                  console.error(`Error al obtener la dirección: ${status}`);
                }
              }
            );
          } else {
            console.error(`Error al obtener la dirección: ${status}`);
          }
        });
      } else {
        console.error(`Error al obtener la dirección: ${status}`);
      }
    });
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4>
          <i className="bi bi-geo-alt text-info"></i> Visor de Ruta
        </h4>
      </div>
      <div className="card-content p-1">
        <div style={{ height: '400px', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyAEsHxKaNzX-KLTfXMHUnhk1-f_1TvIAXc',
            }}
            defaultCenter={{ lat: -33.447487, lng: -70.673676 }}
            defaultZoom={10}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
          />
        </div>
      </div>
      <div className="card-footer">
        <p className="text-info mb-0">Distancia: {distancia}</p>
        <p className="text-info">Tiempo estimado de viaje: {duracion}</p>
      </div>
    </div>
  );
};

export default VisorRutas;
