import { apiUrl } from '../config';

//** ADMINISTRACION */

// Crea un fondo por rendir
export const createFpr = async (fondo, apiToken) => {
  //   console.log(fondo);
  const response = await fetch(`${apiUrl('validador/fpr')}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`,
    },
    body: JSON.stringify(fondo),
  });
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtiene los fondos por rendir pendientes de envio estado 2
export const getFprs = async (estado, apiToken) => {
  const response = await fetch(`${apiUrl('validador/fpr/allfpr/')}${estado}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`,
    },
  });
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtiene un fondo por rendir por su id
export const getFprById = async (id, apiToken) => {
  const response = await fetch(
    `${apiUrl('validador/fpr/')}${id}
    `,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiToken}`,
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Actualiza un fondo por rendir
export const updateFpr = async (id, fpr, apiToken) => {
  const response = await fetch(`${apiUrl('misc/fpr/editar/')}${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`,
    },
    body: JSON.stringify(fpr),
  });
  const data = await response.json();
  data.status = response.status;
  return data;
};

//** TECNNCIO **/

// Obtiene fondos por rendir pendientes de justificacion estado 2
export const getFprsTecnico = async (idTecnico, estado, apiToken) => {
  const response = await fetch(
    `${apiUrl('tecnico/fpr/pendientes/')}${idTecnico}/${estado}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiToken}`,
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};
