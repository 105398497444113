function ModalComentarioRend({ setRecargarData, onChange }) {
  return (
    <div
      className="modal fade modal-lg"
      id="modalComentarioRend"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Agregar Comentario</h5>
          </div>
          <div className="modal-body">
            <form id="formAgregarComentario">
              {/* Comentario */}
              <div className="form-group mb-4 col-12">
                <label htmlFor="comentarioJustificacion" className="form-label">
                  Comentario
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="observaciones"
                  name="observaciones"
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
            <button
              type="submit"
              form="formAgregarItem"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={(e) => {
                e.preventDefault();
                setRecargarData(true);
              }}
            >
              Agregar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalComentarioRend;
