import { apiUrl } from '../config';

// login a user
export const loginService = async (username, password) => {
  const response = await fetch(apiUrl('auth/login'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify({
      email: username,
      password,
    }),
  });

  const data = await response.json();
  data.status = response.status;
  return data;
};

// logout a user
export const logoutService = async () => {
  sessionStorage.clear();
};

// get user data from session storage
export const getSessionUser = () => {
  const user = sessionStorage.getItem('user');
  if (user) {
    return JSON.parse(user);
  }
};
