import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import ModalVerComprobante from '../../general/rendiciones/ModalVerComprobante';
import { formatDate, formatDateTime } from '../../../hooks/useDates';
import useUser from '../../../hooks/useUser';
import {
  editarItemGasto,
  getTicket,
  rechazarItemGasto,
} from '../../../services/rendicionesT';
import {
  AlertToast,
  AlertaConfirmComentario,
  AlertaEditaItem,
} from '../../general/Alerts';
import VisorRutas from '../../general/rendiciones/VisorRutas';
import VisorComprobante from '../../general/rendiciones/VisorComprobante';
import { SpinerDownload } from '../../general/loader/SpinerLoader';

function VerTicketValidador() {
  const { id } = useParams();
  const { apiToken } = useUser();
  const [ticket, setTicket] = useState({});
  const [itemModal, setItemModal] = useState(null);
  const [mostrarVisorRutas, setMostrarVisorRutas] = useState(false);
  const [mostrarVisorComprobante, setMostrarVisorComprobante] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  function ObtieneTicket() {
    getTicket(id, apiToken).then((response) => {
      if (response.status === 200) {
        setTicket(response.ticket);
        const tieneKilometraje = response.ticket.items_gastos.some(
          (item) => Number(item.kilometros) > 0
        );
        setMostrarVisorRutas(tieneKilometraje);
      }
      setIsLoading(false);
    });
  }

  useEffect(() => {
    ObtieneTicket();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRechazaItem = (item) => {
    AlertaConfirmComentario({
      title: 'Rechazar Item',
      text: `¿Está seguro que desea rechazar el item ${item.tipo_gasto} con un monto de $${item.monto}?.`,
      callback: (comentario) => {
        rechazarItemGasto(item.id, comentario, apiToken).then((response) => {
          if (response.status === 200) {
            ObtieneTicket();
            AlertToast({
              icon: 'success',
              title: 'Item Rechazado',
              timer: 3000,
            });
          }
        });
      },
    });
  };

  const handleEditaItem = (item) => {
    AlertaEditaItem({
      title: 'Editar Item',
      text: `¿Está seguro que desea editar el item ${item.tipo_gasto} con un monto de $${item.monto}?.`,
      callback: (comentario) => {
        const nuevaData = {
          id: item.id,
          ticket_id: item.ticket_id,
          comentario_validador: comentario.comentario_validador,
          monto: comentario.monto,
          corregir: 0,
        };
        editarItemGasto(item.id, nuevaData, apiToken).then((response) => {
          if (response.status === 200) {
            ObtieneTicket();
            AlertToast({
              icon: 'success',
              title: 'Monto Editado',
              timer: 3000,
            });
          } else {
            AlertToast({
              icon: 'error',
              title: 'Error al editar',
              timer: 3000,
            });
          }
        });
      },
    });
  };

  return (
    <section className="row">
      <div className="col-12 col-lg-7">
        {isLoading ? <SpinerDownload /> : null}
        <div className="row">
          <div className="card mb-3 p-0">
            <div className="card-header">
              <h4>
                Ticket {ticket.nomenclatura}
                {ticket.ticket}
              </h4>
            </div>
            <div className="card-body">
              {ticket.id && (
                <div className="border">
                  <div className="d-flex">
                    <div className="border-end p-2 col-2 fw-bolder">
                      Fecha Atención
                    </div>
                    <div className="border-end p-2 col-2 fw-bolder">
                      Hora Inicio
                    </div>
                    <div className="border-end p-2 col-2 fw-bolder">
                      Hora Termino
                    </div>
                    <div className="p-2 col-6 fw-bolder">Cliente</div>
                  </div>
                  <div className="d-flex">
                    <div className="border-end px-2 col-2">
                      {formatDate(ticket.fecha_atencion)}
                    </div>
                    <div className="border-end px-2 col-2">
                      {ticket.hora_inicio.slice(0, 5)}
                    </div>
                    <div className="border-end px-2 col-2">
                      {ticket.hora_termino.slice(0, 5)}
                    </div>
                    <div className="px-2 col-6">{ticket.cliente}</div>
                  </div>
                  <div className="border-top">
                    <div className="d-flex">
                      <div className="border-end p-2 col-1 fw-bolder">
                        Desde
                      </div>
                      <div className="border-end p-2 col-4">
                        {ticket.direccion_desde}
                      </div>
                      <div className="p-2 col-2">{ticket.comuna_desde}</div>
                    </div>
                    <div className="d-flex">
                      <div className="border-end p-2 col-1 fw-bolder">
                        Hasta
                      </div>
                      <div className="border-end p-2 col-4">
                        {ticket.direccion_hasta}
                      </div>
                      <div className="p-2 col-2">{ticket.comuna_hasta}</div>
                    </div>
                    <div className="d-flex border-top">
                      <div className="border-end p-2 col-2 fw-bolder">
                        Comentario:
                      </div>
                      <div className="p-2 col-10">
                        {ticket.observacion || 'Sin Comentario'}
                      </div>
                    </div>
                  </div>
                  <div className="border-top d-flex px-2">
                    <div className="border-end col-2 fw-bolder">
                      Radio urbano
                    </div>
                    <div className="border-end px-2 col-1">
                      {ticket.radio_urbano === '1' ? 'Si' : 'No'}
                    </div>
                    <div className="border-end px-2 col-2 fw-bolder">
                      IBM / Gepro
                    </div>
                    <div className="border-end px-2 col-2 d-flex justify-content-between">
                      {ticket.correlativo_gepro &&
                        (ticket.correlativo_gepro || 'N/A')}
                    </div>
                    <div className="p-2 col-5">
                      Creado: {formatDateTime(ticket.created_at)}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="card p-0">
            <div className="card-header">
              <h4>Items</h4>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Concepto</th>
                      <th>Kms</th>
                      <th>Monto</th>
                      <th>Comentarios / Observaciones</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ticket.items_gastos && ticket.items_gastos.length > 0 ? (
                      ticket.items_gastos.map((item) => (
                        <tr
                          key={item.id}
                          className={
                            Number(item.corregir) === 1 ? 'table-danger' : ''
                          }
                        >
                          <td className="col-2">{item.tipo_gasto}</td>
                          <td className="col-1">{item.kilometros}</td>
                          <td className="col-1">{item.monto}</td>
                          <td className="col-6">
                            {item.comentario_gasto}
                            {item.comentario_validador && (
                              <>
                                <br />
                                <small>
                                  <strong className="text-danger">
                                    Validador:
                                  </strong>{' '}
                                  {item.comentario_validador}
                                </small>
                              </>
                            )}
                          </td>

                          <td className="col-2">
                            <div className="d-flex justify-content-end">
                              {item.comprobante ? (
                                <button
                                  className="btn btn-sm btn-outline-info"
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#modalVerComprobante"
                                  onClick={() => {
                                    setItemModal(item);
                                    setMostrarVisorComprobante(true);
                                  }}
                                >
                                  <i className="bi bi-eye"></i>
                                </button>
                              ) : (
                                'S/C'
                              )}
                              {item.tipo_gasto !== 'Kilometraje' ? (
                                <button
                                  className="btn btn-sm btn-outline-warning mx-1"
                                  onClick={() => {
                                    handleEditaItem(item);
                                  }}
                                >
                                  <i className="bi bi-pencil"></i>
                                </button>
                              ) : null}
                              <button
                                className="btn btn-sm btn-outline-danger"
                                onClick={() => {
                                  handleRechazaItem(item);
                                }}
                                // data-bs-toggle="modal"
                                // data-bs-target="#modalVerComprobante"
                              >
                                <i className="bi bi-exclamation-octagon-fill"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4">Sin Items de gastos</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <small className="text-secondary">
                  * S/C : Sin Comprobante
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  Tercera columna  */}
      <div className="col-12 col-lg-5">
        <div className="col-12">
          {mostrarVisorComprobante && itemModal ? (
            <VisorComprobante
              item={itemModal}
              onClose={setMostrarVisorComprobante}
            />
          ) : null}
          {mostrarVisorRutas && ticket && ticket.direccion_desde ? (
            <VisorRutas
              desde={`${ticket.direccion_desde}, ${ticket.comuna_desde}, Chile`}
              hasta={`${ticket.direccion_hasta}, ${ticket.comuna_hasta}, Chile`}
            />
          ) : null}
        </div>
      </div>
    </section>
  );
}

export default VerTicketValidador;
