import { AlertaError } from '../components/general/Alerts';

const campoMensajes = {
  fecha_atencion: 'Debe ingresar la fecha de atención',
  hora_inicio: 'Debe ingresar la hora de inicio',
  hora_termino: 'Debe ingresar la hora de termino',
  proyecto_id: 'Debe seleccionar un proyecto / servicio',
  ticket: 'Debe ingresar el número de ticket',
  direccion_desde: 'Debe ingresar la dirección de origen',
  comuna_desde_id: 'Debe seleccionar la comuna de origen',
  direccion_hasta: 'Debe ingresar la dirección de destino',
  comuna_hasta_id: 'Debe seleccionar la comuna de destino',
};

export const validaNuevoTicket = (ticket) => {
  const campoFaltante = Object.keys(campoMensajes).find((key) => !ticket[key]);
  if (campoFaltante) {
    AlertaError({
      title: 'Ticket Incompleto',
      error: campoMensajes[campoFaltante],
    });
    return;
  }
  return true;
};
