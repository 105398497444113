import '../../assets/css/pages/error403.css';
const Error403 = () => {
  return (
    <>
      <div className="layout-error row position-absolute top-0 start-50 translate-middle-x">
        <img src="../../images/logo_HyO.webp" alt="HyO Ltda." width="100%" />
      </div>
      <div className="position-absolute bottom-0 start-50 translate-middle-x text-center mb-5">
        <div className="col-12">
          <h1>404</h1>
        </div>
        <div className="col-12">
          <h2>No encontrado</h2>
        </div>
      </div>
    </>
  );
};

export default Error403;
