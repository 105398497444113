import { useState } from 'react';
import CardUserLogged from '../../general/cardsInfo/CardUserLogged';
import { createFpr } from '../../../services/fondosPorRendir';
import useUser from '../../../hooks/useUser';
import SelectTecnicos from '../../general/forms/SelectTecnicos';

function NuevoFpr() {
  const { apiToken, dataUserLogged } = useUser();
  const [tecnicoId, setTecnicoId] = useState('');
  const [montoDeposito, setMontoDeposito] = useState('');
  const [fechaServicio, setFechaServicio] = useState('');
  const [comentarioSolicitud, setComentarioSolicitud] = useState('');

  const handleTecnicoChange = (value) => {
    setTecnicoId(value);
  };

  const resetForm = () => {
    setTecnicoId('');
    setMontoDeposito('');
    setFechaServicio('');
    setComentarioSolicitud('');
  };

  const handleSubmit = (e) => {
    console.log('submit');
    e.preventDefault();
    // Validar que los campos no esten vacios
    if (tecnicoId === '' || montoDeposito === '' || fechaServicio === '') {
      return alert('Todos los campos son obligatorios');
    }
    // Validar que el monto sea mayor a 0
    if (montoDeposito <= 0) {
      return alert('El monto debe ser mayor a 0');
    }

    const fondo = {
      tecnicoId,
      montoDeposito,
      fechaServicio,
      comentarioSolicitud,
      solicita_id: dataUserLogged.id,
      estado: 1, // Estado 1: Solicitado
    };

    const response = createFpr(fondo, apiToken);

    if (response.status === 200) {
      alert('FPR creado correctamente');
    } else {
      alert('Error al crear FPR');
    }

    // Restablecer los valores del formulario
    resetForm();
  };

  return (
    <section className="row">
      <div className="col-12 col-lg-9">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">NUEVO</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Tecnico:</label>
                    <SelectTecnicos
                      value={tecnicoId}
                      onChange={handleTecnicoChange}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label>Monto:</label>
                    <input
                      type="number"
                      className="form-control"
                      value={montoDeposito}
                      onChange={(e) => setMontoDeposito(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label>Fecha Servicio:</label>
                    <input
                      type="date"
                      className="form-control"
                      value={fechaServicio}
                      onChange={(e) => setFechaServicio(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>Comentario Solicitud:</label>
                <textarea
                  className="form-control"
                  value={comentarioSolicitud}
                  onChange={(e) => setComentarioSolicitud(e.target.value)}
                />
              </div>
              <div className="col-12 d-flex justify-content-between">
                <button type="submit" className="btn btn-primary">
                  Generar FPR
                </button>
                <button
                  type="reset"
                  className="btn btn-secondary"
                  onClick={resetForm}
                >
                  Limpiar
                </button>
              </div>
            </form>
          </div>
          <div className="card-footer"></div>
        </div>
      </div>
      {/*  Tercera columna  */}
      <div className="col-12 col-lg-3">
        {/*  Card info usuario logueado  */}
        <CardUserLogged />

        {/*  Card ultimas rendiciones si no esta cargando y estadisticas no esta vacio  */}

        {/*  Card utimas aprovadas  */}
      </div>
    </section>
  );
}

export default NuevoFpr;
