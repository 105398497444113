import { AlertaError } from '../general/Alerts';
import { LOGIN } from '../../routes/rutas';
import Main from '../general/Main';
import MenuValidador from '../validador/MenuValidador';
import { Navigate } from 'react-router-dom';
import Sidebar from '../general/Sidebar';
import useUser from '../../hooks/useUser';

function ValidadorRoute() {
  const { isLogged, role, validateToken, logout } = useUser();

  if (!isLogged || role !== 'Validador') return <Navigate to={LOGIN} />;

  const tokenValidation = validateToken();
  setTimeout(() => {
    AlertaError({
      title: 'Sesión expirada!',
      error: 'Su sesión ha expirado, vuelva a iniciar sesión',
    });
    logout();
    return <Navigate to={LOGIN} />;
  }, tokenValidation * 1000);

  return (
    <>
      <Sidebar menu={<MenuValidador />} />
      <Main />
    </>
  );
}

export default ValidadorRoute;
