import { apiUrl } from '../config';

// get tipos gastos
export const getTiposGastos = async (apiToken) => {
  // try {
  //   const response = await fetch(`${apiUrl('misc/gastos/tipos')}`, {
  //     headers: {
  //       Authorization: `Bearer ${apiToken}`,
  //     },
  //   });
  //   const data = await response.json();
  //   data.status = response.status;
  //   return data;
  // } catch (error) {
  //   console.log(error);
  // }
  return {
    status: 200,
    tipos_gastos: [
      {
        id: 1,
        tipo: 'Kilometraje',
        descripcion: 'Kilometraje a recorrer desde el origen a destino',
      },
      {
        id: 2,
        tipo: 'Arriendo de Vehiculo',
        descripcion: 'Arriendo de vehiculo para el traslado',
      },
      {
        id: 3,
        tipo: 'Colacion',
        descripcion: 'Compra de colacion ',
      },
      {
        id: 4,
        tipo: 'Colectivo',
        descripcion: 'Compra de pasaje de colectivo',
      },
      {
        id: 5,
        tipo: 'Combustible',
        descripcion: 'Carga de combustible',
      },
      {
        id: 6,
        tipo: 'Estacionamiento',
        descripcion: 'Pago de estacionamiento',
      },
      {
        id: 7,
        tipo: 'Estadia',
        descripcion: 'Pago de estadia',
      },
      {
        id: 8,
        tipo: 'Ferry',
        descripcion: 'Pago de ferry',
      },
      {
        id: 9,
        tipo: 'Herramientas',
        descripcion: 'Compra de herramientas',
      },
      {
        id: 10,
        tipo: 'Materiales de escritorio',
        descripcion: 'Compra de materiales de escritorio',
      },
      {
        id: 11,
        tipo: 'Pasaje de Bus',
        descripcion: 'Compra de pasaje de bus interurbano',
      },
      {
        id: 12,
        tipo: 'Pasaje de Microbus',
        descripcion: 'Compra de pasaje de microbus urbano',
      },
      {
        id: 13,
        tipo: 'Peaje',
        descripcion: 'Pago de peaje',
      },
      {
        id: 14,
        tipo: 'TAG',
        descripcion: 'Pago de tag',
      },
      {
        id: 15,
        tipo: 'Taxi/App',
        descripcion: 'Compra de pasaje de taxi, uber, didi, etc.',
      },
      {
        id: 16,
        tipo: 'Bip',
        descripcion: 'Carga de tarjeta bip',
      },
      {
        id: 17,
        tipo: 'Otro',
        descripcion: 'Otro gasto, especificar en comentario',
      },
      {
        id: 18,
        tipo: 'Envíos/Retiros',
        descripcion: 'Envíos y Retiros',
      },
      {
        id: 19,
        tipo: 'Herramientas autorizadas por IBM',
        descripcion: 'Compra de herramientas autorizadas por IBM',
      },
      {
        id: 20,
        tipo: 'Compras autorizadas por IBM',
        descripcion: 'Compras autorizadas por IBM',
      },
      {
        id: 21,
        tipo: 'Insumos',
        descripcion: 'Compras de insumos',
      },
      {
        id: 22,
        tipo: 'Pasaje Metrotren',
        descripcion: 'Compras de pasaje servicio Metrotren',
      },
      {
        id: 23,
        tipo: 'Cotización',
        descripcion: 'Cotización',
      },
    ],
  };
};

// delete tipo gasto
export const deleteTipoGasto = async (id, apiToken) => {
  try {
    const response = await fetch(
      `${apiUrl('administracion/gastos/tipo')}/${id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }
    );
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};

// create tipo gasto
export const createTipoGasto = async (tipoGasto, apiToken) => {
  try {
    const response = await fetch(`${apiUrl('administracion/gastos/tipo')}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiToken}`,
      },
      body: JSON.stringify(tipoGasto),
    });
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};

/*******************************
 * Centro de Costos
 *******************************/

// Obtiene los centros de costos
export const getCentrosCostos = async (apiToken) => {
  try {
    const response = await fetch(`${apiUrl('misc/centroscostos')}`, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Crea un centro de costos
export const createCentroCosto = async (centroCosto, apiToken) => {
  console.log(centroCosto);
  // try {
  //   const response = await fetch(`${apiUrl('administracion/centroscostos')}`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${apiToken}`,
  //     },
  //     body: JSON.stringify(centroCosto),
  //   });
  //   const data = await response.json();
  //   data.status = response.status;
  //   return data;
  // } catch (error) {
  //   console.log(error);
  // }
};

// Elimina un centro de costos
export const deleteCentroCosto = async (id, apiToken) => {
  console.log(id);
};
