import { Link } from 'react-router-dom';
import { getRendicionesTodas } from '../../../services/rendicionesT';
import useUser from '../../../hooks/useUser';
import { useEffect, useState } from 'react';
import { formatDate } from '../../../hooks/useDates';
import CustomTable from '../CustomTable';

const excelName = `Lista de rendiciones del año ${new Date().getFullYear()}`;

function TablaRendiciones() {
  const { apiToken, dataUserLogged } = useUser();
  const [rendiciones, setRendiciones] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [perfil, setPerfil] = useState('');

  useEffect(() => {
    // dataUserlogged en minuscula
    setPerfil(dataUserLogged.role.toLowerCase());
  }, [dataUserLogged]);

  const columns = [
    {
      name: 'SEM.',
      cell: (row) => (
        <Link className="text-reset" to={`/${perfil}/rendicion/${row.id}`}>
          {row.semana}
        </Link>
      ),
      sortable: true,
      sortFunction: (a, b) => Number(a.semana) - Number(b.semana),
      width: '5%',
      clave: 'semana',
    },
    { name: 'RUT', selector: (row) => row.rut, sortable: true, clave: 'rut' },
    {
      name: 'NOMBRE',
      selector: (row) => row.nombre,
      sortable: true,
      filterable: true,
      clave: 'nombre',
    },
    {
      name: 'APELLIDO',
      selector: (row) => row.apellido,
      sortable: true,
      clave: 'apellido',
    },
    {
      name: 'REGIÓN',
      selector: (row) => row.region,
      sortable: true,
      clave: 'region',
    },
    {
      name: 'ENVÍO',
      selector: (row) => formatDate(row.fecha_envio),
      sortable: true,
      clave: 'fecha_envio',
    },
    {
      name: 'VALIDACIÓN',
      selector: (row) => row.fecha_valida,
      sortable: true,
      clave: 'fecha_valida',
    },
    {
      name: 'APROBACIÓN',
      selector: (row) => row.fecha_aprueba,
      sortable: true,
      clave: 'fecha_aprueba',
    },
    {
      name: 'CONSOLIDACIÓN',
      selector: (row) => row.fecha_pago,
      sortable: true,
      clave: 'fecha_pago',
    },
    {
      name: 'MONTO',
      selector: (row) => row.total_pago,
      sortable: true,
      right: true,
      width: '7%',
      clave: 'total_pago',
    },
    {
      name: 'ESTADO',
      selector: (row) => row.estado,
      sortable: true,
      clave: 'estado',
    },
    {
      name: 'VER',
      cell: (row) => (
        <Link
          to={`/${perfil}/rendicion/${row.id}`}
          className="btn btn-outline-primary btn-sm"
        >
          <i className="bi bi-eye"></i>
        </Link>
      ),
      width: '5%',
      rigth: true,
    },
  ];

  useEffect(() => {
    const fetchRendiciones = async () => {
      const data = await getRendicionesTodas('todas', apiToken);
      setRendiciones(data.rendiciones);
      setIsLoading(false);
    };
    fetchRendiciones();
  }, [apiToken]);

  return (
    <CustomTable
      columns={columns}
      data={rendiciones}
      isLoading={isLoading}
      exportar={true}
      excelName={excelName}
    />
  );
}

export default TablaRendiciones;
