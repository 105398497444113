import { Link } from 'react-router-dom';
// import { getRendicionesTodas } from '../../../services/rendicionesT';
import useUser from '../../../hooks/useUser';
import { useEffect, useState } from 'react';
import { formatDate } from '../../../hooks/useDates';
import CustomTable from '../CustomTable';

const excelName = `Lista de rendiciones pendientes de validación`;

function TablaRevisar() {
  const { apiToken, dataUserLogged } = useUser();
  const [rendiciones, setRendiciones] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [perfil, setPerfil] = useState('');

  useEffect(() => {
    // dataUserlogged en minuscula
    setPerfil(dataUserLogged.role.toLowerCase());
  }, [dataUserLogged]);

  const columns = [
    {
      name: 'SOL.',
      cell: (row) => (
        <Link className="text-reset" to={`/${perfil}/rendicion/${row.id}`}>
          {row.semana}
        </Link>
      ),
      sortable: true,
      sortFunction: (a, b) => Number(a.semana) - Number(b.semana),
      width: '5%',
      clave: 'semana',
    },
    {
      name: 'NOMBRE',
      selector: (row) => row.nombre,
      sortable: true,
      filterable: true,
      clave: 'nombre',
    },
    {
      name: 'APELLIDO',
      selector: (row) => row.apellido,
      sortable: true,
      clave: 'apellido',
    },
    {
      name: 'REGIÓN',
      selector: (row) => row.region,
      sortable: true,
      clave: 'region',
    },
    {
      name: 'FECHA ENVÍO',
      selector: (row) => formatDate(row.fecha_envio),
      sortable: true,
      clave: 'fecha_envio',
    },
    {
      name: 'MONTO',
      selector: (row) => row.total_pago,
      sortable: true,
      right: true,
      width: '7%',
      clave: 'total_pago',
    },
    {
      name: 'VER',
      cell: (row) => (
        <Link
          to={`/${perfil}/fpr/detalle/${row.id}`}
          className="btn btn-outline-primary btn-sm"
        >
          <i className="bi bi-eye"></i>
        </Link>
      ),
      width: '5%',
      rigth: true,
    },
  ];
  useEffect(() => {
    const fetchRendiciones = async () => {
      // const data = await getRendicionesTodas(2, apiToken);
      // setRendiciones(data.rendiciones);
      const datos = {
        message: 'Rendiciones obtenidas',
        rendiciones: [
          {
            id: '1',
            semana: '1',
            fecha_envio: '2023-07-12 16:30:45',
            fecha_valida: '2023-07-12 09:14:04',
            fecha_aprueba: null,
            fecha_pago: null,
            total_pago: '11200',
            rut: '',
            nombre: 'Mauricio Alejandro',
            apellido: 'Sanchez Mena',
            centro_costo: '30710-V48',
            estado: 'Enviada',
            region: 'Metropolitana',
          },
          {
            id: '2',
            semana: '2',
            fecha_envio: '2023-07-12 16:29:29',
            fecha_valida: '2023-07-12 11:26:06',
            fecha_aprueba: null,
            fecha_pago: null,
            total_pago: '244250',
            rut: '12326866-0',
            nombre: 'John Cesar',
            apellido: 'Godoy Navarrete',
            centro_costo: '30710-V48',
            estado: 'Enviada',
            region: 'Biobio',
          },
        ],
      };
      setRendiciones(datos.rendiciones);
      setIsLoading(false);
    };
    fetchRendiciones();
  }, [apiToken]);

  return (
    <CustomTable
      columns={columns}
      data={rendiciones}
      isLoading={isLoading}
      exportar={true}
      excelName={excelName}
    />
  );
}

export default TablaRevisar;
