import { apiUrl } from '../config';

// Obtiene las ultimas tres rendiciones enviadas
export const getGastosMesTecnicos = async (apiToken) => {
  try {
    const response = await fetch(`${apiUrl('misc/estadisticas/mestecnicos')}`, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Obtiene los kilometros por region
export const getKilometrosRegion = async (apiToken) => {
  try {
    const response = await fetch(`${apiUrl('misc/estadisticas/kmsregiones')}`, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Obtiene los gastos con Gepro por mes
export const getGastosMesGepro = async (apiToken) => {
  try {
    const response = await fetch(`${apiUrl('misc/estadisticas/gepromes')}`, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Obtiene el gasto promedio por proyecto
export const getGastosPromedioTicketProyecto = async (apiToken) => {
  try {
    const response = await fetch(
      `${apiUrl('misc/estadisticas/gastopromproy')}`,
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }
    );
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};
