import React, { useRef, useState } from 'react';
import Resizer from 'react-image-file-resizer';

const resizeFile = (file) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      600,
      600,
      'JPEG',
      90,
      0,
      (uri) => {
        resolve(uri);
      },
      'file',
      'auto'
    );
  });
};

const InputImagen = ({ onImageProcessed }) => {
  const [image, setImage] = useState(null);
  const fileInputRef = useRef(null);

  const processAndSetImage = async (file) => {
    const resizedImage = await resizeFile(file);
    setImage(resizedImage);

    // Llamar al callback con la imagen procesada.
    if (onImageProcessed) {
      onImageProcessed(resizedImage);
    }
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      processAndSetImage(file);
    }
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onDrop = async (event) => {
    event.preventDefault();

    const file = event.dataTransfer.files[0];
    if (file) {
      processAndSetImage(file);
    }
  };

  return (
    <div className="container" onDragOver={onDragOver} onDrop={onDrop}>
      <div className="mb-3 d-flex justify-content-between">
        <div className="col-3">
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={() => fileInputRef.current.click()}
          >
            Imagen
          </button>
          <input
            ref={fileInputRef}
            type="file"
            id="input-file"
            hidden
            onChange={handleImageChange}
            accept="image/*"
          />
        </div>
        <div className="col-9 border p-4">Arrastre y suelte imagen aca</div>
      </div>
    </div>
  );
};

export default InputImagen;
