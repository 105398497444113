import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { formatDate } from '../../../../hooks/useDates';
import useUser from '../../../../hooks/useUser';
import {
  addComentarioRendicion,
  deleteTicket,
  enviarRendicion,
  getRendicion,
} from '../../../../services/rendicionesT';
import { AlertaConfirm, AlertaSuccess } from '../../../general/Alerts';
import ModalComentarioRend from '../nueva/ModalComentarioRend';
import { SpinerDownload } from '../../../general/loader/SpinerLoader';

function RendicionSemana() {
  const { apiToken } = useUser();
  const [rendicion, setRendicion] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [totalKilometros, setTotalKilometros] = useState(0);
  const [totalGastos, setTotalGastos] = useState(0);
  const [kilometrosTotales, setKilometrosTotales] = useState(0);
  const [totalPagar, setTotalPagar] = useState(0);
  const [recargarData, setRecargarData] = useState(false);
  const [comentarioTecnico, setComentarioTecnico] = useState('');

  // obtener el id de la url
  const id = window.location.pathname.split('/')[4];

  // scroll top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function ObtieneRendicion(id, apiToken) {
    setIsLoading(true);
    getRendicion(id, apiToken).then((response) => {
      if (response.status === 200) {
        setRendicion(response.rendicion);
      }
      setIsLoading(false);
    });
  }

  useEffect(() => {
    ObtieneRendicion(id, apiToken);
  }, [apiToken, id]);

  // Detecta cuando se presiona aceptar en modal comentario
  useEffect(() => {
    if (recargarData) {
      if (comentarioTecnico !== '') {
        AlertaConfirm({
          title: '¿Agregar Comentario?',
          text: '¿Estas seguro de agregar el comentario?',
          callback: async () => {
            const dataComentario = {
              id: id,
              observaciones_tecnico: comentarioTecnico,
            };
            setIsLoading(true);
            const res = await addComentarioRendicion(dataComentario, apiToken);
            if (res && res.status === 200) {
              ObtieneRendicion(id, apiToken);
              AlertaSuccess({
                title: 'Comentario Agregado',
                text: 'El comentario se agrego correctamente',
              });
              setRecargarData(false);
            }
          },
        });
      }
      setRecargarData(false);
    }
  }, [recargarData, id, apiToken, comentarioTecnico]);

  useEffect(() => {
    if (rendicion.tickets) {
      let kilometrosTotales = 0;
      let totalKilometros = 0;
      let totalGastos = 0;
      rendicion.tickets.forEach((ticket) => {
        kilometrosTotales += Number(ticket.kilometros);
        totalKilometros += Number(ticket.subtotal_kms);
        totalGastos += Number(ticket.subtotal_gastos);
      });

      // totalKilometros = kilometrosTotales * Number(rendicion.valor_kilometro);

      // setTotalPagar(totalGastos);
      setTotalPagar(totalGastos + totalKilometros);
      setKilometrosTotales(kilometrosTotales);
      setTotalKilometros(totalKilometros);
      // setTotalGastos(totalGastos - totalKilometros);
      setTotalGastos(totalGastos);
    }
  }, [rendicion]);

  // comentario change
  const handleComentarioChange = (comentario) => {
    setComentarioTecnico(comentario);
  };

  // Eliminar Ticket
  const handleEliminarTicket = (idTicket, ticket) => {
    AlertaConfirm({
      title: '¿Eliminar Ticket?',
      text: `¿Estas seguro de eliminar el ticket/servicio ${ticket}?`,
      callback: async () => {
        setIsLoading(true);
        const res = await deleteTicket(idTicket, apiToken);
        if (res && res.status === 200) {
          ObtieneRendicion(id, apiToken);
          AlertaSuccess({
            title: 'Ticket Eliminado',
            text: 'El ticket/servicio se elimino correctamente',
          });
        }
      },
    });
  };

  // Editar Ticket
  const handleEditarTicket = (idTicket, ticket) => {
    AlertaConfirm({
      title: '¿Modificar Ticket?',
      text: `Se editarara el ticket/servicio ${ticket}`,
      callback: async () => {
        // abrir url de edicion con usenavigate
        navigate(
          `/tecnico/rendiciones/editarticket/${idTicket}/${rendicion.fecha_desde}/${rendicion.fecha_hasta}`
        );
      },
    });
  };

  const handleEnvio = (e) => {
    e.preventDefault();

    AlertaConfirm({
      title: '¿Enviar Rendicion?',
      text: '¿Estas seguro de enviar la rendicion a aprobacion?',
      callback: async () => {
        const dataRendicion = {
          id: id,
          kilometros: kilometrosTotales,
          total_kilometros: totalKilometros,
          total_gastos: totalGastos,
          total_pago: totalPagar,
          estado: 2,
        };
        const res = await enviarRendicion(dataRendicion, apiToken);
        if (res && res.status === 200) {
          ObtieneRendicion(id, apiToken);
          AlertaSuccess({
            title: 'Enviado',
            text: 'La rendicion ha sido enviada a aprobacion',
          });
        }
      },
    });
  };

  return (
    <section className="row">
      <div className="col-12">
        {isLoading && <SpinerDownload />}
        <div className="card">
          <div className="card-header">
            <h4>Rendición Semana {rendicion.numero}</h4>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th colSpan="3" scope="col">
                      #{rendicion.numero}
                    </th>
                    <th scope="col">
                      Desde {formatDate(rendicion.fecha_desde)}
                    </th>
                    <th scope="col">
                      Hasta {formatDate(rendicion.fecha_hasta)}
                    </th>
                    <th scope="col">Región {rendicion.region}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="3" scope="row">
                      Nombre
                    </th>
                    <td>{rendicion.nombre}</td>
                    <td>{rendicion.apellido}</td>
                    <td>Enviada el: {rendicion.fecha_envio || 'No enviada'}</td>
                  </tr>
                  <tr>
                    <td colSpan="6">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th scope="col">Kilómetros totales</th>
                            <th scope="col">Total Kilómetros</th>
                            <th scope="col">Total Gastos</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{kilometrosTotales || 0} kms</td>
                            <td>${totalKilometros || '0'} pesos</td>
                            <td>${totalGastos || '0'} pesos</td>
                          </tr>
                          <tr>
                            <th scope="row">Comentario Solicitud:</th>
                            <td colSpan="4">
                              <div className="d-flex justify-content-between">
                                <span>{rendicion.observaciones_tecnico}</span>
                                {rendicion.estado_nombre === 'Pendiente' ||
                                rendicion.estado_nombre ===
                                  'Rechazada Validacion' ? (
                                  <button
                                    className="btn btn-secondary btn-sm"
                                    data-bs-toggle="modal"
                                    data-bs-target="#modalComentarioRend"
                                  >
                                    <i className="bi bi-chat-square-text"></i>{' '}
                                    {rendicion.estado_nombre ===
                                    'Rechazada Validacion'
                                      ? 'Editar Comentario'
                                      : 'Agregar Comentario'}
                                  </button>
                                ) : null}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Comentario Validador:</th>
                            <td colSpan="4">
                              {rendicion.observaciones_validador ||
                                'Sin comentario'}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <th colSpan="4" scope="row" className="fs-5">
                      Total a pagar
                    </th>
                    <td>
                      <strong className="fs-4">
                        ${totalPagar || '0'} pesos
                      </strong>
                    </td>

                    <td>
                      Estado: <strong>{rendicion.estado_nombre}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-12">
        <div className="card">
          <div className="card-header p-3 bg-success bg-opacity-10 border border-hyo border-start-1 rounded-end d-flex justify-content-between">
            <h4>Tickets</h4>
            {Number(rendicion.estado) === 1 ||
            Number(rendicion.estado) === 6 ? (
              <Link
                to={`/tecnico/rendiciones/agregarticket/${rendicion.id}/${rendicion.fecha_desde}/${rendicion.fecha_hasta}`}
                className="btn btn-secondary ms-1"
              >
                <i className="bi bi-receipt"></i> Agregar Ticket
              </Link>
            ) : null}
          </div>
          <div className="card-body ">
            {/* Tabla tickets */}
            <div className="table-responsive">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Fecha</th>
                    <th>Hr Inicio</th>
                    <th>Hr Termino</th>
                    <th>Proyecto</th>
                    <th>Ticket</th>
                    <th>Origen</th>
                    <th>Comuna</th>
                    <th>Destino</th>
                    <th>Comuna</th>
                    <th>R. Urbano</th>
                    <th>Kms</th>
                    <th>SubT.</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading && rendicion.tickets.length > 0 ? (
                    rendicion.tickets.map((ticket, index) => (
                      <tr
                        key={ticket.id}
                        className={
                          Number(ticket.items_rechazados) > 0
                            ? 'table-danger'
                            : ''
                        }
                      >
                        <td>{index + 1}</td>
                        <td>{ticket.fecha_atencion}</td>
                        <td>{ticket.hora_inicio.slice(0, 5)}</td>
                        <td>{ticket.hora_termino.slice(0, 5)}</td>
                        <td>{ticket.cliente}</td>
                        <td>
                          {ticket.nomenclatura}
                          {ticket.ticket}
                        </td>
                        <td>{ticket.direccion_desde}</td>
                        <td>{ticket.comuna_desde}</td>
                        <td>{ticket.direccion_hasta}</td>
                        <td>{ticket.comuna_hasta}</td>
                        <td>{ticket.radio_urbano === '1' ? 'Si' : 'No'}</td>
                        <td>{ticket.kilometros}</td>
                        <td>
                          $
                          {Number(ticket.subtotal_kms) +
                            Number(ticket.subtotal_gastos)}
                        </td>
                        <td>
                          <div className="d-flex justify-content-center">
                            <Link
                              to={`/tecnico/rendiciones/ticket/${ticket.id}`}
                              className="btn btn-sm btn-info"
                            >
                              <i className="bi bi-eye"></i>
                            </Link>
                            {Number(rendicion.estado) === 1 ||
                            Number(rendicion.estado) === 6 ? (
                              <button
                                className="btn btn-sm btn-warning ms-2"
                                onClick={() =>
                                  handleEditarTicket(
                                    ticket.id,
                                    `${ticket.nomenclatura}${ticket.ticket}`
                                  )
                                }
                              >
                                <i className="bi bi-pencil"></i>
                              </button>
                            ) : null}
                            {Number(rendicion.estado) === 1 ||
                            Number(rendicion.estado) === 6 ? (
                              <button
                                className="btn btn-sm btn-danger ms-2"
                                onClick={() =>
                                  handleEliminarTicket(
                                    ticket.id,
                                    `${ticket.nomenclatura}${ticket.ticket}`
                                  )
                                }
                              >
                                <i className="bi bi-trash"></i>
                              </button>
                            ) : null}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="13">Rendicion sin tickets</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card-footer">
            {Number(rendicion.estado) === 1 ||
            Number(rendicion.estado) === 6 ? (
              <button
                className="btn btn-primary btn-block"
                onClick={handleEnvio}
              >
                <i className="bi bi-cloud-upload"></i> Enviar rendición
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <ModalComentarioRend
        setRecargarData={setRecargarData}
        onChange={handleComentarioChange}
      />
    </section>
  );
}

export default RendicionSemana;
