import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { formatDate, formatDateTime } from '../../../hooks/useDates';
import {
  actualizarEstadoRendicion,
  getRendicion,
} from '../../../services/rendicionesT';
import useUser from '../../../hooks/useUser';
import {
  AlertaConfirmComentario,
  AlertaConfirmRadio,
  AlertaSuccess,
  AlertaConfirm,
  AlertaError,
} from '../../general/Alerts';
import { SpinerDownload } from '../../general/loader/SpinerLoader';
import PdfDownloader from '../../general/PdfDownloader';

import styles from './RendicionSemanaView.module.css';

function ButtonPanel({
  role,
  estadoNombre,
  tieneRechazo,
  handleEnvio,
  handleRechazar,
}) {
  const roleConfiguration = {
    Validador: ['Envio', 'Rechazar'],
    Gerencia: ['Envio', 'Rechazar'],
    Finanzas: ['Pagar'],
  };

  const buttonConfiguration = {
    Pagar: {
      className: 'btn btn-success btn-sm oculto-pdf',
      icon: 'bi bi-cloud-upload',
      text: 'Consolidar rendición',
      handleClick: handleEnvio,
    },
    Envio: {
      className: 'btn btn-success btn-sm oculto-pdf',
      icon: 'bi bi-cloud-upload',
      text: 'Aprobar rendición',
      handleClick: handleEnvio,
      disabled: tieneRechazo,
    },
    Rechazar: {
      className: 'btn btn-danger btn-sm oculto-pdf',
      icon: 'bi bi-x-circle',
      text: 'Rechazar rendición',
      handleClick: handleRechazar,
    },
  };

  if (estadoNombre === 'Consolidada') {
    return null;
  }

  return (
    <div className="col-12 d-flex justify-content-between">
      {roleConfiguration[role].map((buttonType) => {
        const { className, icon, text, handleClick, disabled } =
          buttonConfiguration[buttonType];
        return (
          <button
            key={buttonType}
            className={className}
            onClick={handleClick}
            disabled={disabled}
          >
            <i className={icon}></i> {text}
          </button>
        );
      })}
    </div>
  );
}

function RendicionSemana() {
  const { apiToken, dataUserLogged } = useUser();
  const [rendicion, setRendicion] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [totalKilometros, setTotalKilometros] = useState(0);
  const [totalGastos, setTotalGastos] = useState(0);
  const [kilometrosTotales, setKilometrosTotales] = useState(0);
  const [totalPagar, setTotalPagar] = useState(0);
  const [tieneRechazo, setTieneRechazo] = useState(false);
  const [ultimoVisto, setUltimoVisto] = useState(null);
  const [habilitaBotones, setHabilitaBotones] = useState(false);
  const navigate = useNavigate();
  // obtener el id de la url
  const id = window.location.pathname.split('/')[3];
  const [perfil, setPerfil] = useState('');

  useEffect(() => {
    // dataUserlogged en minuscula
    setPerfil(dataUserLogged.role.toLowerCase());
  }, [dataUserLogged]);

  function ObtieneRendicion(id, apiToken) {
    getRendicion(id, apiToken)
      .then((response) => {
        if (response.status === 200) {
          setRendicion(response.rendicion);
        }
      })
      .catch((error) => {
        console.error('Error obteniendo rendición:', error);
        AlertaError({
          title: 'Error',
          error: 'Error al obtener la rendición',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    ObtieneRendicion(id, apiToken);
  }, [apiToken, id]);

  useEffect(() => {
    if (rendicion.tickets) {
      let kilometrosTotales = 0;
      let totalKilometros = 0;
      let totalGastos = 0;
      rendicion.tickets.forEach((ticket) => {
        kilometrosTotales += Number(ticket.kilometros);
        totalKilometros += Number(ticket.subtotal_kms);
        totalGastos += Number(ticket.subtotal_gastos);
      });

      // Redondeamos kilometrosTotales a un dígito decimal
      kilometrosTotales = Number(kilometrosTotales.toFixed(1));

      setTotalPagar(totalGastos + totalKilometros);
      setKilometrosTotales(kilometrosTotales);
      setTotalKilometros(totalKilometros);
      setTotalGastos(totalGastos);
    }
  }, [rendicion]);

  useEffect(() => {
    if (rendicion.tickets) {
      const tieneRechazo = rendicion.tickets.some((ticket) => {
        return ticket.items_rechazados > 0;
      });
      setTieneRechazo(tieneRechazo);
    }
  }, [rendicion]);

  useEffect(() => {
    const ultiaSemanaVista = sessionStorage.getItem('id-semana-viendo');
    if (id === ultiaSemanaVista) {
      const ultimoVisto = sessionStorage.getItem('ultimo-visto');
      setUltimoVisto(ultimoVisto);
    }
  }, [id]);

  const handleUltimoVisto = (ticket) => {
    // almacena ultimo visto en session storage
    sessionStorage.setItem('ultimo-visto', ticket);
    // almacena el id de la semana en session storage
    sessionStorage.setItem('id-semana-viendo', id);
  };

  // Editar Ticket
  const handleEditarTicket = (idTicket, ticket) => {
    AlertaConfirm({
      title: '¿Modificar Ticket?',
      text: `Se editarara el ticket/servicio ${ticket}`,
      callback: async () => {
        // abrir url de edicion con usenavigate
        navigate(
          `/validador/rendiciones/editarticket/${idTicket}/${rendicion.fecha_desde}/${rendicion.fecha_hasta}`
        );
      },
    });
  };

  // Función para interactuar con la API
  const actualizarEstado = (seleccion, id, comentario, apiToken) => {
    let dataSend;
    if (seleccion === 'gerencia') {
      dataSend = {
        estado: 3,
        fecha_valida: new Date().toISOString().slice(0, 19).replace('T', ' '),
        valida_id: dataUserLogged.id,
      };
      comentario && (dataSend.observaciones_validador = comentario);
    } else if (seleccion === 'valTofinanzas') {
      dataSend = {
        estado: 4,
        fecha_valida: new Date().toISOString().slice(0, 19).replace('T', ' '),
        valida_id: dataUserLogged.id,
        fecha_aprueba: new Date().toISOString().slice(0, 19).replace('T', ' '),
        aprueba_id: dataUserLogged.id,
      };
      comentario && (dataSend.observaciones_validador = comentario);
    } else if (seleccion === 'valTovacaciones') {
      dataSend = {
        estado: 8,
        fecha_valida: new Date().toISOString().slice(0, 19).replace('T', ' '),
        valida_id: dataUserLogged.id,
      };
      comentario && (dataSend.observaciones_validador = comentario);
    } else if (seleccion === 'valTolicencia') {
      dataSend = {
        estado: 9,
        fecha_valida: new Date().toISOString().slice(0, 19).replace('T', ' '),
        valida_id: dataUserLogged.id,
      };
      comentario && (dataSend.observaciones_validador = comentario);
    } else if (seleccion === 'valTosingastos') {
      dataSend = {
        estado: 11,
        fecha_valida: new Date().toISOString().slice(0, 19).replace('T', ' '),
        valida_id: dataUserLogged.id,
      };
      comentario && (dataSend.observaciones_validador = comentario);
    } else if (seleccion === 'finanzas') {
      dataSend = {
        estado: 4,
        fecha_aprueba: new Date().toISOString().slice(0, 19).replace('T', ' '),
        aprueba_id: dataUserLogged.id,
      };
      comentario && (dataSend.observaciones_gerencia = comentario);
    } else if (seleccion === 'consolidado') {
      dataSend = {
        estado: 5,
        fecha_pago: new Date().toISOString().slice(0, 19).replace('T', ' '),
        pago_id: dataUserLogged.id,
      };
    }
    return actualizarEstadoRendicion(id, dataSend, apiToken);
  };

  // Función para manejar confirmaciones de usuario en función del rol
  const handleConfirmation = (role, id, apiToken) => {
    if (role === 'Validador') {
      AlertaConfirmRadio({
        title: '¿Aprobar Rendición?',
        text: 'Se aprobara la rendición y se enviara a:',
        icon: 'question',
        callback: (seleccion) => {
          setIsLoading(true);
          actualizarEstado(seleccion.value, id, seleccion.comentario, apiToken)
            .then((response) => {
              if (response.status === 200) {
                AlertaSuccess({
                  title: 'Enviado',
                  success: `La rendición ha sido enviada a ${
                    seleccion.value.charAt(0).toUpperCase() +
                    seleccion.value.slice(1)
                  }`,
                });
              }
              ObtieneRendicion(id, apiToken);
            })
            .catch((error) => {
              console.error('Error durante la actualización:', error);
              AlertaError({
                title: 'Error',
                error: 'Error al enviar la rendición',
              });
            });
        },
      });
    } else if (role === 'Finanzas') {
      AlertaConfirm({
        title: '¿Consolidar Rendición?',
        text: 'Se consolidara la rendición y se notificara al técnico.',
        callback: () => {
          setIsLoading(true);
          actualizarEstado('consolidado', id, apiToken)
            .then((response) => {
              if (response.status === 200) {
                AlertaSuccess({
                  title: 'Enviado',
                  success: `La rendición ha sido consolidada`,
                });
              }
              ObtieneRendicion(id, apiToken);
            })
            .catch((error) => {
              console.error('Error durante la actualización:', error);
              AlertaError({
                title: 'Error',
                error: 'Error al enviar la rendición',
              });
            });
        },
      });
    } else if (role === 'Gerencia') {
      AlertaConfirmComentario({
        title: '¿Aprobar Rendición?',
        text: 'Se aprobara la rendición y se enviara a finanzas para su consolidación:',
        icon: 'question',
        callback: (comentario) => {
          setIsLoading(true);
          actualizarEstado('finanzas', id, comentario, apiToken)
            .then((response) => {
              if (response.status === 200) {
                AlertaSuccess({
                  title: 'Enviado',
                  success: `La rendición ha sido enviada a finanzas para su consolidación`,
                });
              }
              ObtieneRendicion(id, apiToken);
            })
            .catch((error) => {
              console.error('Error durante la actualización:', error);
              AlertaError({
                title: 'Error',
                error: 'Error al enviar la rendición',
              });
            });
        },
      });
    }
  };

  const handleEnvio = (e) => {
    e.preventDefault();
    handleConfirmation(dataUserLogged.role, id, apiToken);
  };

  const handleRechazar = (e) => {
    e.preventDefault();
    AlertaConfirmComentario({
      title: '¿Rechazar Rendición?',
      text:
        dataUserLogged.role === 'Validador'
          ? 'Se rechazara la rendición y se devolvera al técnico para su corrección'
          : 'Se rechazara la rendición y volvera a validación para su corrección',
      icon: 'question',
      callback: (comentario) => {
        setIsLoading(true);
        const dataSend = {
          [dataUserLogged.role === 'Validador'
            ? 'fecha_valida'
            : 'fecha_aprueba']: new Date()
            .toISOString()
            .slice(0, 19)
            .replace('T', ' '),
          [dataUserLogged.role === 'Validador' ? 'valida_id' : 'aprueba_id']:
            dataUserLogged.id,
          estado:
            dataUserLogged.role === 'Validador'
              ? 6
              : dataUserLogged.role === 'Gerencia'
              ? 7
              : undefined,
        };

        if (comentario) {
          dataSend[
            dataUserLogged.role === 'Validador'
              ? 'observaciones_validador'
              : 'observaciones_gerencia'
          ] = comentario;
        }
        actualizarEstadoRendicion(id, dataSend, apiToken)
          .then((response) => {
            if (response.status === 200) {
              AlertaSuccess({
                title: 'Realizado',
                success: 'La rendición ha sido rechazada',
              });
            }
            ObtieneRendicion(id, apiToken);
          })
          .catch((error) => {
            console.error('Error durante la actualización:', error);
            AlertaError({
              title: 'Error',
              error: 'Error al rechazar la rendición',
            });
          });
      },
    });
  };

  // Primero comprobamos que rendicion y rendicion.historial existen
  const historialFiltrado =
    rendicion && rendicion.historial
      ? rendicion.historial.filter((historial) => historial.estado !== '1')
      : [];

  /** PDF */

  return (
    <section className="row" id="documento">
      <div className="col-12">
        {isLoading ? <SpinerDownload /> : null}
        <div className="card">
          <div className="card-header">
            <h4>Rendición Semana {rendicion.numero}</h4>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th colSpan="1">#{rendicion.numero}</th>
                    <th colSpan="1">
                      Desde {formatDate(rendicion.fecha_desde)}
                    </th>
                    <th colSpan="1">
                      Hasta {formatDate(rendicion.fecha_hasta)}
                    </th>
                    <th colSpan="2">
                      Enviada el:{' '}
                      {formatDateTime(rendicion.fecha_envio) || 'No enviada'}
                    </th>
                    <th colSpan="1">Estado: {rendicion.estado_nombre}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="1" scope="row">
                      Nombre
                    </th>
                    <td colSpan="3">
                      {rendicion.nombre} {rendicion.apellido}
                    </td>

                    <td colSpan="1">
                      <strong>Región</strong> {rendicion.region}
                    </td>
                    <td colSpan="1">
                      <strong>Telefono</strong> {rendicion.telefono}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="5">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th width="200px">Kilómetros</th>
                            <th width="200px">Total Kilómetraje</th>
                            <th width="200px">Total Gastos</th>
                            <th width="200px">Información adicional</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{kilometrosTotales || 0} kms</td>
                            <td>${totalKilometros || '0'} pesos</td>
                            <td>${totalGastos || '0'} pesos</td>
                            <td colSpan="2">
                              {rendicion.valor_kilometro && (
                                <span className="badge rounded-pill bg-light-primary fw-normal">
                                  Km: ${rendicion.valor_kilometro}
                                </span>
                              )}
                              <span className="badge rounded-pill bg-light-primary fw-normal ms-2">
                                CC:{' '}
                                {rendicion.centro_costo
                                  ? rendicion.centro_costo.slice(-3)
                                  : 'Sin Dato'}
                              </span>
                              <span className="badge rounded-pill bg-light-primary fw-normal ms-2">
                                {rendicion.tipo_tecnico
                                  ? rendicion.tipo_tecnico
                                  : 'Sin Dato'}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <th>Comentario Técnico:</th>
                            <td colSpan="4" style={{ maxWidth: '600px' }}>
                              {rendicion.observaciones_tecnico ||
                                'Sin comentario'}
                            </td>
                          </tr>
                          <tr>
                            <th>Comentario Validador:</th>
                            <td colSpan="4">
                              {rendicion.observaciones_validador ||
                                'Sin comentario'}
                            </td>
                          </tr>
                          <tr>
                            <th>Comentario Gerencia:</th>
                            <td colSpan="4">
                              {rendicion.observaciones_gerencia ||
                                'Sin comentario'}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td colSpan="1">
                      <table className="table mb-0 table-bordered">
                        <thead>
                          <tr>
                            <th>Etapas Rendición</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <small>
                                Validación:{' '}
                                {rendicion &&
                                rendicion.fecha_valida &&
                                rendicion.nombre_validador &&
                                rendicion.apellido_validador
                                  ? `${formatDate(rendicion.fecha_valida)} | ${
                                      rendicion.nombre_validador
                                    } ${rendicion.apellido_validador}`
                                  : 'Sin datos'}
                              </small>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <small>
                                Gerencia:{' '}
                                {rendicion &&
                                rendicion.fecha_aprueba &&
                                rendicion.nombre_gerente &&
                                rendicion.apellido_gerente
                                  ? `${formatDate(rendicion.fecha_aprueba)} | ${
                                      rendicion.nombre_gerente
                                    } ${rendicion.apellido_gerente}`
                                  : 'Sin datos'}
                              </small>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <small>
                                Finanzas:{' '}
                                {rendicion &&
                                rendicion.fecha_pago &&
                                rendicion.nombre_finanza &&
                                rendicion.apellido_finanza
                                  ? `${formatDate(rendicion.fecha_pago)} | ${
                                      rendicion.nombre_finanza
                                    } ${rendicion.apellido_finanza}`
                                  : 'Sin datos'}
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <th colSpan="1" scope="row" className="fs-5">
                      Total a pagar
                    </th>
                    <th colSpan="3" className="fs-4">
                      ${totalPagar || '0'} pesos
                    </th>
                    <th>
                      {((dataUserLogged.role === 'Validador' &&
                        rendicion.estado_nombre === 'Enviada') ||
                        (dataUserLogged.role === 'Validador' &&
                          rendicion.estado_nombre === 'Rechazada Gerencia') ||
                        (dataUserLogged.role === 'Gerencia' &&
                          rendicion.estado_nombre === 'Aprobada Validador') ||
                        (dataUserLogged.role === 'Finanzas' &&
                          rendicion.estado_nombre === 'Aprobada Gerencia')) && (
                        <ButtonPanel
                          role={dataUserLogged.role}
                          estadoNombre={rendicion.estado_nombre}
                          tieneRechazo={tieneRechazo}
                          handleEnvio={handleEnvio}
                          handleRechazar={handleRechazar}
                        />
                      )}
                    </th>
                    <th>
                      <PdfDownloader
                        downloadFileName={`Rendicion sem-${rendicion.numero} ${rendicion.nombre} ${rendicion.apellido}`}
                        rootElementId="documento"
                      />
                      {rendicion.estado_nombre === 'Enviada' &&
                        rendicion.comprobante_pago !== null && (
                          <button className="btn btn-outline-info btn-sm">
                            Comprobante
                          </button>
                        )}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
              style={{
                display: historialFiltrado.length > 0 ? 'block' : 'none',
              }} // escondemos el acordeón si no hay registros
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button p-1 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    <i className="bi bi-clock-history"></i> Historial de la
                    rendición
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body text-black p-0">
                    <div className={styles.divTable + ' ' + styles.blueTable}>
                      <div className={styles.divTableHeading}>
                        <div className={styles.divTableRow}>
                          <div className={styles.divTableHead}>N°</div>
                          <div className={styles.divTableHead}>Registro</div>
                          <div className={styles.divTableHead}>Estado</div>
                          <div className={styles.divTableHead}>
                            Fecha Revisión
                          </div>
                          <div className={styles.divTableHead}>Validador</div>
                          <div className={styles.divTableHead}>KMS</div>
                          <div className={styles.divTableHead}>$T. KMS</div>
                          <div className={styles.divTableHead}>$T. Gastos</div>
                          <div className={styles.divTableHead}>
                            $T. Rendición
                          </div>
                          <div className={styles.divTableHead}>
                            Observación Técnico
                          </div>
                          <div className={styles.divTableHead}>
                            Observación Validación
                          </div>
                          <div className={styles.divTableHead}>Fecha Pago</div>
                          <div className={styles.divTableHead}>
                            Agente Finanzas
                          </div>
                        </div>
                      </div>
                      <div className={styles.divTableBody}>
                        {!isLoading
                          ? historialFiltrado.map((historial, index) => (
                              <div
                                className={styles.divTableRow}
                                key={historial.audit_id}
                              >
                                <div className={styles.divTableCell}>
                                  {historialFiltrado.length - index}
                                </div>
                                <div className={styles.divTableCell}>
                                  {historial.changed_at}
                                </div>
                                <div className={styles.divTableCell}>
                                  {historial.estado_nombre}
                                </div>
                                <div className={styles.divTableCell}>
                                  {formatDateTime(historial.fecha_valida)}
                                </div>
                                <div className={styles.divTableCell}>
                                  {historial.validador || 'N/A'}
                                </div>
                                <div className={styles.divTableCell}>
                                  {historial.kilometros || 0}
                                </div>
                                <div className={styles.divTableCell}>
                                  ${historial.total_kilometros || 0}
                                </div>
                                <div className={styles.divTableCell}>
                                  ${historial.total_gastos}
                                </div>
                                <div className={styles.divTableCell}>
                                  ${historial.total_pago}
                                </div>
                                <div className={styles.divTableCell}>
                                  {historial.observaciones_tecnico}
                                </div>
                                <div className={styles.divTableCell}>
                                  {historial.observaciones_validador}
                                </div>
                                <div className={styles.divTableCell}>
                                  {formatDateTime(historial.fecha_pago)}
                                </div>
                                <div className={styles.divTableCell}>
                                  {historial.pagador}
                                </div>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row text-end">
              <small className="position-absolute">
                Ultima modificación: {formatDateTime(rendicion.updated_at)}
              </small>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-12">
        <div className="card">
          <div className="card-header p-3 bg-success bg-opacity-10 border border-hyo border-start-1 rounded-end d-flex justify-content-between">
            <h4>Tickets / Servicios</h4>
            {(rendicion.estado_nombre === 'Enviada' ||
              rendicion.estado_nombre === 'Rechazada Gerencia') &&
              dataUserLogged.role === 'Validador' && (
                <button
                  className="btn btn-outline-warning btn-sm oculto-pdf"
                  onClick={() => setHabilitaBotones(!habilitaBotones)}
                >
                  <i className="bi bi-eye"></i> Botones de Acción
                </button>
              )}
          </div>
          <div className="card-body ">
            {/* Tabla tickets */}
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Fecha</th>
                    <th>Hr Inicio</th>
                    <th>Hr Termino</th>
                    <th>Proyecto</th>
                    <th>Ticket</th>
                    <th>Origen</th>
                    {/* <th>Comuna</th> */}
                    <th>Destino</th>
                    {/* <th>Comuna</th> */}
                    <th>R. Urbano</th>
                    <th>IBM Gepro</th>
                    <th>Kms</th>
                    <th>Subt. Kms</th>
                    <th>Gastos</th>
                    <th>Subt. Gastos</th>
                    <th>Total</th>
                    <th className="oculto-pdf">Ver</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading && rendicion.tickets.length > 0 ? (
                    rendicion.tickets.map((ticket, index) => (
                      <tr
                        key={ticket.id}
                        className={
                          Number(ticket.items_rechazados) > 0
                            ? 'table-danger'
                            : ''
                        }
                      >
                        <td
                          className={`${
                            ticket.id === ultimoVisto ? 'bg-secondary' : ''
                          }`}
                        >
                          <Link
                            className="text-reset"
                            to={`/validador/rendicion/ticket/${ticket.id}`}
                            onClick={() => {
                              handleUltimoVisto(ticket.id);
                            }}
                          >
                            {index + 1}
                          </Link>
                        </td>
                        <td>{formatDate(ticket.fecha_atencion)}</td>
                        <td>{ticket.hora_inicio.slice(0, 5)}</td>
                        <td>{ticket.hora_termino.slice(0, 5)}</td>
                        <td>{ticket.cliente}</td>
                        <td>
                          {ticket.nomenclatura}
                          {ticket.ticket}
                        </td>
                        <td>
                          {ticket.direccion_desde}, {ticket.comuna_desde}
                        </td>
                        {/* <td>{ticket.comuna_desde}</td> */}
                        <td>
                          {ticket.direccion_hasta}, {ticket.comuna_hasta}
                        </td>
                        {/* <td>{ticket.comuna_hasta}</td> */}
                        <td>{ticket.radio_urbano === '1' ? 'Si' : 'No'}</td>
                        <td>{ticket.correlativo_gepro ? 'Si' : 'No'}</td>
                        <td>{ticket.kilometros || 0}</td>
                        <td>${ticket.subtotal_kms || 0}</td>
                        <td>
                          <ul className="m-0">
                            {ticket.items_gastos.map((item, i) => (
                              <li key={i} className="d-flex">
                                <small className="flex-grow-1 me-2">
                                  {`${item.tipo_gasto} `}
                                </small>
                                <small>${item.monto}</small>
                              </li>
                            ))}
                          </ul>
                        </td>
                        <td>${ticket.subtotal_gastos || 0}</td>
                        <td>
                          $
                          {Number(ticket.subtotal_kms) +
                            Number(ticket.subtotal_gastos)}
                        </td>
                        <td
                          className={`oculto-pdf ${
                            ticket.id === ultimoVisto ? 'bg-secondary' : ''
                          }`}
                        >
                          <div className="d-flex justify-content-center">
                            <Link
                              to={`/${perfil}/rendicion/ticket/${ticket.id}`}
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                handleUltimoVisto(ticket.id);
                              }}
                            >
                              <i className="bi bi-eye"></i>
                            </Link>
                            {habilitaBotones && (
                              <button
                                className="btn btn-sm btn-warning ms-2"
                                onClick={() =>
                                  handleEditarTicket(
                                    ticket.id,
                                    `${ticket.nomenclatura}${ticket.ticket}`
                                  )
                                }
                              >
                                <i className="bi bi-pencil"></i>
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="14">Rendicion sin tickets</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card-footer">
            {((dataUserLogged.role === 'Validador' &&
              rendicion.estado_nombre === 'Enviada') ||
              (dataUserLogged.role === 'Validador' &&
                rendicion.estado_nombre === 'Rechazada Gerencia') ||
              (dataUserLogged.role === 'Gerencia' &&
                rendicion.estado_nombre === 'Aprobada Validador') ||
              (dataUserLogged.role === 'Finanzas' &&
                rendicion.estado_nombre === 'Aprobada Gerencia')) && (
              <ButtonPanel
                role={dataUserLogged.role}
                estadoNombre={rendicion.estado_nombre}
                tieneRechazo={tieneRechazo}
                handleEnvio={handleEnvio}
                handleRechazar={handleRechazar}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default RendicionSemana;
