import CardUserLogged from '../../../general/cardsInfo/CardUserLogged';
import { getRendiciones } from '../../../../services/rendicionesT';
import { useEffect, useState } from 'react';
import useUser from '../../../../hooks/useUser';
import { Link } from 'react-router-dom';
import { formatDate, getNumeroSemana } from '../../../../hooks/useDates';

function RendicionesTecnico() {
  const { apiToken, dataUserLogged } = useUser();
  const [rendiciones, setRendiciones] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  function ObtieneRendiciones() {
    getRendiciones(dataUserLogged.id, apiToken).then((response) => {
      if (response.status === 200) {
        setRendiciones(response.rendiciones);
      }
    });

    setIsLoading(false);
  }

  console.log(getNumeroSemana());

  useEffect(() => {
    ObtieneRendiciones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="row">
      <div className="col-12 col-lg-9">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4>Lista de Rendiciones</h4>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sem.</th>
                        <th>Desde</th>
                        <th>Hasta</th>
                        <th>Monto</th>
                        <th>Estado</th>
                        <th className="col-1">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading
                        ? null
                        : rendiciones.map((rendicion) => (
                            <tr key={rendicion.id}>
                              <td>{rendicion.numero}</td>
                              <td>Lun {formatDate(rendicion.fecha_desde)}</td>
                              <td>Dom {formatDate(rendicion.fecha_hasta)}</td>
                              <td>{rendicion.total_pago || '$0'}</td>
                              <td
                                style={{
                                  color:
                                    rendicion.estado_nombre ===
                                    'Rechazada Validacion'
                                      ? 'orange'
                                      : rendicion.estado_nombre ===
                                          'Pendiente' &&
                                        Number(rendicion.numero) ===
                                          getNumeroSemana()
                                      ? 'green'
                                      : rendicion.estado_nombre ===
                                          'Pendiente' &&
                                        Number(rendicion.numero) ===
                                          getNumeroSemana() - 1
                                      ? 'orange'
                                      : rendicion.estado_nombre ===
                                          'Pendiente' &&
                                        Number(rendicion.numero) <=
                                          getNumeroSemana() - 2
                                      ? 'red'
                                      : 'inherit',
                                }}
                              >
                                {rendicion.estado_nombre === 'Pendiente' &&
                                Number(rendicion.numero) <=
                                  getNumeroSemana() - 2 ? (
                                  'Pendiente Atrasada'
                                ) : rendicion.estado_nombre === 'Pendiente' &&
                                  Number(rendicion.numero) ===
                                    getNumeroSemana() - 1 ? (
                                  'Pendiente'
                                ) : rendicion.estado_nombre === 'Pendiente' &&
                                  Number(rendicion.numero) ===
                                    getNumeroSemana() ? (
                                  'Semana Actual'
                                ) : rendicion.estado_nombre ===
                                  'Rechazada Validacion' ? (
                                  <>
                                    <i className="bi bi-exclamation-triangle"></i>{' '}
                                    Rechazada Validación
                                  </>
                                ) : (
                                  rendicion.estado_nombre
                                )}
                              </td>

                              <td>
                                <div className="col-12 d-flex justify-content-end">
                                  {/* Boton Ver */}
                                  <Link
                                    to={`/tecnico/rendiciones/semana/${rendicion.id}`}
                                    className="btn btn-info"
                                  >
                                    <i className="bi bi-eye"></i>
                                  </Link>
                                  {/* Boton agregar ticket */}
                                  {rendicion.estado_nombre === 'Pendiente' ? (
                                    <Link
                                      to={`/tecnico/rendiciones/agregarticket/${rendicion.id}/${rendicion.fecha_desde}/${rendicion.fecha_hasta}`}
                                      className="btn btn-success ms-1"
                                    >
                                      <i className="bi bi-receipt"></i>
                                    </Link>
                                  ) : null}
                                </div>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-3">
        <CardUserLogged />
      </div>
    </section>
  );
}

export default RendicionesTecnico;
