import { useEffect, useState } from 'react';
import useUser from '../../hooks/useUser';
import { getEstadisticasRendiciones } from '../../services/rendicionesT';
import CardUserLogged from '../general/cardsInfo/CardUserLogged';
// import TotalMesTecnicos from '../general/estadisticas/TotalMesTecnicos';
// import KilometrosRegiones from '../general/estadisticas/KilometrosRegiones';
// import GeproMes from '../general/estadisticas/GeproMes';
// import GastosTicketPromedioProyecto from '../general/estadisticas/GastoTicketPromedioProyecto';
// import TotalMesRegiones from '../general/estadisticas/TotalMesRegiones';

function DashGerencia() {
  const { isLogged, role, apiToken, dataUserLogged } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [estadisticas, setEstadisticas] = useState([]);

  const ObtieneEstadisticas = () => {
    getEstadisticasRendiciones(apiToken).then((response) => {
      if (response.status === 200) {
        setEstadisticas(response.estadisticas);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (isLogged && role !== 'Tecnico') {
      ObtieneEstadisticas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, role, apiToken, dataUserLogged]);

  return (
    <section className="row">
      <div className="col-12 col-lg-12">
        {/*  Cards estadisticas  */}
        <div className="row">
          <div className="col-sm-12 col-md-3">
            <div className="card shadow-sm">
              <div className="card-body">
                <div className="row d-flex align-items-center">
                  <div className="col-md-4 col-lg-12 col-xl-12 position-absolute">
                    <div
                      className="rounded rounded-4 bg-danger text-center overflow-hidden"
                      style={{ height: '90px', width: '90px', opacity: '0.3' }}
                    >
                      <i
                        className="bi bi-currency-dollar text-white"
                        style={{ fontSize: '80px' }}
                      ></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12">
                    <h6 className="text-end font-semibold">
                      Pendientes de Pago
                    </h6>
                    <h4
                      className="font-extrabold mb-0 text-end"
                      id="doc-vencidos"
                    >
                      {isLoading
                        ? '0'
                        : estadisticas.conteo.find(
                            (elemento) => elemento.estado === 'Finanzas'
                          )?.cantidad || '0'}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3">
            <div className="card shadow-sm">
              <div className="card-body">
                <div className="row d-flex align-items-center">
                  <div className="col-md-4 col-lg-12 col-xl-12 d-flex justify-content-start position-absolute">
                    <div
                      className="rounded rounded-4 bg-success text-center  overflow-hidden"
                      style={{ height: '90px', width: '90px', opacity: '0.3' }}
                    >
                      <i
                        className="bi bi-currency-dollar text-white"
                        style={{ fontSize: '80px' }}
                      ></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12">
                    <h6 className="text-end font-semibold">Consolidadas</h6>
                    <h4
                      className="font-extrabold mb-0 text-end"
                      id="doc-vencidos"
                    >
                      {isLoading
                        ? '0'
                        : estadisticas.conteo.find(
                            (elemento) => elemento.estado === 'Consolidadas'
                          )?.cantidad || '0'}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3">
            <div className="card shadow-sm">
              <div className="card-body">
                <div className="row d-flex align-items-center">
                  <div className="col-md-4 col-lg-12 col-xl-12 position-absolute">
                    <div
                      className="rounded rounded-4 bg-warning text-center overflow-hidden"
                      style={{ height: '90px', width: '90px', opacity: '0.3' }}
                    >
                      <i
                        className="bi bi-exclamation-triangle-fill text-white"
                        style={{ fontSize: '80px' }}
                      ></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12">
                    <h6 className="text-end font-semibold text-truncate">
                      En Validación
                    </h6>
                    <h4
                      className="font-extrabold mb-0 text-end"
                      id="doc-vencidos"
                    >
                      {isLoading
                        ? '0'
                        : estadisticas.conteo.find(
                            (elemento) => elemento.estado === 'Validacion'
                          )?.cantidad || '0'}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  Tercera columna  */}
          <div className="col-sm-12 col-md-3">
            {/*  Card info usuario logueado  */}
            <CardUserLogged />
          </div>
        </div>
        {/*  Estadisticas Maestras */}
        <div className="row">
          <div className="col-12">
            <div className="card shadow-sm">
              <div className="card-body p-1">
                <div style={{ position: 'relative' }}>
                  <iframe
                    width="100%"
                    height="2200"
                    src="https://lookerstudio.google.com/embed/reporting/b8237be9-93b1-4fc0-9f80-03ada3b5b9ab/page/p_xpupqcvp8c"
                    // frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen
                    title="googleDash"
                  ></iframe>
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '0',
                      height: '30px', // Ajusta este valor para cubrir exactamente el pie de página
                      width: '100%',
                      backgroundColor: '#ffffff', // Ajusta esto al color de fondo de tu página
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header"></div>
            <div className="card-body">
              {/* Informacion navegadores soportados */}
              <div className="row"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DashGerencia;
