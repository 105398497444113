import { Link } from 'react-router-dom';
// Carga scripts externos
import ScriptExterno from '../../herramientas/cargarScripts';
// Script bootstrap js
const Bootstrap = (props) => {
  ScriptExterno(process.env.PUBLIC_URL + '/assets/js/bootstrap.js');
};
// Script del template
const ScriptTema = (props) => {
  ScriptExterno(process.env.PUBLIC_URL + '/assets/js/aplicacion.js');
};

function Footer() {
  // Año actual para el pie de pagina
  const thisYear = new Date().getFullYear();
  return (
    <>
      <footer>
        <div className="footer clearfix mb-0 text-muted">
          <div className="float-start">
            <p>{thisYear} &copy; HyO Ltda.</p>
          </div>
          <div className="float-end">
            <p>
              Desarrollado por{' '}
              <span className="text-danger">
                <i className="bi bi-github"></i>{' '}
              </span>
              <Link to="https://github.com/Miguel-Ubilla" target="_blank">
                {' '}
                Miguel Ubilla Rocco
              </Link>{' '}
              para HyO Ltda.
            </p>
          </div>
        </div>
      </footer>
      <Bootstrap />
      <ScriptTema />
    </>
  );
}

export default Footer;
