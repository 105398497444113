import { useEffect, useState } from 'react';

function Informes() {
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const currentMonth = new Date().getMonth();

  // Función para obtener los meses en función del año seleccionado
  const getMonths = (year) => {
    const allMonths = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];

    if (year === 2023) {
      return allMonths.slice(4); // De Mayo a Diciembre para 2023
    } else if (year === new Date().getFullYear()) {
      return allMonths.slice(0, currentMonth + 1); // Hasta el mes actual para el año actual
    } else {
      return allMonths; // Todos los meses para cualquier otro año
    }
  };

  // Obtener los meses en función del año seleccionado
  const months = getMonths(selectedYear);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearsArray = [];
    for (let year = 2023; year <= currentYear; year++) {
      yearsArray.push(year);
    }
    setYears(yearsArray);
  }, []);

  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value));
  };

  const handleDownload = (event, type) => {
    event.preventDefault();
    const mes = event.target.getAttribute('data-mes');
    const downloadUrl = `https://hyostorage.s3.amazonaws.com/informes/${type}/${type
      .charAt(0)
      .toUpperCase()}${type.slice(1)}-${mes}-${selectedYear}.xlsx`;

    const link = document.createElement('a');
    link.href = downloadUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <section className="row">
      <div className="col-12 col-lg-9">
        {/* Sección de Tickets Rendidos */}
        <div className="card">
          <div className="card-header">
            <h4>TICKETS RENDIDOS</h4>
          </div>
          <div className="card-body">
            <div className="row row-cols-1 row-cols-xl-6">
              {months.map((month, index) => {
                const monthIndex =
                  selectedYear === 2023 ? index + 5 : index + 1;
                return (
                  <div
                    className="card border border-secondary col-2 mx-1 shadow-sm p-1"
                    key={index}
                  >
                    <div className="card-header bg-transparent p-0 m-auto">
                      <div className="d-flex px-4 py-3">
                        <div className="rounded-circle rounded-5 py-0 px-2 bg-light">
                          <i
                            className="bi bi-file-earmark-spreadsheet"
                            style={{ fontSize: 35 }}
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-0 m-0 border-top border-bottom border-secondary text-center">
                      <h5>{month}</h5>
                      <small className="text-muted">
                        Actualizado:{' '}
                        {`${
                          monthIndex < 10 ? '0' : ''
                        }${monthIndex}-${selectedYear}`}
                      </small>
                    </div>
                    <div className="card-footer p-0 m-0 text-center text-white bg-success">
                      <button
                        className="text-white border-0 bg-transparent"
                        data-mes={
                          monthIndex < 10 ? `0${monthIndex}` : `${monthIndex}`
                        }
                        onClick={(event) => handleDownload(event, 'tickets')}
                      >
                        Descargar
                        <i className="bi bi-download ms-2"></i>
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/* Sección de Gastos Consolidados */}
        <div className="card">
          <div className="card-header">
            <h4>GASTOS CONSOLIDADOS</h4>
          </div>
          <div className="card-body">
            <div className="row row-cols-1 row-cols-xl-6">
              {months.map((month, index) => {
                const monthIndex =
                  selectedYear === 2023 ? index + 5 : index + 1;
                // Ajustar la lógica para incluir todos los meses del año actual hasta el mes actual
                if (
                  selectedYear === new Date().getFullYear() &&
                  monthIndex > currentMonth + 1
                ) {
                  return null;
                }
                return (
                  <div
                    className="card border border-secondary col-2 mx-1 shadow-sm p-1"
                    key={index}
                  >
                    <div className="card-header bg-transparent p-0 m-auto">
                      <div className="d-flex px-4 py-3">
                        <div className="rounded-circle rounded-5 py-0 px-2 bg-light">
                          <i
                            className="bi bi-file-earmark-spreadsheet"
                            style={{ fontSize: 35 }}
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-0 m-0 border-top border-bottom border-secondary text-center">
                      <h5>{month}</h5>
                      <small className="text-muted">
                        Actualizado:{' '}
                        {`${
                          monthIndex < 10 ? '0' : ''
                        }${monthIndex}-${selectedYear}`}
                      </small>
                    </div>
                    <div className="card-footer p-0 m-0 text-center text-white bg-success">
                      <button
                        className="text-white border-0 bg-transparent"
                        data-mes={
                          monthIndex < 10 ? `0${monthIndex}` : `${monthIndex}`
                        }
                        onClick={(event) =>
                          handleDownload(event, 'rendiciones')
                        }
                      >
                        Descargar
                        <i className="bi bi-download ms-2"></i>
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-3">
        <div className="card">
          <div className="card-header">
            <h4>Mostrar año</h4>
          </div>
          <div className="card-body">
            <div className="d-flex">
              <div className="form-floating me-2 col-8">
                <select
                  className="form-select"
                  id="floatingSelect"
                  aria-label="Floating label select example"
                  value={selectedYear}
                  onChange={handleYearChange}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingSelect">Seleccionar año</label>
              </div>
              <button
                className="btn btn-outline-secondary col-4"
                type="button"
                onClick={() => {}}
              >
                Mostrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Informes;

// Consulta para obtener los tickewts trabajados
// SELECT
//     CONCAT(u.nombre, ' ', u.apellido) AS tecnico,
//     u.region,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 1 THEN 1 END) AS dia_1,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 2 THEN 1 END) AS dia_2,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 3 THEN 1 END) AS dia_3,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 4 THEN 1 END) AS dia_4,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 5 THEN 1 END) AS dia_5,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 6 THEN 1 END) AS dia_6,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 7 THEN 1 END) AS dia_7,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 8 THEN 1 END) AS dia_8,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 9 THEN 1 END) AS dia_9,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 10 THEN 1 END) AS dia_10,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 11 THEN 1 END) AS dia_11,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 12 THEN 1 END) AS dia_12,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 13 THEN 1 END) AS dia_13,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 14 THEN 1 END) AS dia_14,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 15 THEN 1 END) AS dia_15,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 16 THEN 1 END) AS dia_16,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 17 THEN 1 END) AS dia_17,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 18 THEN 1 END) AS dia_18,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 19 THEN 1 END) AS dia_19,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 20 THEN 1 END) AS dia_20,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 21 THEN 1 END) AS dia_21,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 22 THEN 1 END) AS dia_22,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 23 THEN 1 END) AS dia_23,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 24 THEN 1 END) AS dia_24,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 25 THEN 1 END) AS dia_25,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 26 THEN 1 END) AS dia_26,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 27 THEN 1 END) AS dia_27,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 28 THEN 1 END) AS dia_28,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 29 THEN 1 END) AS dia_29,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 30 THEN 1 END) AS dia_30,
//     COUNT(CASE WHEN DAY(t.fecha_atencion) = 31 THEN 1 END) AS dia_31,
//     COUNT(t.id) AS total_tickets_mes
// FROM
//     users u
// JOIN
//     rendiciones r ON u.id = r.tecnico_id
// JOIN
//     tickets t ON r.id = t.rendicion_id
// WHERE
//     MONTH(t.fecha_atencion) = 01 AND
//     YEAR(t.fecha_atencion) = 2024
// GROUP BY
//     u.id;
