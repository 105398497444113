// SweetAlert2
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal); // SweetAlert2

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

// AlertaError;
export const AlertaError = ({ title, error }) => {
  MySwal.fire({
    title: title || 'Error',
    text: error,
    icon: 'error',
    confirmButtonText: 'Aceptar',
  });
};

// AlertaSucess
export const AlertaSuccess = ({ title, success, callback }) => {
  MySwal.fire({
    title: title || 'Éxito',
    text: success,
    icon: 'success',
    confirmButtonText: 'Aceptar',
  }).then((result) => {
    if (result.isConfirmed && callback) {
      callback();
    }
  });
};

export const AlertaWarning = ({ warning }) => {
  MySwal.fire({
    title: 'Advertencia',
    text: warning,
    icon: 'warning',
    confirmButtonText: 'Aceptar',
  });
};

export const AlertaInfo = ({ success }) => {
  MySwal.fire({
    position: 'top-end',
    icon: 'success',
    title: success,
    showConfirmButton: false,
    timer: 1500,
  });
};

// AlertaConfirm whit callback
export const AlertaConfirm = ({ title, text, callback }) => {
  MySwal.fire({
    title: title,
    text: text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Aceptar',
    cancelButtonText: 'Cancelar',
  }).then((result) => {
    if (result.isConfirmed) {
      callback();
    }
  });
};

export const AlertaConfirmComentario = ({ title, text, icon, callback }) =>
  MySwal.fire({
    title,
    text,
    icon: icon || 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Aceptar',
    cancelButtonText: 'Cancelar',
    input: 'textarea',
    inputPlaceholder: 'Comentario',
    inputAttributes: {
      autocapitalize: 'off',
    },
    showLoaderOnConfirm: true,
    preConfirm: (comentario) => comentario,
  }).then((result) => {
    if (result.isConfirmed) {
      callback(result.value);
    }
  });

export const AlertaConfirmRadio = ({ title, text, icon, callback }) => {
  MySwal.fire({
    title: title,
    html: `
        <div style="display: flex; gap: 20px; align-items: center; justify-content: center;">
          <div>
            <input type="radio" id="gerencia" name="drone" value="gerencia">
            <label for="gerencia">Gerencia</label>
          </div>
          <div>
            <input type="radio" id="finanzas" name="drone" value="valTofinanzas">
            <label for="finanzas">Finanzas</label>
          </div>
          <div>
            <select id="otros" style="width: 100%;">
              <option value="">Seleccione una opción</option>
              <option value="valTovacaciones">Vacaciones</option>
              <option value="valTolicencia">Lic. Medica</option>
              <option value="valTosingastos">Sin Gastos</option>
            </select>
          </div>
          
        </div>
        <textarea id="comentario" placeholder="Comentario" style="width: 100%; margin-top: 10px;"></textarea>
      `,
    icon: icon || 'warning',
    didOpen: () => {
      const radios = document.querySelectorAll('input[name="drone"]');
      const select = document.getElementById('otros');

      radios.forEach((radio) => {
        radio.addEventListener('change', () => {
          select.value = '';
        });
      });

      select.addEventListener('change', () => {
        radios.forEach((radio) => (radio.checked = false));
      });
    },
    preConfirm: () => {
      const valueRadio = document.querySelector(
        'input[name="drone"]:checked'
      )?.value;
      const valueSelect = document.getElementById('otros').value;
      const comentario = document.getElementById('comentario').value;

      if (!valueRadio && !valueSelect) {
        Swal.showValidationMessage('Debe seleccionar una opción');
      } else {
        return { value: valueRadio || valueSelect, comentario };
      }
    },
    showCancelButton: true,
    confirmButtonText: 'Aceptar',
    cancelButtonText: 'Cancelar',
  }).then((result) => {
    if (result.isConfirmed) {
      callback(result.value);
    }
  });
};

export const AlertaEditaItem = ({ title, text, icon, callback }) => {
  MySwal.fire({
    title: title,
    text: text,
    html: `
      <div style="display: flex; gap: 20px; align-items: center; justify-content: center;">
        <div>
        <label for="gerencia">Nuevo monto $</label>
          <input type="number" id="gerencia" name="drone" value="">
        </div>
      </div>
      <textarea id="comentario" placeholder="Comentario" style="width: 100%; margin-top: 10px;"></textarea>
    `,
    icon: icon || 'warning',
    preConfirm: () => {
      const monto = document.querySelector('input[name="drone"]')?.value;
      const comentario_validador = document.getElementById('comentario').value;
      if (!monto || isNaN(monto)) {
        Swal.showValidationMessage('Debe ingresar un monto nuevo');
      } else {
        return { monto, comentario_validador };
      }
    },
    showCancelButton: true,
    confirmButtonText: 'Aceptar',
    cancelButtonText: 'Cancelar',
  }).then((result) => {
    if (result.isConfirmed) {
      callback(result.value);
    }
  });
};

// Toast Alerta
export const AlertToast = ({ icon, title, timer }) => {
  Toast.fire({
    icon: icon || 'success',
    title: title || 'Éxito',
    showConfirmButton: false,
    timer: timer || 2000,
  });
};
