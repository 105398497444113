import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import TablaRendicionesAdministracion from '../../general/rendiciones/TablaAdministracion';
import TablaPendientesEnvio from '../../general/rendiciones/TablaPendientesEnvio';
import TablaRendicionesFinanzas from '../../general/rendiciones/TablaFinanzas';
import TablaRendicionesPagadas from '../../general/rendiciones/TablaPagadas';
import useUser from '../../../hooks/useUser';
import {
  AlertaConfirm,
  AlertaConfirmComentario,
  AlertaError,
  AlertaSuccess,
} from '../../general/Alerts';
import { actualizarEstadoRendiciones } from '../../../services/rendicionesT';
import TablaRevisar from '../../general/rendiciones/TablaRevisar';

const tableMap = {
  gerencia: {
    titulo: 'EN GERENCIA',
    componente: TablaRendicionesAdministracion,
  },
  validacion: {
    titulo: 'EN VALIDACIÓN',
    componente: TablaRevisar,
  },
  finanzas: {
    titulo: 'FINANZAS | PENDIENTES DE PAGO',
    componente: TablaRendicionesFinanzas,
  },
  consolidadas: {
    titulo: 'CONSOLIDADAS',
    componente: TablaRendicionesPagadas,
  },
  pendientes: {
    titulo: 'PENDIENTES DE ENVÍO',
    componente: TablaPendientesEnvio,
  },
  default: {
    titulo: '',
    componente: TablaRendicionesFinanzas,
  },
};

function RendicionesGerencia() {
  const { tipotabla } = useParams();
  const [titulo, setTitulo] = useState('');
  const [TablaComponente, setTablaComponente] = useState(
    () => tableMap.default.componente
  );
  const [aConsolidar, setAConsolidar] = useState([]);
  const { apiToken, dataUserLogged } = useUser();
  const [recargarTabla, setRecargarTabla] = useState(false);

  useEffect(() => {
    const tabla = tableMap[tipotabla] || tableMap.default;
    setTitulo(tabla.titulo);
    setTablaComponente(() => tabla.componente);
  }, [tipotabla]);

  const handleSelectFilas = ({ selectedRows }) => {
    const data = selectedRows.map((row) => ({
      id: row.id,
      aprueba_id: dataUserLogged.id,
      estado: 4,
      fecha_aprueba: new Date().toISOString().slice(0, 19).replace('T', ' '),
    }));
    setAConsolidar(data);
  };

  const handlePagarSeleccionadas = () => {
    aConsolidar.length > 0
      ? AlertaConfirm({
          title: 'Aprobar rendiciones',
          text: '¿Está seguro que desea aprobar las rendiciones seleccionadas?',
          callback: () => {
            actualizarEstadoRendiciones(aConsolidar, apiToken).then(
              (response) => {
                if (response.status === 200) {
                  AlertaSuccess({
                    title: 'Rendiciones aprobadas',
                    text: 'Las rendiciones seleccionadas han sido aprobadas y enviadas a finanzas',
                  });
                  setAConsolidar([]);
                  setRecargarTabla(true);
                } else {
                  AlertaError({
                    title: 'Error',
                    error: 'Ha ocurrido un error al aprobar las rendiciones',
                  });
                }
              }
            );
          },
        })
      : AlertaError({
          title: 'Error',
          error: 'Debe seleccionar al menos una rendición para aprobar',
        });
  };

  const handleRechazarSeleccionadas = () => {
    aConsolidar.length > 0
      ? AlertaConfirmComentario({
          title: 'Rechazar rendiciones',
          text: '¿Está seguro que desea rechazar las rendiciones seleccionadas?',
          icon: 'warning',
          callback: (comentario) => {
            aConsolidar.forEach((rendicion) => {
              rendicion.estado = 7;
              rendicion.observaciones_gerencia = comentario;
            });
            actualizarEstadoRendiciones(aConsolidar, apiToken).then(
              (response) => {
                if (response.status === 200) {
                  AlertaSuccess({
                    title: 'Rendiciones rechazadas',
                    text: 'Las rendiciones seleccionadas han sido rechazadas y devueltas a validación',
                  });
                  setAConsolidar([]);
                  setRecargarTabla(true);
                } else {
                  AlertaError({
                    title: 'Error',
                    error: 'Ha ocurrido un error al rechazar las rendiciones',
                  });
                }
              }
            );
          },
        })
      : AlertaError({
          title: 'Error',
          error: 'Debe seleccionar al menos una rendición para rechazar',
        });
  };

  return (
    <section className="section">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">{titulo}</h4>
        </div>
        <div className="card-body">
          {/* tabla */}
          <TablaComponente
            recargarTabla={recargarTabla}
            setRecargarTabla={setRecargarTabla}
            selectableRows
            onSelectedRowsChange={handleSelectFilas}
          />
        </div>
        <div className="card-footer">
          {tipotabla === 'gerencia' && (
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-danger me-4"
                onClick={handleRechazarSeleccionadas}
              >
                Rechazar Seleccionadas
              </button>
              <button
                className="btn btn-primary"
                onClick={handlePagarSeleccionadas}
              >
                Aprobar Seleccionadas
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default RendicionesGerencia;
