export const estadosSolicitudes = [
  {
    id: 1,
    estado: 'Pendiente',
    descripcion: 'Rendicion no enviada',
  },
  {
    id: 2,
    estado: 'Enviada',
    descripcion: 'Rendicion enviada, en proceso de validacion',
  },
  {
    id: 3,
    estado: 'Aprobada Validacion',
    descripcion:
      'Rendicion aprobada por validacion, en proceso de revision administrativa',
  },
  {
    id: 4,
    estado: 'Aprobada Administracion',
    descripcion:
      'Rendicion aprobada por administracion, en proceso de consolidación',
  },
  {
    id: 5,
    estado: 'Consolidada',
    descripcion: 'Rendicion consolidada, Cerrada',
  },
  {
    id: 6,
    estado: 'Rechazada Validacion',
    descripcion: 'Rendicion rechazada por validador, requiere correccion.',
  },
  {
    id: 7,
    estado: 'Rechazada Administracion',
    descripcion:
      'Redicion rechazada por administrador, requiere revision de validador.',
  },
];
