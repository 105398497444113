import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatDate, getNumeroSemana } from '../../hooks/useDates';
import useUser from '../../hooks/useUser';
import {
  getEstadisticasTecnico,
  getRendicionesPendientes,
} from '../../services/rendicionesT';
import CardUserLogged from '../general/cardsInfo/CardUserLogged';
import { SpinerDownload } from '../general/loader/SpinerLoader';
import RendicionesDash from './rendiciones/RendicionesDash';
import { getFprsTecnico } from '../../services/fondosPorRendir';

function Dashboard() {
  const { isLogged, role, apiToken, dataUserLogged } = useUser();
  const [rendicionesPendientes, setRendicionesPendientes] = useState([]);
  const [fprPendientes, setFprPendientes] = useState([]);
  const [estadisticas, setEstadisticas] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // scroll top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (isLogged && role === 'Tecnico') {
        setIsLoading(true);

        const [estadisticasRes, rendicionesRes, fprRes] = await Promise.all([
          getEstadisticasTecnico(dataUserLogged.id, apiToken),
          getRendicionesPendientes(dataUserLogged.id, apiToken),
          getFprsTecnico(dataUserLogged.id, 2, apiToken),
        ]);

        if (estadisticasRes.status === 200) {
          setEstadisticas(estadisticasRes.estadisticas);
        }

        if (rendicionesRes.status === 200) {
          setRendicionesPendientes(rendicionesRes.rendiciones);
        }

        if (fprRes.status === 200) {
          setFprPendientes(fprRes.data);
        }

        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, role, apiToken, dataUserLogged]);

  return (
    <section className="row">
      <div className="col-12 col-lg-9">
        {isLoading ? <SpinerDownload /> : null}
        {/*  Cards estadisticas  */}
        <div className="row">
          <div className="col-6 col-lg-3">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row d-flex align-items-center">
                  <div className="col-md-4 col-lg-12 col-xl-12 position-absolute">
                    <div
                      className="rounded rounded-4 bg-danger text-center overflow-hidden"
                      style={{ height: '80px', width: '80px', opacity: '0.3' }}
                    >
                      <i
                        className="bi bi-exclamation-triangle-fill text-white"
                        style={{ fontSize: '70px' }}
                      ></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12">
                    <h6 className="text-end font-semibold text-truncate">
                      Pendientes de Envío
                    </h6>
                    <h4
                      className="font-extrabold mb-0 text-end"
                      id="doc-vencidos"
                    >
                      {!isLoading && estadisticas ? estadisticas.pendientes : 0}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row d-flex align-items-center">
                  <div className="col-md-4 col-lg-12 col-xl-12 position-absolute">
                    <div
                      className="rounded rounded-4 bg-warning text-center overflow-hidden"
                      style={{ height: '80px', width: '80px', opacity: '0.3' }}
                    >
                      <i
                        className="bi bi-exclamation-triangle-fill text-white"
                        style={{ fontSize: '70px' }}
                      ></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12">
                    <h6 className="text-end font-semibold">Rechazadas</h6>
                    <h4
                      className="font-extrabold mb-0 text-end"
                      id="doc-vencidos"
                    >
                      {!isLoading && estadisticas ? estadisticas.rechazadas : 0}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row d-flex align-items-center">
                  <div className="col-md-4 col-lg-12 col-xl-12 d-flex justify-content-start position-absolute">
                    <div
                      className="rounded rounded-4 bg-success text-center  overflow-hidden"
                      style={{ height: '80px', width: '80px', opacity: '0.3' }}
                    >
                      <i
                        className="bi bi-currency-dollar text-white"
                        style={{ fontSize: '70px' }}
                      ></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12">
                    <h6 className="text-end font-semibold">Consolidadas</h6>
                    <h4
                      className="font-extrabold mb-0 text-end"
                      id="doc-vencidos"
                    >
                      {!isLoading && estadisticas
                        ? estadisticas.consolidadas
                        : 0}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* FPR */}
          <div className="col-6 col-lg-3">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row d-flex align-items-center">
                  <div className="col-md-4 col-lg-12 col-xl-12 d-flex justify-content-start position-absolute">
                    <div
                      className="rounded rounded-4 bg-danger text-center  overflow-hidden"
                      style={{ height: '80px', width: '80px', opacity: '0.3' }}
                    >
                      <i
                        className="bi bi-currency-dollar text-white"
                        style={{ fontSize: '70px' }}
                      ></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12">
                    <h6 className="text-end font-semibold">FPR</h6>
                    <h4
                      className="font-extrabold mb-0 text-end"
                      id="doc-vencidos"
                    >
                      {!isLoading && estadisticas
                        ? estadisticas.consolidadas
                        : 0}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  Cards estadisticas end  */}
        <div className="row d-none d-sm-block">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4>Rendiciones Pendientes</h4>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sem.</th>
                        <th>Estado</th>
                        <th>Desde</th>
                        <th>Hasta</th>
                        <th>Monto</th>
                        <th className="col-1">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="6" className="text-center">
                            Cargando...
                          </td>
                        </tr>
                      ) : rendicionesPendientes.length > 0 ? (
                        rendicionesPendientes.map((rendicion) => (
                          <tr key={rendicion.id}>
                            <td>{rendicion.numero}</td>
                            <td
                              style={{
                                color:
                                  rendicion.estado_nombre ===
                                  'Rechazada Validacion'
                                    ? 'orange'
                                    : rendicion.estado_nombre === 'Pendiente' &&
                                      Number(rendicion.numero) ===
                                        getNumeroSemana()
                                    ? 'green'
                                    : rendicion.estado_nombre === 'Pendiente' &&
                                      Number(rendicion.numero) ===
                                        getNumeroSemana() - 1
                                    ? 'orange'
                                    : rendicion.estado_nombre === 'Pendiente' &&
                                      Number(rendicion.numero) <=
                                        getNumeroSemana() - 2
                                    ? 'red'
                                    : 'inherit',
                              }}
                            >
                              {rendicion.estado_nombre === 'Pendiente' &&
                              Number(rendicion.numero) <=
                                getNumeroSemana() - 2 ? (
                                'Pendiente Atrasada'
                              ) : rendicion.estado_nombre === 'Pendiente' &&
                                Number(rendicion.numero) ===
                                  getNumeroSemana() - 1 ? (
                                'Pendiente'
                              ) : rendicion.estado_nombre === 'Pendiente' &&
                                Number(rendicion.numero) ===
                                  getNumeroSemana() ? (
                                'Semana Actual'
                              ) : rendicion.estado_nombre ===
                                'Rechazada Validacion' ? (
                                <>
                                  <i className="bi bi-exclamation-triangle"></i>{' '}
                                  Rechazada Validación
                                </>
                              ) : (
                                rendicion.estado_nombre
                              )}
                            </td>
                            <td>Lun {formatDate(rendicion.fecha_desde)}</td>
                            <td>Dom {formatDate(rendicion.fecha_hasta)}</td>
                            <td>{rendicion.total_pago || '$0'}</td>
                            <td>
                              <div className="col-12 d-flex justify-content-end">
                                {/* Boton Ver */}
                                <Link
                                  to={`/tecnico/rendiciones/semana/${rendicion.id}`}
                                  className="btn btn-info"
                                >
                                  <i className="bi bi-eye"></i>
                                </Link>
                                {/* Boton agregar ticket */}
                                {rendicion.estado_nombre === 'Pendiente' ? (
                                  <Link
                                    to={`/tecnico/rendiciones/agregarticket/${rendicion.id}/${rendicion.fecha_desde}/${rendicion.fecha_hasta}`}
                                    className="btn btn-success ms-1"
                                  >
                                    <i className="bi bi-receipt"></i>
                                  </Link>
                                ) : null}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="text-center">
                            No hay rendiciones pendientes
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Fondo Por Rendir */}
        <div className="row d-none d-sm-block">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4>Fondos por Rendir Pendientes</h4>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>FPR</th>
                        <th>Fecha Servicio</th>
                        <th>Estado</th>
                        <th>Fecha Deposito</th>
                        <th>Monto</th>
                        <th className="col-1">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="6" className="text-center">
                            Cargando...
                          </td>
                        </tr>
                      ) : fprPendientes.length > 0 ? (
                        fprPendientes.map((fpr) => (
                          <tr key={fpr.id}>
                            <td>{fpr.id}</td>
                            <td>{formatDate(fpr.fecha_servicio)}</td>
                            <td>{fpr.estado}</td>
                            <td>{formatDate(fpr.fecha_deposito)}</td>
                            <td>{fpr.monto_deposito || '$0'}</td>
                            <td>
                              <div className="col-12 d-flex justify-content-end">
                                {/* Boton Ver */}
                                <Link
                                  to={`/tecnico/fpr/${fpr.id}`}
                                  className="btn btn-info"
                                >
                                  <i className="bi bi-eye"></i>
                                </Link>
                                {/* Boton agregar ticket */}
                                {fpr.estado_nombre === 'Pendiente' ? (
                                  <Link
                                    to={`/tecnico/fpr/${fpr.id}`}
                                    className="btn btn-success ms-1"
                                  >
                                    <i className="bi bi-receipt"></i>
                                  </Link>
                                ) : null}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="text-center">
                            No hay rendiciones pendientes
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <RendicionesDash />
        </div>
      </div>
      {/*  Tercera columna  */}
      <div className="col-12 col-lg-3">
        {/*  Card info usuario logueado  */}
        <CardUserLogged />

        {/*  Card ultimos recursos  */}
        <div class="card text-center border border-warning">
          <div class="card-header text-danger">IMPORTANTE!</div>
          <div class="card-body">
            <h5 class="card-title text-secondary">
              Registre todos los Tickets
            </h5>
            <p class="card-text">
              Desde este momento usted debe registrar todos los tickets
              atendidos en la aplicación. En caso que no le generaran gastos de
              transporte u otra índole, solo debe ingresar la información del
              ticket.
            </p>
            <p className="text-danger text-sm">
              Es de suma importancia el registro de cada atención que realiza.
            </p>
          </div>
          <div class="card-footer text-body-secondary">
            Comunica: Gerencia HyO Ltda.
          </div>
        </div>
        {/*  Card utimas desviculaciones  */}
      </div>
    </section>
  );
}

export default Dashboard;
